export const types = {
  SET_MESSAGE: 'SET_MESSAGE',
  CLEAR_SNACKBAR: 'CLEAR_SNACKBAR',
}

type Action = { type: string; payload?: any }
type Dispatch = (action: Action) => void

export interface SnackbarParams {
  message: any // usually a string, but you can also pass a react element like (<ul><li>thing one</li><li>thing two</li></ul>)
  type: string
}

export const snackbarActions = {
  setMessage(params: SnackbarParams, dispatch: any) {
    dispatch({
      type: types.SET_MESSAGE,
      payload: {
        open: true,
        ...params,
      },
    })
    setTimeout(() => {
      dispatch({
        type: types.CLEAR_SNACKBAR,
        payload: {},
      })
    }, 7500)
  },
  clearSnackbar(dispatch: any) {
    dispatch({
      type: types.CLEAR_SNACKBAR,
      payload: {},
    })
  },
}
