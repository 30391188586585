import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { RootComponent } from './styledComponents'
import { PageTitle } from '../styledComponents'
import _ from 'lodash'
import { Container, Grid, CircularProgress } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import queryString from 'query-string'
import { TITLE_TEMPLATE } from '../../utils'
import * as api from '../../services/thezerocard/api-helper'
import { useAppPerms } from '../../AppPermissions'
import { PaidClaimsReport } from './PaidClaimsReport'
import { PAID_CLAIMS } from './slugs'
import ignoredReports from './ignore'

interface Props {
  location?: any // React Router prop
  history?: any // React Router prop
}

export const Reporting = (props: Props) => {
  const [chartEmbedURL, setChartEmbedURL] = useState('')
  const [currentChartSlug, setCurrentChartSlug]: any = useState('')
  const [validReportConfigs, setValidReportConfigs]: any = useState([])
  const [currentTabValue, setCurrentTabValue] = useState(0)
  const { selectedEmployerID, userPermissions } = useAppPerms()
  const params = queryString.parse(props.location.search)

  const fetchReportUrl = (reportSlug: string) => {
    api
      .get(`/teamzero/report/${selectedEmployerID}/${reportSlug}`)
      .then((v: any) => {
        setChartEmbedURL(v.Data.URL)
      })
    props.history.push({
      search: `?chart=${reportSlug}`,
    })
  }

  useEffect(() => {
    // Filter out reports that are not allowed or ignored
    const filteredReportConfigs = _.filter(
      userPermissions.ReportConfig,
      (report) => {
        return report.Can == true && !ignoredReports.includes(report.Slug)
      }
    )

    // Sort configs by report name
    const sortedReportConfigs = _.sortBy(filteredReportConfigs, (obj: any) => {
      return obj.Name
    })

    setValidReportConfigs(sortedReportConfigs)

    if (sortedReportConfigs.length > 0) {
      // by default use the first report
      let slug = sortedReportConfigs[0].Slug
      let tabValue = 0

      // if there is a valid chart query string, use that instead
      if (
        params.chart &&
        _.findIndex(sortedReportConfigs, { Slug: params.chart }) > -1
      ) {
        slug = params.chart
        tabValue = _.findIndex(sortedReportConfigs, { Slug: slug })
      }

      setCurrentChartSlug(slug)
      setCurrentTabValue(tabValue)
      fetchReportUrl(slug)
    }
  }, [selectedEmployerID])

  const tabs = (reportConfigs: any) => {
    let tabs = []
    let count = 0
    _.map(reportConfigs, (config: any) => {
      tabs.push(
        <Tab
          value={count}
          label={config.Name}
          key={config.Name}
          onClick={() => {
            setCurrentChartSlug(config.Slug)
            fetchReportUrl(config.Slug)
          }}
        />
      )
      count += 1
    })
    return tabs
  }

  const renderReport = () => {
    if (!currentChartSlug) return null
    let chartHeight = '1600px'
    if (currentChartSlug === 'payment_lag') chartHeight = '1100px'
    if (currentChartSlug === 'savings') chartHeight = '3100px'
    if (currentChartSlug === 'eligibility') chartHeight = '1750px'
    switch (currentChartSlug) {
      case PAID_CLAIMS:
        return <PaidClaimsReport employerID={selectedEmployerID} />
      default:
        return (
          <iframe
            sandbox="allow-forms allow-downloads allow-modals allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            title="Embedded Report"
            src={chartEmbedURL}
            style={{
              width: '100%',
              minHeight: chartHeight,
              border: '1px solid #ddd',
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          />
        )
    }
  }

  if (!selectedEmployerID) {
    return (
      <RootComponent>
        <Grid>
          <CircularProgress />
        </Grid>
      </RootComponent>
    )
  }

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Reporting</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={currentTabValue}
              onChange={(_e: object, value: number) =>
                setCurrentTabValue(value)
              }
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              {tabs(validReportConfigs)}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {validReportConfigs.length > 0 && renderReport()}
          </Grid>
        </Grid>
      </Container>
      {validReportConfigs.length == 0 && <NoAccess />}
    </RootComponent>
  )
}

const NoAccess = () => {
  return (
    <div style={{ marginTop: -75 }}>
      <Container maxWidth="xl">
        <PageTitle>Sorry, this page requires additional permission.</PageTitle>
        <p>
          <em>
            You do not have access to view any reports. Ask your teamZERO
            Administrator for access to if you need to view this page.
          </em>
        </p>
      </Container>
    </div>
  )
}
