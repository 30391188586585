import { useEffect } from 'react'

interface ScrollToTopProps {
  location: any
}

export const ScrollToTop = (props: ScrollToTopProps) => {
  const { location } = props
  const { pathname, search } = location

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      //fallback for older browsers
      window.scrollTo(0, 0)
    }
  }, [pathname, search])
  //
  // renders nothing, since nothing is needed
  return null
}
