import queryString from 'query-string'
import * as api from '../../services/thezerocard/api-helper'

/**
 * Services for the user state to access async data
 * Only to be used in the useUserState hook
 */

const GOONIES_ID = 198

export const services = {
  async getEmployers() {
    const filter = JSON.stringify({ NoLimit: true, OrIDs: [GOONIES_ID] })
    const qs = queryString.stringify({ filter })
    return api.get(`/teamzero/employer?${qs}`)
  },
}
