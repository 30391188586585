import React from 'react'
import * as tzcHelper from '../../services/thezerocard/helper'

import IconChart from '@material-ui/icons/InsertChart'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import InvoiceIcon from '@material-ui/icons/Description'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

import Menu from '../Menu'
import * as T from './types'
import { useAppPerms } from '../../AppPermissions'

export const MenuContainer = () => {
  const { userPermissions, user, selectedEmployerID } = useAppPerms()

  const UserCanManageOtherUsers = userPermissions?.CanManageOtherUsers
  const userFullName = user ? `${user.FirstName} ${user.LastName}` : ''

  const menuItems = [
    {
      label: 'Reporting',
      icon: <IconChart />,
      url: `/employer/${selectedEmployerID}/reporting`,
    },
    userPermissions?.ViewAndSubmitEligibility
      ? {
          label: 'Eligibility',
          icon: <SupervisorAccountIcon />,
          url: `/employer/${selectedEmployerID}/eligibility`,
        }
      : null,
    userPermissions?.ViewInvoice
      ? {
          label: 'Invoices',
          icon: <InvoiceIcon />,
          url: `/employer/${selectedEmployerID}/invoices`,
        }
      : null,
    UserCanManageOtherUsers
      ? {
          label: 'User Management',
          icon: <AssignmentIndIcon />,
          url: `/employer/${selectedEmployerID}/admin`,
        }
      : null,
  ].filter(Boolean) as unknown as T.MenuItem[]

  return (
    <Menu
      isProduction={tzcHelper.isProduction()}
      isManager={UserCanManageOtherUsers}
      fullName={userFullName}
      user={user}
      menuItems={menuItems}
    />
  )
}
