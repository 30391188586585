import React, { MutableRefObject } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import ZeroLogo from './ZeroLogo'
import QuestLogo from './QuestLogo'
import ColorBar from './ColorBar'
import LabCardLogo from './LabCardLogo'
import Scissors from './Scissors'
import { nameFormatter } from '../../formatters'

interface CardStyleProps {
  cardBg: string
  labelColor: string
  txtColor: string
  radius: string
  border: string
  position: string
  centerPage: boolean
}

const CardStyle = styled.div<CardStyleProps>`
  font-family: 'Raleway', sans-serif;
  border: ${(p) => p.border};
  position: ${(p) => p.position};
  margin-bottom: 20px;
  max-width: 36em;
  min-width: 30em;

  ${(p) => (p.centerPage ? `margin: 250px auto;` : null)}

  .member-card .member-card * {
    box-sizing: border-box;
  }

  .member-card {
    background-color: ${(p) => p.cardBg};
    border-radius: ${(p) => p.radius};
    font-size: 10px;
  }

  .header {
    display: grid;
    padding: 3em;
    place-items: center;
  }

  .logo {
    height: 3em;
    width: auto;
  }

  .letters {
    fill: ${(p) => p.txtColor};
  }

  .content {
    padding: 0 3em;
  }

  .two-col-row {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 2em;
    text-align: left;
  }

  .two-col-row > :last-child {
    text-align: right;
  }

  .three-col-row {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-bottom: 2em;
    text-align: left;
  }

  .three-col-row > :nth-child(2) {
    text-align: center;
  }

  .three-col-row > :last-child {
    text-align: right;
  }

  .four-col-row {
    display: grid;
    grid-template-columns: 20% 30% 30% 20%;
    margin-bottom: 2em;
    text-align: left;
  }

  .four-col-row > :nth-child(2) {
    text-align: center;
  }

  .four-col-row > :nth-child(3) {
    text-align: center;
  }

  .four-col-row > :last-child {
    text-align: right;
  }
  .data,
  .data dl,
  .data dt,
  .data dd {
    all: unset;
  }

  .left dt,
  .left dd {
    text-align: left;
  }

  .data dt {
    color: ${(p) => p.labelColor};
    display: block;
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .data dd {
    color: ${(p) => p.txtColor};
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1.4;
  }

  .data dd.id {
    font-size: 1.8em;
    font-weight: 600;
  }

  .data dd.phone {
    color: ${(p) => p.txtColor};
    text-decoration: underline;
    cursor: pointer;
  }

  .data dd.phone:hover {
    color: #ffe100;
  }

  .print .data dd.phone a,
  .print .data dd.phone a:hover {
    color: #0059c9;
    font-weight: 600;
    text-decoration: none;
  }

  .footer {
    display: grid;
    gap: 2em;
    grid-template-columns: auto auto;
    margin-top: 1em;
    padding: 3em;
    position: relative;
    place-items: center;
  }

  .quest-logo {
    height: 3em;
  }

  .labcard-logo {
    height: 2em;
  }

  .quest-logo *,
  .labcard-logo * {
    fill: ${(p) => p.txtColor};
  }

  .scissors {
    bottom: 3em;
    content: '';
    display: block;
    height: 3.3em;
    position: absolute;
    right: -3.4em;
    width: 4.4em;
  }
`

interface Props {
  FirstName: string
  LastName: string
  MiddleName: string
  IntegrationId: string
  IsCurrentlyActive: boolean
  NotYetActive: boolean
  Dob: string
  QuestID: string
  HasQuest: boolean
  componentRef?: MutableRefObject<null>
  print?: boolean
}

const MemberIdCard: React.FC<Props> = (props: any) => {
  const {
    FirstName,
    LastName,
    MiddleName,
    IntegrationId,
    IsCurrentlyActive,
    NotYetActive,
    Dob,
    QuestID,
    HasQuest,
    componentRef,
    print,
  } = props

  const cardStyles = {
    cardBg: print ? 'white' : '#002F66',
    labelColor: print ? '#00000A' : '#00C5FF',
    txtColor: print ? '#00000A' : 'white',
    radius: print ? '0px' : '2em',
    border: print ? '0.2em dashed #00000a' : 'none',
    position: print ? 'relative' : 'initial',
    centerPage: print,
  }

  const fullName = nameFormatter(FirstName, MiddleName, LastName)

  // this prevents tel: url from appearing when printing the document
  const callPhone = () => (window.location.href = 'tel:8558160001')

  const getActiveText = () => {
    if (!!IsCurrentlyActive) {
      return 'Active'
    }

    return NotYetActive ? 'Pending' : 'Inactive'
  }

  return (
    <CardStyle ref={componentRef} {...cardStyles}>
      <div className={print ? 'member-card print' : 'member-card'}>
        <header className="header">
          <ZeroLogo />
        </header>

        <section className="content">
          <div className="three-col-row">
            <dl className="data left">
              <dt>Member Name</dt>
              <dd>{fullName}</dd>
            </dl>

            <dl className="data">
              <dt>Date of Birth</dt>
              <dd>{moment(Dob).utc().format('MM/DD/YYYY')}</dd>
            </dl>

            <dl className="data">
              <dt>Member ID</dt>
              <dd className="id">{IntegrationId}</dd>
            </dl>
          </div>

          <div className="two-col-row">
            <dl className="data left">
              <dt>Website</dt>
              <dd>https://zero.health</dd>
            </dl>

            <dl className="data">
              <dt>Call Us</dt>
              <dd className="phone" onClick={callPhone}>
                855-816-0001
              </dd>
            </dl>
          </div>

          {HasQuest ? (
            <div className="four-col-row">
              <dl className="data left">
                <dt>Status</dt>
                <dd>{getActiveText()}</dd>
              </dl>

              <dl className="data">
                <dt>Group #</dt>
                <dd>{QuestID}</dd>
              </dl>

              <dl className="data">
                <dt>Mnemonic</dt>
                <dd>LBCRD</dd>
              </dl>

              <dl className="data">
                <dt>3PF</dt>
                <dd>LBD</dd>
              </dl>
            </div>
          ) : (
            <div className="three-col-row">
              <dl className="data left">
                <dt>Status</dt>
                <dd>{getActiveText()}</dd>
              </dl>
              <dl className="data"></dl>

              <dl className="data"></dl>
            </div>
          )}
        </section>
        <footer className="footer">
          <ColorBar />
          {HasQuest ? <QuestLogo /> : <></>}
          {HasQuest ? <LabCardLogo /> : <></>}
        </footer>

        {print && <Scissors />}
      </div>
    </CardStyle>
  )
}

export default MemberIdCard
