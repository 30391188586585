import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import {
  getAppBarComponent,
  AppBarStyled,
  HalfContainerFlexStart,
  HalfContainerFlexEnd,
  MenuFooter,
  LoginBtn,
  MenuLink,
} from './styledComponents'
import { LinearProgress, CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ExitToApp from '@material-ui/icons/ExitToApp'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { EmployerSelect } from '../EmployerSelect'
import { UserMenu } from '../UserMenu'
import Logo from '../../assets/tzc-logo.png'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useAuth } from '../../Auth'
import { useAppPerms } from '../../AppPermissions'
import * as T from './types'
import { useConfig } from '../../Config'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
      paddingTop: '84px',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
)

interface MenuProps {
  isProduction: boolean
  isManager: boolean
  fullName: string
  user: any
  menuItems: T.MenuItem[]
}

const MenuItem: React.FC<T.MenuItem> = (props) => {
  return (
    <MenuLink style={{ textDecoration: 'none' }} to={props.url}>
      <ListItem button>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
    </MenuLink>
  )
}

export default function Menu({
  isProduction,
  isManager,
  fullName,
  user,
  menuItems,
}: MenuProps) {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth()
  const { selectedEmployerID } = useAppPerms()
  const classes = useStyles()
  const contactText = (
    <span>
      If you have any questions, please contact your ZERO account manager or{' '}
      <a href="mailto:client.success@zero.health">client.success@zero.health</a>
    </span>
  )
  const { notificationsEnabled, env, gitHash } = useConfig()
  const AppBarComponent = getAppBarComponent(env)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarComponent className={classes.appBar}>
        <AppBarStyled>
          <HalfContainerFlexStart>
            <Link to="/">
              <img alt="Zero" style={{ width: 115, height: 33 }} src={Logo} />
            </Link>
          </HalfContainerFlexStart>
          <HalfContainerFlexEnd>
            {isManager ? (
              <Link to={`/employer/${selectedEmployerID}/admin`}>
                <IconButton>
                  <AssignmentIndIcon />
                </IconButton>
              </Link>
            ) : null}
            <EmployerSelect />
            {loading ? (
              <CircularProgress />
            ) : isAuthenticated ? (
              <UserMenu
                userDisplayName={fullName}
                currentUser={user}
                logout={logout}
              />
            ) : (
              <LoginBtn onClick={() => loginWithRedirect}>Login</LoginBtn>
            )}
          </HalfContainerFlexEnd>
        </AppBarStyled>
        {loading ? <LinearProgress /> : <div />}
      </AppBarComponent>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={true}
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerContainer}>
          {!loading ? (
            <List>
              {menuItems.map((m) => (
                <MenuItem key={m.label} {...m} />
              ))}
              <Divider />
              {notificationsEnabled && (
                <MenuItem
                  label="Notifications"
                  icon={<NotificationsIcon />}
                  url="/notifications"
                />
              )}
              {isAuthenticated ? (
                <ListItem button onClick={logout}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary={'Logout'} />
                </ListItem>
              ) : null}
              {env !== 'prd' && (
                <ListItem>
                  <p style={{ color: 'gray' }}>Version: {gitHash}</p>
                </ListItem>
              )}
              <ListItem>
                <MenuFooter secondary={contactText} />
              </ListItem>
            </List>
          ) : null}
        </div>
      </Drawer>
    </div>
  )
}
