import React, { createContext, useContext, useReducer, Dispatch } from 'react'
import { snackbarActions } from './snackbar/snackbarActions'
import { initialState, mainReducer, Action } from './reducers'

interface Props {
  reducer: typeof mainReducer
  initialState: typeof initialState
}

type StateAndDispatch = [typeof initialState, Dispatch<Action>]

const StateContext = createContext<StateAndDispatch | undefined>(undefined)

export const StateProvider: React.FC<Props> = ({
  reducer,
  initialState,
  children,
}) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

// use ! to tell typescript value will not be undefined
export const useStateValue = () => useContext(StateContext)!

export const withState = (WrappedComponent: any) => (props: any) => {
  const [state, dispatch] = useStateValue()
  return <WrappedComponent state={state} dispatch={dispatch} {...props} />
}

export const notifySnackbar = (res: any, dispatch: any) => {
  if (!res) {
    snackbarActions.setMessage(
      {
        message:
          'We encountered an error when trying to communicate with the server, please try again.',
        type: 'error',
      },
      dispatch
    )
    return
  }

  if (res.error) {
    // if we know the error, display it here
    if (Array.isArray(res.Error)) {
      snackbarActions.setMessage(
        { message: res.Error.join(', '), type: 'error' },
        dispatch
      )
      return
    }

    if (res.Error) {
      snackbarActions.setMessage(
        { message: res.Error.Message, type: 'error' },
        dispatch
      )
      return
    }

    // if we dont know the error, give a generic message
    snackbarActions.setMessage(
      {
        message: 'Unknown error, please try again or contact us.',
        type: 'error',
      },
      dispatch
    )
    return
  }

  // can add custom messages later
  snackbarActions.setMessage({ message: 'Success!', type: 'success' }, dispatch)
  return
}
