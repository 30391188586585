import { services } from './employerServices'
import { Dispatch } from '../types'
import { Employer } from './types'
import { loadingActions } from '../loading/loadingActions'

export const types = {
  GET_EMPLOYERS: 'GET_EMPLOYERS',
  GET_EMPLOYERS_INITIATED: 'GET_EMPLOYERS_INITIATED',
  UPDATE_SELECTED_EMPLOYER: 'UPDATE_SELECTED_EMPLOYER',
}

const { updateGetEmployersLoading } = loadingActions

export const employerActions = {
  async updateSelectedEmployer(dispatch: Dispatch, selectedEmployer: Employer) {
    dispatch({
      type: types.UPDATE_SELECTED_EMPLOYER,
      payload: {
        selectedEmployer,
      },
    })
  },
  async getEmployers(dispatch: Dispatch) {
    updateGetEmployersLoading(dispatch, true)
    const response = await services.getEmployers()
    updateGetEmployersLoading(dispatch, false)

    const employers = response.Data ? response.Data : null

    dispatch({
      type: types.GET_EMPLOYERS,
      payload: {
        employers,
      },
    })
  },
}
