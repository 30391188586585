import React from 'react'
import { Button } from '@material-ui/core'
import * as api from '../../services/thezerocard/api-helper'

interface FileLinkBtnProps {
  preSignUrl: string
  filename: string
  variant?: string
  style?: any
}

export const FileLinkBtn = ({
  preSignUrl,
  filename,
  style,
}: FileLinkBtnProps) => {
  const handleClick = (e: any) => {
    e.stopPropagation()

    const newTab = window.open()

    api
      .get(preSignUrl)
      .then((data: any) => {
        const url = data?.Data?.URL || false
        if (!url) {
          return console.error('Presigned file URL did not return properly')
        }

        if (!newTab) {
          return window.alert('Unable to download file.')
        }
        newTab.location.href = url
        return
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  return (
    <Button
      title={filename}
      variant="outlined"
      size="small"
      color="primary"
      onClick={handleClick}
      style={style}>
      {filename}
    </Button>
  )
}
