import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Content,
  MainComponent,
  Root,
  AppFrameStyled,
} from './styledComponents'
import { MenuContainer } from '../Menu/MenuContainer'
import { ErrorBoundaryFallback } from '../ErrorBoundaryFallback'
import SnackBar from '../SnackBar'

interface Props {
  children: React.ReactNode
}

const AppFrame: React.FC<Props> = ({ children: routes }) => {
  return (
    <Root>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <SnackBar />
        <AppFrameStyled>
          <MenuContainer />
          <MainComponent id="tzc-main">
            <Content>{routes}</Content>
          </MainComponent>
        </AppFrameStyled>
      </ErrorBoundary>
    </Root>
  )
}

export default AppFrame
