import { Dispatch } from '../types'
export const types = {
  UPDATE_GET_MEMBER_LOADING: 'UPDATE_GET_MEMBER_LOADING',
  UPDATE_TERM_MEMBERSHIP_LOADING: 'UPDATE_TERM_MEMBERSHIP_LOADING',
  UPDATE_GET_USER_INFO_LOADING: 'UPDATE_GET_USER_INFO_LOADING',
  UPDATE_GET_EMPLOYERS_LOADING: 'UPDATE_GET_EMPLOYERS_LOADING',
  UPDATE_ELIGIBILITY_SEARCH_LOADING: 'UPDATE_ELIGIBILITY_SEARCH_LOADING',
  UPDATE_GET_VALIDATION_FILE_LOADING: 'UPDATE_GET_VALIDATION_FILE_LOADING',
  UPDATE_GET_CHART_PERMISSIONS_FOR_CURRENT_USER_LOADING:
    'UPDATE_GET_CHART_PERMISSIONS_FOR_CURRENT_USER_LOADING',
  UPDATE_GET_USERS_AND_PERMISSIONS_LOADING:
    'UPDATE_GET_USERS_AND_PERMISSIONS_LOADING',
  UPDATE_PUT_USERS_AND_PERMISSIONS_LOADING:
    'UPDATE_PUT_USERS_AND_PERMISSIONS_LOADING',
  UPDATE_GET_PERMISSIONS_FOR_USER_LOADING:
    'UPDATE_GET_PERMISSIONS_FOR_USER_LOADING',
  UPDATE_GET_INVOICE_LOADING: 'UPDATE_GET_INVOICE_LOADING',
  UPDATE_GET_INVOICES_LOADING: 'UPDATE_GET_INVOICES_LOADING',
  UPDATE_GET_USER_NOTIFICATION_SETTINGS_LOADING:
    'UPDATE_GET_USER_NOTIFICATION_SETTINGS_LOADING',
  UPDATE_PUT_USER_NOTIFICATION_TYPE_LOADING:
    'UPDATE_PUT_USER_NOTIFICATION_TYPE_LOADING',
  UPDATE_PUT_EMPLOYER_NOTIFICATION_SETTING_LOADING:
    'UPDATE_PUT_EMPLOYER_NOTIFICATION_SETTING_LOADING',
  UPDATE_CREATE_USER_LOADING: 'UPDATE_CREATE_USER_LOADING',
  UPDATE_DELETE_USER_LOADING: 'UPDATE_DELETE_USER_LOADING',
}

export const loadingActions = {
  async updateGetInvoicesLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_GET_INVOICES_LOADING,
      payload: {
        isLoading,
        index: 'getInvoices',
      },
    })
  },
  async updateGetInvoiceLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_GET_INVOICE_LOADING,
      payload: {
        isLoading,
        index: 'getInvoice',
      },
    })
  },
  async updateGetMemberLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_GET_MEMBER_LOADING,
      payload: {
        isLoading,
        index: 'getMember',
      },
    })
  },
  async updateTermMembershipLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_TERM_MEMBERSHIP_LOADING,
      payload: {
        isLoading,
        index: 'termMembership',
      },
    })
  },
  async updateGetUserInfoLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_GET_USER_INFO_LOADING,
      payload: {
        isLoading,
        index: 'getUser',
      },
    })
  },
  async updateGetEmployersLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_GET_EMPLOYERS_LOADING,
      payload: {
        isLoading,
        index: 'getEmployers',
      },
    })
  },
  async updateEligibilitySearchLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_ELIGIBILITY_SEARCH_LOADING,
      payload: {
        isLoading,
        index: 'eligibilitySearch',
      },
    })
  },
  async updateGetValidationFileLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_GET_VALIDATION_FILE_LOADING,
      payload: {
        isLoading,
        index: 'getValidationFile',
      },
    })
  },
  async updateGetUsersAndPermissionsLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_GET_USERS_AND_PERMISSIONS_LOADING,
      payload: {
        isLoading,
        index: 'getUsersAndPermissions',
      },
    })
  },
  async updatePutUsersPermissionHandleLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_PUT_USERS_AND_PERMISSIONS_LOADING,
      payload: {
        isLoading,
        index: 'putUsersAndPermissions',
      },
    })
  },
  async updateGetPermissionsForUserLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_GET_PERMISSIONS_FOR_USER_LOADING,
      payload: {
        isLoading,
        index: 'getPermissionsForUser',
      },
    })
  },
  async updateGetChartPermissionsForCurrentUserLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_GET_CHART_PERMISSIONS_FOR_CURRENT_USER_LOADING,
      payload: {
        isLoading,
        index: 'getChartPermissionsForCurrentUser',
      },
    })
  },
  async updateGetUserNotificationSettingsLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_GET_USER_NOTIFICATION_SETTINGS_LOADING,
      payload: {
        isLoading,
        index: 'getUserNotificationSettings',
      },
    })
  },
  async updatePutUserNotificationTypeLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_PUT_USER_NOTIFICATION_TYPE_LOADING,
      payload: {
        isLoading,
        index: 'putUserNotificationType',
      },
    })
  },
  async updatePutEmployerNotificationSettingLoading(
    dispatch: Dispatch,
    isLoading: boolean
  ) {
    dispatch({
      type: types.UPDATE_PUT_EMPLOYER_NOTIFICATION_SETTING_LOADING,
      payload: {
        isLoading,
        index: 'putEmployerNotificationSetting',
      },
    })
  },
  async updateCreateUserLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_CREATE_USER_LOADING,
      payload: {
        isLoading,
        index: 'createUser',
      },
    })
  },
  async updateDeleteUserLoading(dispatch: Dispatch, isLoading: boolean) {
    dispatch({
      type: types.UPDATE_DELETE_USER_LOADING,
      payload: {
        isLoading,
        index: 'deleteUser',
      },
    })
  },
}
