//With tsconfig -> isolatedModules = true, this file needs an import or export to make it a module
export {}
const baseLogger = console
const logLevel = process.env.REACT_APP_ENV

const PREFIX = `###tzc-${logLevel}-logger => `

const devLogger = Object.assign({}, baseLogger, {
  log: (...args) => {
    return baseLogger.log(PREFIX, ...args)
  },
  debug: (...args) => {
    return baseLogger.debug(PREFIX, ...args)
  },
})

const noopLogger = Object.assign({}, baseLogger, {
  log: (...args) => {
    return null // noop
  },
  debug: (...args) => {
    return null // noop
  },
  table: (...args) => {
    return null // noop
  },
})

const logger =
  logLevel && (logLevel === 'prd' || logLevel === 'test')
    ? noopLogger
    : devLogger
// turning off this log message for test environments
if (logLevel !== 'test') {
  console.log('####################tzc#######################')
  console.log(' ')
  console.log(`${PREFIX} You are using the ${logLevel} logger`)
  console.log(
    `${PREFIX} Switch your log level to 'Verbose' in Chrome to view all messages: https://stackoverflow.com/questions/18760213/chrome-console-log-console-debug-are-not-working`
  )
  console.log(' ')
  console.log('####################/tzc#######################')
}

// eslint-disable-next-line
console = logger
