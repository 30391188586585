import { types } from './notificationActions'
import { Action } from '../types'
import { NotificationResponse } from '../../response-types'

export const notificationsInitialState: NotificationResponse = {
  Notifications: [],
  Employers: [],
}

export default function reducer(state: NotificationResponse, action: Action) {
  switch (action.type) {
    case types.SET_NOTIFICATION_SETTINGS:
      return {
        ...action.payload,
      }
    default:
      return { ...state }
  }
}
