import * as api from '../../services/thezerocard/api-helper'

/**
 * Services for the user state to access async data
 * Only to be used in the useUserState hook
 */

interface S3FileUpload {
  name: string
  type: string
}

interface PresignFileParams {
  objectName: string
  contentType: string
  make_unique: boolean
  employer_id: number
}

export const services = {
  // this method is used exclusively with the s3 file uploader component. Use the presignedUrlDirect method for manual fetching.
  getPresignedUrlForUpload(
    employerID: number,
    notifier: (s3Key: string) => void
  ): (file: S3FileUpload, cb: any) => void {
    return (file, cb) => {
      const params: PresignFileParams = {
        objectName: file.name,
        contentType: file.type,
        make_unique: true,
        employer_id: employerID,
      }

      api
        .get('/teamzero/presign_file', params)
        .then((data: any) => {
          if (data && data.Data) {
            cb({ signedUrl: data.Data.Url })
            notifier(data.Data.S3Key)
          } else {
            console.error('No signedUrl on response')
          }
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  },
  async getPresignedUrlForUploadDirect(params: PresignFileParams) {
    return api.get('/teamzero/presign_file', params)
  },
}
