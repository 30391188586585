import { types } from './invoiceActions'
import { Action } from '../types'

interface State {
  invoice: any
  invoices: any[]
}

export const invoiceInitialState = {
  invoice: {},
  invoices: [],
}

export default function reducer(state: State, action: Action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_INVOICE:
      return {
        ...state,
        ...payload,
      }
    case types.GET_INVOICES:
      return {
        ...state,
        ...payload,
      }
    default:
      return { ...state }
  }
}
