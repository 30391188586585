import _ from 'lodash'
import { types } from './loadingActions'
import { Action } from '../types'

interface State {
  global: boolean
  apiCalls: ApiCalls
}

interface ApiCalls {
  [index: string]: any
}

export const loadingInitialState: State = {
  global: false,
  apiCalls: {},
}

export default function reducer(state: State, action: Action) {
  const { payload } = action

  switch (action.type) {
    case types.UPDATE_ELIGIBILITY_SEARCH_LOADING:
    case types.UPDATE_GET_EMPLOYERS_LOADING:
    case types.UPDATE_GET_MEMBER_LOADING:
    case types.UPDATE_GET_USER_INFO_LOADING:
    case types.UPDATE_TERM_MEMBERSHIP_LOADING:
    case types.UPDATE_GET_VALIDATION_FILE_LOADING:
    case types.UPDATE_GET_USERS_AND_PERMISSIONS_LOADING:
    case types.UPDATE_PUT_USERS_AND_PERMISSIONS_LOADING:
    case types.UPDATE_GET_PERMISSIONS_FOR_USER_LOADING:
    case types.UPDATE_GET_INVOICE_LOADING:
    case types.UPDATE_GET_INVOICES_LOADING:
      const apiCallObject = {
        ...state.apiCalls,
        [payload.index]: payload.isLoading,
      }

      // Find returns true if an apiCallLoading is true
      const global = !!_.find(apiCallObject, (value) => value)

      return {
        global,
        apiCalls: apiCallObject,
      }
    default:
      return { ...state }
  }
}
