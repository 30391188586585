import React from 'react'
import { DateInput } from './Inputs'

interface Props {
  value: any
  onChange: (val: string) => void
  label: string
  disabled?: boolean
  style?: any
}

const DateField: React.FC<any> = ({
  value,
  onChange,
  label,
  disabled,
  style,
  ...otherProps
}: Props & Partial<any>) => (
  <DateInput
    label={label}
    onChange={(evt: React.SyntheticEvent) =>
      onChange((evt.target as HTMLInputElement).value)
    }
    value={value}
    disabled={disabled}
    style={style}
    {...otherProps}
  />
)

export default DateField
