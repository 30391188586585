import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import CheckIcon from '@material-ui/icons/Check'
import {
  TableCell,
  TableHead,
  TableRow,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { PermissionsTableModal } from './PermissionsTableModal'
import AddUserButton from './AddUserButton'

import { ClickableTableRow, StyledCell } from './styledComponents'

export interface Column {
  key: string
  name: string
}

interface Props {
  data: any
  EmployerID: string
}

export const PermissionsTable = ({ data, EmployerID }: Props) => {
  const [tableData, setTableData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [currentPage, setCurrentPage] = useState(0)
  const [columns, updateColumns] = useState<Column[]>([]) // Object array of format {key}
  const [editModalOpen, updateEditModalOpen] = useState(false)
  const [selectedUserID, updateSelectedUserID] = useState<string | null>(null) // The ID of the user being displayed in edit modal
  const [reportConfig, setReportConfig] = useState<Column[]>([])
  const [permissionConfig, setPermissionConfig] = useState<Column[]>([])
  const [showZeroUsers, setShowZeroUsers] = useState<boolean>(false)

  useEffect(() => {
    let filteredData = data
    if (!showZeroUsers) {
      console.debug('row: ', data)
      filteredData = data?.filter(
        (row) => row.UserInfo?.Email?.indexOf('@zero.health') === -1
      )
    }
    setTableData(filteredData)
  }, [showZeroUsers])

  // Setup header titles
  useEffect(() => {
    let rConfig: Column[] = []
    let pConfig: Column[] = []

    // Get the header titles from the first data points reportConfig
    if (tableData.length > 0) {
      rConfig = _.map(data[0]?.ReportConfig, (perm: any) => {
        return {
          key: perm.PermField,
          name: perm.Name,
        }
      })
      setReportConfig(rConfig)
      pConfig = _.map(tableData[0]?.PermissionConfig, (perm: any) => {
        return {
          key: perm.PermField,
          name: perm.Name,
        }
      })
      setPermissionConfig(pConfig)
    }

    const titles = [
      { key: 'Email', name: 'Email' },
      { key: 'FirstName', name: 'First Name' },
      { key: 'LastName', name: 'Last Name' },
      ...rConfig,
      ...pConfig,
    ]

    // @ts-ignore
    updateColumns(titles)
  }, [tableData])

  const handleRowClick = (UserID: string) => {
    updateSelectedUserID(UserID)
    updateEditModalOpen(true)
  }

  // Fires after a user closes the modal
  const handleEditModalClose = () => {
    updateEditModalOpen(false)
    updateSelectedUserID(null)
  }

  // Table Header to display using the columns
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {_.map(columns, (obj: any) => {
          return (
            <TableCell align="center" padding="default" key={obj.name}>
              {obj.name}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )

  // Generate the rows for the table
  const getTableRows = () => {
    // Slice just the paginated section to display
    const paginatedData = tableData
      ? tableData.slice(
          currentPage * rowsPerPage,
          currentPage * rowsPerPage + rowsPerPage
        )
      : null
    // Generate the rows based off the sliced data
    const tableRows = _.map(paginatedData, (element: any) => {
      const permissionCells = _.map(columns, (obj: any) => {
        // Do not add a cell if it contains User Info, that is handled seperatly
        if (
          obj.key === 'Email' ||
          obj.key === 'FirstName' ||
          obj.key === 'LastName'
        )
          return null

        return (
          <StyledCell align="center" key={obj.key}>
            {element[obj.key] ? <CheckIcon /> : false}
          </StyledCell>
        )
      }).filter((cell) => cell !== null)

      return (
        <ClickableTableRow
          key={element.MemberID}
          onClick={() => handleRowClick(element.UserID)}>
          <StyledCell align="center">{element.UserInfo.Email}</StyledCell>
          <StyledCell align="center">{element.UserInfo.FirstName}</StyledCell>
          <StyledCell align="center">{element.UserInfo.LastName}</StyledCell>
          {permissionCells}
        </ClickableTableRow>
      )
    })

    return tableRows
  }

  // When a user changes to a new page
  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
  }

  // When a user sets a new amount of rows per a page
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Grid container>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <FormControlLabel
              label={'Show ZERO Users'}
              control={
                <Switch
                  checked={showZeroUsers}
                  color="primary"
                  onChange={() => {
                    setShowZeroUsers(!showZeroUsers)
                  }}
                />
              }
            />
            <AddUserButton
              onClose={(userID: number | null) => {
                if (userID) {
                  //open modal
                  // @ts-ignore
                  updateSelectedUserID(userID)
                  updateEditModalOpen(true)
                }
              }}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHeader />
          <TableBody>{getTableRows()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 250, 1000]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <PermissionsTableModal
        open={editModalOpen}
        onClose={handleEditModalClose}
        UserID={selectedUserID}
        EmployerID={EmployerID}
        reportConfig={reportConfig}
        permissionConfig={permissionConfig}
      />
    </>
  )
}
