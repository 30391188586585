import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
// @todo-refactor
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core'
import isEmail from 'is-email'
import { CustomTextField as TextField } from '../CustomTextField'
import { userActions } from '../../redux/user/userActions'
import { useStateValue } from '../../redux'
import { useForm } from '../../hooks'
const { createUser, getUsersAndPermissions } = userActions

interface Props {
  open: boolean
  onClose: (userID: number | null) => void
}

const exists = (val: string) => !!val

const FormErrors: React.FC<{ formErrors: any }> = ({ formErrors }) => {
  const errors: string[] = Object.values(formErrors)
  return (
    <>
      {errors.length
        ? errors.map((err: string) => (
            <p style={{ color: 'firebrick' }}>{err}</p>
          ))
        : null}
    </>
  )
}

const AddUserDialog: React.FC<Props> = ({ open, onClose }) => {
  const defaultState = {
    FirstName: '',
    LastName: '',
    Email: '',
  }

  const validators = {
    FirstName: { isValid: exists, msg: 'First name is required' },
    LastName: { isValid: exists, msg: 'Last name is required' },
    Email: { isValid: isEmail, msg: 'Valid email is required' },
  }

  const params: any = useParams()
  const form = useForm(defaultState, validators)
  const [{ user }, dispatch]: any = useStateValue()
  const [attempedSave, setAttemptedSave] = React.useState(false)

  const onSave = async () => {
    setAttemptedSave(true)

    if (form.isValid()) {
      const resUser: any = await createUser(
        dispatch,
        params.employerID,
        form.data
      )
      form.reset()
      setAttemptedSave(false)
      getUsersAndPermissions(dispatch, params.employerID)
      onClose(resUser.UserID)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        form.reset()
        setAttemptedSave(false)
        onClose(null)
      }}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={5}>
            <TextField
              label="First Name"
              value={form.data.FirstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                form.setters.FirstName(e.target.value)
              }
            />
            <TextField
              label="Last Name"
              value={form.data.LastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                form.setters.LastName(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Email"
              value={form.data.Email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                form.setters.Email(e.target.value)
              }
            />
          </Grid>
        </Grid>
        {attempedSave && <FormErrors formErrors={form.errors} />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            form.reset()
            setAttemptedSave(false)
            onClose(null)
          }}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddUserDialog
