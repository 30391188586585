import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'

import './index.css'

import App from './App'
import { AuthProvider } from './Auth'
import { ConfigProvider } from './Config'
import { StateProvider } from './redux'
import { mainReducer, initialState } from './redux/reducers'
import { AppPermissionProvider } from './AppPermissions'
import history from './utils/history'
import checkRelease from './CheckRelease'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ConfigProvider>
    <AuthProvider>
      <AppPermissionProvider>
        <StateProvider reducer={mainReducer} initialState={initialState}>
          <Router history={history}>
            <App />
          </Router>
        </StateProvider>
      </AppPermissionProvider>
    </AuthProvider>
  </ConfigProvider>
)

checkRelease()
