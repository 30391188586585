import React from 'react'
import { Route, RouteProps } from 'react-router'
import { Forbidden } from './views/Forbidden'
import { useAppPerms } from './AppPermissions'

export interface PermissionGate {
  perm: string
}

type ProtectedRouteProps = RouteProps & PermissionGate

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { userPermissions } = useAppPerms()
  const { perm, component, ...routeProps } = props
  const canView = userPermissions[perm]

  const ForbiddenPage = () => <Forbidden route={props.path} perm={perm} />
  const Component = canView ? component : ForbiddenPage

  return <Route {...routeProps} component={Component} />
}
