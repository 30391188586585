/* eslint-disable indent */
import { types } from './snackbarActions'

export const snackbarInitialState = {
  open: false,
  message: null,
  type: null,
}

export default function reducer(state: any, action: any) {
  const { payload } = action

  switch (action.type) {
    case types.SET_MESSAGE:
      return {
        ...state,
        ...payload,
      }
    case types.CLEAR_SNACKBAR:
      return {
        ...state,
        open: false,
        message: null,
        type: null,
      }
    default:
      return { ...state }
  }
}
