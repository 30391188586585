import React, { useState } from 'react'
import { COLORS } from '../../utils/colors'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Avatar from '@material-ui/core/Avatar'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useConfig } from '../../Config'

const StyledAvatar = styled(Avatar)`
  color: ${COLORS.BW.GUNMETAL} !important;
  background-color: ${COLORS.BW.CLOUDS} !important;
  font-family: Raleway;

  :hover {
    opacity: 0.5;
  }
`

const DisplayBox = styled.div`
  padding-left: 10px;
`

const AvatarButton = styled(Button)`
  :hover {
    opacity: none;
    background-color: transparent;
  }

  :focus {
    outline: none;
  }
`

const MenuLink = styled(Link)`
  color: ${COLORS.BW.INK_BLACK};
  :hover {
    color: ${COLORS.BW.INK_BLACK};
  }
`

interface Props {
  userDisplayName: string // The name of the user to display as the clickable menu
  logout: () => void // Function to log the user out when clicked
  currentUser: any // Current user that is logged in
}

export const UserMenu = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { notificationsEnabled } = useConfig()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  // Needs to be called anytime a menu item is selected.
  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()
    props.logout()
  }

  return (
    <DisplayBox>
      <AvatarButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <StyledAvatar>
          {props.userDisplayName ? props.userDisplayName[0] : null}
        </StyledAvatar>
      </AvatarButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}>
        {notificationsEnabled && (
          <MenuLink to="/notifications">
            <MenuItem>Notifications</MenuItem>
          </MenuLink>
        )}
        <MenuItem onClick={handleLogout} style={{ color: COLORS.BW.INK_BLACK }}>
          Logout {props.currentUser?.Email}
        </MenuItem>
      </Menu>
    </DisplayBox>
  )
}
