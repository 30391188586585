import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  LinearProgress,
  Paper,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TablePagination,
  Table,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { models, Column } from './Models'
import { ClickableTableRow, StyledCell } from './styledComponents'
import { EnhancedTableHead } from './EnhancedTableHead'
import { Sortable, Error, DataTableProps } from './Types'

const NULL_DATE = '01/01/0001' //todo: centralize

export const DataTable = ({
  initPageSize = 50,
  initPage = 0,
  error,
  data,
  count,
  sortHandler,
  loading,
  ...props
}: DataTableProps) => {
  const [pageSize, setPageSize] = useState(initPageSize)
  const [currentPage, setCurrentPage] = useState(initPage)

  // Generate the rows for the table
  const renderTableRows = () => {
    const model = models[props.model]

    let dataToDisplay: any = data
    // if we're doing client side pagination, handle it here
    if (props.clientPagination) {
      dataToDisplay = data
        ? data.slice(currentPage * pageSize, currentPage * pageSize + pageSize)
        : null
    }

    // Generate the rows
    const tableRows = _.map(dataToDisplay, (row: any, i: number) => {
      const cells = _.map(Object.keys(model), (key: string, ik: number) => {
        const column = model[key]
        const content = row[key]
        if (column && column.hidden) {
          return null
        }
        const cellContent = formatCellContents(content, row, column)
        return (
          <StyledCell
            align="left"
            key={`${i}-${ik}`}
            onClick={() => props.handleRowClick(row)}>
            {cellContent}
          </StyledCell>
        )
      })

      return <ClickableTableRow key={i}>{cells}</ClickableTableRow>
    })

    return tableRows
  }

  const renderError = () => {
    return (
      <TableRow>
        <TableCell variant="footer">
          {error?.Message}. If you need help, please report this to
          support@zero.health
        </TableCell>
      </TableRow>
    )
  }

  // Format the cell content as needed
  const formatCellContents = (
    cellContent: any,
    row: any,
    column: Column | undefined
  ) => {
    if (!column) {
      return cellContent
    }

    if (column.formatter !== undefined) {
      return column.formatter({ cell: cellContent, row })
    }
    // Properly format dates
    if (column.isDate) {
      const formatted = moment(cellContent).format('MM/DD/YYYY')
      if (formatted === NULL_DATE || cellContent === null) {
        return 'n/a'
      }
      return formatted
    }
    // Show Boolean as a checkbox
    if (typeof column.defaultValue === 'boolean') {
      return cellContent ? <CheckIcon /> : false
    }

    // Keep a string formated as is
    return cellContent
  }

  // When a user changes to a new page
  const handleChangePage = (event: unknown, page: number) => {
    setCurrentPage(page)
    props.onChangePage({ page, pageSize })
  }

  // When a user sets a new amount of rows per a page
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setPageSize(newRowsPerPage)
    props.onChangePage({ page: currentPage, pageSize: newRowsPerPage })
  }

  return (
    <>
      <TableContainer component={Paper}>
        {loading ? <LinearProgress /> : <div />}
        <Table>
          <EnhancedTableHead
            columns={models[props.model]}
            sortHandler={sortHandler}
            sortable={props.sortable}
          />
          <TableBody>{error ? renderError() : renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 250, 1000]}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

DataTable.defaultProps = {
  clientPagination: false,
}
