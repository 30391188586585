import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { LinearProgress } from '@material-ui/core'
import { useConfig } from './Config'
import AuthError, {
  PASSWORD_EXPIRED,
  UNKNOWN_ERROR,
} from './components/AuthError'
import history from './utils/history'

const localStorage = window.localStorage

interface AuthGetter {
  (): Auth | any
}

interface Auth {
  isAuthenticated: any
  loading: boolean
  loginWithRedirect?: any
  path: any
  user: any
  logout: any
}

export const AuthContext = React.createContext({})

export const useAuth: AuthGetter = () => useContext(AuthContext)

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated]: [boolean | undefined, any] =
    useState()
  const [error, setError] = useState<string | null>(null)
  const [user, setUser]: [any | undefined, any] = useState()
  const [auth0Client, setAuth0]: [any | undefined, any] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen] = useState(false)

  const { auth0ClientID, auth0Domain } = useConfig()

  const onRedirectCallback = (appState: { targetUrl: string }) => {
    history.push(appState?.targetUrl || window.location.pathname)
  }

  const onPasswordExpired = () => {
    setIsAuthenticated(true)
    setLoading(false)
    setError(PASSWORD_EXPIRED)
  }

  const onError = () => {
    setLoading(false)
    setError(UNKNOWN_ERROR)
  }

  useEffect(() => {
    // wait for the config to initialize both of these values before continuing.
    if (!auth0ClientID || !auth0Domain) {
      return
    }

    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        client_id: auth0ClientID,
        domain: auth0Domain,
        redirect_uri: `${window.location.origin}/login_callback`,
      })
        .then((client) => client)
        .catch((err) => {
          // this handles the silent auth flow...ie if you are already identified by another application
          const { error_description } = err
          if (error_description === PASSWORD_EXPIRED) {
            return onPasswordExpired()
          }
          return onError()
        })
      if (!auth0FromHook) return // if it does not resolve, its likely an error condition (handled above)

      setAuth0(auth0FromHook)

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      // when we get error info from auth0, handle it
      if (window.location.search.includes('error=')) {
        if (
          window.location.search.includes(
            `error=unauthorized&error_description=${PASSWORD_EXPIRED}`
          )
        ) {
          return onPasswordExpired()
        }
        return onError()
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)

        // we can make this super idiomatic with access_tokens requested for each api call
        // but for now we're swiping the ID Token to seamlessly integrate with our backend.
        const token = await auth0FromHook.getTokenSilently()
        const claims = await auth0FromHook.getIdTokenClaims()

        if (claims && claims.__raw) {
          localStorage.setItem('id_token', claims.__raw)
        }
        localStorage.setItem('access_token', token)
        localStorage.setItem('claims', JSON.stringify(claims))
      } else {
        await auth0FromHook.loginWithRedirect({
          appState: {
            targetUrl: `${window.location.pathname}${window.location.search}`,
          },
        })
      }

      setLoading(false)
    }

    initAuth0()
    // eslint-disable-next-line
  }, [auth0ClientID, auth0Domain])

  if (error) {
    return <AuthError error={error} />
  }

  if (auth0Client === undefined) {
    return <div />
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        getIdTokenClaims: (...p: any[]) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any[]) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p: any[]) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any[]) => auth0Client.getTokenWithPopup(...p),
        logout: (...p: any[]) => auth0Client.logout(...p),
      }}>
      {loading ? <LinearProgress /> : children}
    </AuthContext.Provider>
  )
}
