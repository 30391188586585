import { types } from './userActions'
import { Action } from '../types'

interface State {
  FirstName: string
  LastName: string
  ExtUsername: string
  Email: string
  Avatar?: any
  ExtID?: string
  GoZeroNotifications?: number
  ID?: number
  IsActive?: boolean
  IsGoZero?: boolean
  MemberID?: number
  CurrentUser: any
  UserPermissions: any[]
}

export const userInitialState: State = {
  FirstName: '',
  LastName: '',
  ExtUsername: '',
  Email: '',
  CurrentUser: {},
  UserPermissions: [],
}

export default function reducer(state: State, action: Action) {
  const { payload } = action
  switch (action.type) {
    case types.GET_USER_INFO:
      return {
        ...state,
        CurrentUser: {
          ...action.payload,
        },
      }
    case types.GET_USERS_AND_PERMISSIONS:
      return {
        ...state,
        UserPermissions: payload.userPermissions,
      }
    case types.GET_CHART_PERMISSIONS_FOR_CURRENT_USER:
      return {
        ...state,
        CurrentUserPermissions: {
          ...payload,
        },
      }
    case types.GET_PERMISSIONS_FOR_USER:
      return {
        ...state,
        SelectedUserInfo: payload,
      }
    case types.CLEAR_SELECTED_USER:
      return {
        ...state,
        SelectedUserInfo: null,
      }
    default:
      return { ...state }
  }
}
