// Import in individual reducers here
import userReducer, { userInitialState } from './user/userReducer'
import employerReducer, {
  employerInitialState,
} from './employer/employerReducer'
import eligibilityReducer, {
  eligibilityInitialState,
} from './eligibility/eligibilityReducer'
import memberReducer, { memberInitialState } from './member/memberReducer'
import snackbarReducer, {
  snackbarInitialState,
} from './snackbar/snackbarReducer'
import loadingReducer, { loadingInitialState } from './loading/loadingReducer'
import invoiceReducer, { invoiceInitialState } from './invoices/invoiceReducer'
import notificationReducer, {
  notificationsInitialState,
} from './notifications/notificationsReducer'

export type Action = { type: string; payload?: any }

export const mainReducer = (
  {
    user,
    employer,
    member,
    snackbar,
    eligibility,
    loading,
    invoices,
    notifications,
  }: any,
  action: Action
) => {
  return {
    user: userReducer(user, action),
    employer: employerReducer(employer, action),
    eligibility: eligibilityReducer(eligibility, action),
    member: memberReducer(member, action),
    snackbar: snackbarReducer(snackbar, action),
    loading: loadingReducer(loading, action),
    invoices: invoiceReducer(invoices, action),
    notifications: notificationReducer(notifications, action),
  }
}

export const initialState = {
  user: userInitialState,
  employer: employerInitialState,
  snackbar: snackbarInitialState,
  eligibility: eligibilityInitialState,
  member: memberInitialState,
  loading: loadingInitialState,
  invoices: invoiceInitialState,
  notifications: notificationsInitialState,
}
