import React from 'react'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'

/**
 * A customized version of the Material UI text field to fit our needs
 */
export const StyledTextField = styled(TextField)`
  font-family: 'Raleway';
`

export const CustomTextField = (props: any) => {
  // See text field material-ui docs: it honors both a 'inputProps' and 'InputProps' as two DIFFERENT,
  // things, which seems confusing AF. This tries to do as little damage as possible and just pass props
  // straight through, while appending the style rule to the 'InputLabelProps' object (if it exists).
  const { InputLabelProps, ...other } = props
  // Styles to pass down to the input label on the text field
  const inputLabelProps = {
    style: {
      fontFamily: 'Raleway',
    },
    ...InputLabelProps,
  }

  return <StyledTextField InputLabelProps={inputLabelProps} {...other} />
}
