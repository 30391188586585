import * as api from '../../services/thezerocard/api-helper'

export const services = {
  // get all user notification settings
  async getUserNotificationSettings(userId: number) {
    return api.get(`/teamzero/notification_settings/${userId}`)
  },
  // update specific notification
  async putUserNotificationType(
    UserID: number,
    NotificationType: string,
    NotificationFrequency: number
  ) {
    return api.put(
      `/teamzero/notification_setting/${UserID}/notification_type/${NotificationType}`,
      {
        NotificationType,
        NotificationFrequency,
      }
    )
  },
  // turn employer notifications on or off
  async putEmployerNotificationSetting(
    UserID: number,
    EmployerID: number,
    Active: boolean
  ) {
    return api.put(
      `/teamzero/notification_setting/${UserID}/employer/${EmployerID}`,
      {
        EmployerID,
        Active,
      }
    )
  },
}
