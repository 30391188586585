import React from 'react'
import { Button } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import AddUserDialog from './AddUserDialog'

interface AddUserButtonProps {
  onClose: () => void
}

const AddUserButton = ({ onClose }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  return (
    <>
      <Button
        color="primary"
        startIcon={<PersonAdd />}
        onClick={() => setDialogOpen(true)}>
        Add User
      </Button>
      <AddUserDialog
        open={dialogOpen}
        onClose={(userID: number | null) => {
          setDialogOpen(false)
          onClose(userID)
        }}
      />
    </>
  )
}

export default AddUserButton
