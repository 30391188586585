import React from 'react'
import DateField from '../../DateField'
import CheckboxWithDescription from '../../CheckboxWithDescription'

interface SetDeactivationDateProps {
  deactivationDate: string
  setDeactivationDate: (val: string | null) => void
  hasDeactivationDate: boolean
  setHasDeactivationDate: (val: boolean) => void
  initialDate: string
}

const NO_DEACTIVATION_DATE = '01/01/0001'

const SetDeactivationDateStep: React.FC<SetDeactivationDateProps> = (props) => {
  const {
    deactivationDate,
    setDeactivationDate,
    hasDeactivationDate,
    setHasDeactivationDate,
    initialDate,
  } = props

  React.useEffect(() => {
    if (hasDeactivationDate === false && !!deactivationDate) {
      setDeactivationDate(null)
    }
  }, [hasDeactivationDate, setDeactivationDate]) // eslint-disable-line

  return (
    <div style={{ width: '500px' }}>
      {hasDeactivationDate ? (
        <DateField
          value={deactivationDate}
          onChange={setDeactivationDate}
          label="First date of non-coverage"
          disabled={!hasDeactivationDate}
          style={{ width: '220px' }}
        />
      ) : (
        <div style={{ padding: '0.5rem 0' }}>
          <strong>
            Deactivation date will be removed (ie. eligible until further
            notice)
          </strong>
        </div>
      )}
      {initialDate !== NO_DEACTIVATION_DATE && (
        <CheckboxWithDescription
          value={!hasDeactivationDate}
          onChange={(e: any) => {
            setHasDeactivationDate(!e.target.checked)
          }}
          label="Remove deactivation date"
          description="Permanently eligible until changed."
        />
      )}
    </div>
  )
}

export default SetDeactivationDateStep
