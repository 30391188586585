import React from 'react'
import { File, ContentType, Rejection } from './types'
import {
  PictureAsPdf as PdfIcon,
  Image as ImageIcon,
  Description as FileIcon,
  Error as ErrorIcon,
} from '@material-ui/icons'
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  LinearProgress,
} from '@material-ui/core'

const PNG: ContentType = 'image/png'
const PDF: ContentType = 'application/pdf'
const CSV: ContentType = 'text/csv'
const XLSX: ContentType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

interface FileListProps {
  files: File[]
  rejectedFiles: Rejection[]
  uploadStatus: number
  errors: any
}
export const FileList = ({
  errors,
  uploadStatus,
  files,
  rejectedFiles,
}: FileListProps) => {
  const getFileIcon = (type: ContentType) => {
    switch (type) {
      case PNG:
        return <ImageIcon fontSize="large" />
      case PDF:
        return <PdfIcon fontSize="large" />
      case CSV:
      case XLSX:
      default:
        return <FileIcon fontSize="large" />
    }
  }

  const fileListItems = (ff) => {
    return ff.map((file: any) => {
      console.debug('File: ', file, uploadStatus)
      const err = errors ? errors[file.name] : null
      return (
        <ListItem key={file.path}>
          {err ? (
            <ListItemIcon>
              <ErrorIcon fontSize="large" style={{ color: 'red' }} />
            </ListItemIcon>
          ) : (
            <ListItemIcon>{getFileIcon(file.type)}</ListItemIcon>
          )}
          <ListItemText
            primary={file.path}
            /* @todo, this needs to be modified to accept upload status for individual files.
             * i attempted this briefly and it is compounded by the fact that uploads are a single thread
             * triggered by a user interaction, and thus we cant use "useState" values...since they havent
             * been dispatched yet (see onFileSelected). So we need a better way to track it */
            secondary={
              err ? (
                `There was a problem uploading this file, please try again. Code: ${err}`
              ) : (
                <LinearProgress variant="determinate" value={uploadStatus} />
              )
            }
          />
        </ListItem>
      )
    })
  }

  return files.length > 0 ? (
    <aside>
      <List>{fileListItems(files)}</List>
      {rejectedFiles?.length > 0 ? (
        <List>{fileListItems(rejectedFiles)}</List>
      ) : null}
    </aside>
  ) : null
}
