import { Column } from './index'
import { defaultDateFormatter, moneyFormatter } from '../../../formatters'

export interface Invoices {
  Number: number
  DateOfIssue: string
  DueDate: string
  Amount: number
  ID: number
  Status: string
  TypeID: number
  Notes: string
}

export interface InvoiceColumns {
  [index: string]: Column
  DateOfIssue: Column
  Number: Column
  DueDate: Column
  Amount: Column
  ID: Column
  Status: Column
}

export const invoices: InvoiceColumns = {
  Number: {
    defaultValue: '',
    displayValue: 'Number',
  },
  DateOfIssue: {
    defaultValue: '',
    displayValue: 'Date of Issue',
    formatter: defaultDateFormatter,
    sortName: 'DateOfIssue',
  },
  DueDate: {
    defaultValue: '',
    displayValue: 'Due Date',
    formatter: defaultDateFormatter,
  },
  Status: {
    defaultValue: '',
    displayValue: 'Status',
  },
  Amount: {
    defaultValue: '',
    displayValue: 'Amount',
    formatter: moneyFormatter,
  },
  ID: {
    defaultValue: '',
    displayValue: 'ID',
    hidden: true,
  },
}

interface DataTableFormatterProps {
  cell: any
  row: any
}
