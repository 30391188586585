import * as api from '../../services/thezerocard/api-helper'
import {
  APIErrorResponse,
  APIResponse,
} from '../../services/thezerocard/api-helper'
import { TermParams } from './memberActions'

/**
 * Services for the user state to access async data
 * Only to be used in the useMemberState hook
 */

interface GetMemberProps {
  Address?: any
  AddressId?: number
  Dob: string
  Email: string
  FirstName: string
  Honorific: string
  ID: number
  LastName: string
  MiddleName: string
  Phone: string
  SSNLast4: string
  Suffix: string
  [x: string]: any
}

interface GetMemberAPIResponse extends APIResponse {
  Data: GetMemberProps
}

export const services = {
  async getMember(
    ID: number,
    EmployerID: number
  ): Promise<GetMemberAPIResponse | APIErrorResponse> {
    const response = await api.get(
      `/teamzero/employer/${EmployerID}/member/${ID}`
    )
    return response
  },
  async termMembership(ID: number, params: TermParams) {
    const response = await api.put(`/teamzero/eligibility/deactivation_date`, {
      EmployerMemberID: ID,
      DeactivationDate: params.DeactivationDate,
      NoDeactivationDate: params.NoDeactivationDate,
      IncludedDependentEmployerMemberIDs:
        params.IncludedDependentEmployerMemberIDs,
      SkipUpdateUntil: params.SkipUpdateUntil,
    })

    return response
  },
}
