import { services } from './eligibilityServices'
import { loadingActions } from '../loading/loadingActions'
import { Dispatch } from '../types'

export const types = {
  ELIGIBILITY_SEARCH: 'ELIGIBILITY_SEARCH',
}

export interface Filter {
  FirstName?: string | null
  LastName?: string | null
  DOB?: string | null
  SSNLast4?: string | null
  EmployerIDs?: number[]
}

export interface EligibilityParams {
  filter: Filter
  page?: number
}

export interface NewMemberParams {
  IsSubscriber: boolean
  SubscriberID: number | null
  EmployerID: number | null
  FirstName: string | null
  MiddleName: string | null
  LastName: string | null
  Suffix: string | null
  DateOfBirth: string | null
  Gender: string | null
  SSN: string | null
  Email: string | null
  UseAddressID: number | null // not displayed or bound to a field, only used for "use same address as subscriber"
  Address1: string
  Address2: string
  City: string
  State: string
  Zip: string
  HomePhone: string | null
  WorkPhone: string | null
  RelationToSubscriber: string | null
  BenefitStart: string | null
  BenefitEnd: string | null
  Notes: string | null
  DivisionID: number | null
  SubsetHandle: string | null
  CarrierName: string | null
  PlanName: string | null
}

export interface EditMemberParams {
  MemberID: number | null
  FirstName: string | null
  MiddleName: string | null
  LastName: string | null
  Suffix: string | null
  DateOfBirth: string | null
  Gender: string | null
  SSN: string | null
  Email: string | null
  Address1: string | null
  Address2: string | null
  City: string | null
  State: string | null
  Zip: string | null
  Phone: string | null
  SubsetHandle: string | null
  DivisionID: number | null
  CarrierName: string | null
  PlanName: string | null
  ApplySubsetChangeToDependents: boolean | null
}

const { updateEligibilitySearchLoading, updateGetValidationFileLoading } =
  loadingActions

export const eligibilityActions = {
  async clearEligibilitySearch(dispatch: Dispatch, params: EligibilityParams) {
    return this.eligibilitySearch(dispatch, {
      ...params,
      page: 0,
    })
  },
  async getValidationFile(dispatch: Dispatch, employerID: number) {
    updateGetValidationFileLoading(dispatch, true)
    const response = await services.getValidationFile(employerID)
    updateGetValidationFileLoading(dispatch, false)

    const downloadUrl = response.Data ? response.Data.URL : null

    return downloadUrl
  },
  async eligibilitySearch(dispatch: Dispatch, params: EligibilityParams) {
    updateEligibilitySearchLoading(dispatch, true)
    const response = await services.eligibilitySearch(params)
    updateEligibilitySearchLoading(dispatch, false)

    const { Data, Meta } = response
    const error = Data.Error ? Data.Error : null
    const eligibilityData = error ? [] : Data
    const count = error ? 0 : Meta?.Total

    dispatch({
      type: types.ELIGIBILITY_SEARCH,
      payload: {
        eligibleMembers: eligibilityData,
        count,
        eligibilityError: error,
        params,
      },
    })
  },
  async addEligibilityMember(params: any) {
    const response = await services.addEligibilityMember(params)
    return response
  },
  async getAddressForMemberID(memberID: number) {
    const response = await services.getAddressForMemberID(memberID)
    return response
  },
  async getMemberDetail(memberID: number, employerID: number) {
    const response = await services.getMemberDetail(memberID, employerID)
    return response
  },
  async saveEligibilityMemberEdit(params: any, employerID: number) {
    const response = await services.saveEligibilityMemberEdit(
      params,
      employerID
    )
    return response
  },
  async postEligibilityFile(params: any) {
    const response = await services.postEligibilityFile(params)
    return response
  },
  async postAccumulatorFile(params: any) {
    const response = await services.postAccumulatorFile(params)
    return response
  },
  async getEligibilitySubsetHandles(employerID: number, payload?: any) {
    const response = await services.getEligibilitySubsetHandles(
      employerID,
      payload
    )
    return response?.Data || []
  },
  async getDivisions(employerID: number) {
    const response = await services.getDivisions(employerID)
    return response?.Data || []
  },
}
