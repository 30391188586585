import { Column } from './index'
import utils from '../../../utils/index'

export interface EligibleMembers {
  [index: string]: Column
  ActivationDate: Column
  DOB: Column
  DeactivationDate: Column
  DisplayAddress: Column
  DivisionName: Column
  EmployerId: Column
  EmployerMemberId: Column
  EmployerName: Column
  FirstName: Column
  FirstNameAka: Column
  Gender: Column
  InsuranceGroupId: Column
  InsuranceMemberId: Column
  IntegrationID: Column
  IsCurrentlyActive: Column
  IsSubscriber: Column
  LastName: Column
  LastNameAka: Column
  Latitude: Column
  Longitude: Column
  MemberID: Column
  MiddleName: Column
  Problematic: Column
  SSNLastFour: Column
  SubsetHandle: Column
}

export const eligibleMemberCols = {
  IsCurrentlyActive: {
    name: 'Currently Active?',
    details: {
      dataFormat: utils.boolCheckFormatter,
      valProperty: 'checked',
    },
  },
  FirstName: { name: 'First Name', details: { sortName: 'first_name' } },
  LastName: { name: 'Last Name', details: { sortName: 'last_name' } },
  DOB: { name: 'DOB', details: { dataFormat: utils.defaultDateFormatter } },
  DisplayAddress: { name: 'Address' },
  ActivationDate: {
    name: 'Activation Date',
    details: {
      dataFormat: utils.defaultDateFormatter,
      width: '175px',
      sortName: 'activation_date',
    },
  },
  DeactivationDate: {
    name: 'Deactivation Date',
    details: {
      dataFormat: utils.defaultDateFormatter,
      width: '175px',
      sortName: 'deactivation_date',
    },
  },
  DivisionName: { name: 'Division' },
  Gender: { name: 'Gender' },
  IntegrationID: { name: 'Member ID' },
  IsSubscriber: {
    name: 'Is Subscriber?',
    details: {
      dataFormat: utils.boolCheckFormatter,
      valProperty: 'checked',
    },
  },
  SSNLastFour: { name: 'SSN (last four)' },
  SubsetHandle: {
    name: 'Employer Group',
    details: {
      dataFormat: (cell, row, data) => {
        if (
          data.SubsetHandle &&
          data.SubsetHandle.length &&
          data.SubsetHandle.length > 0
        ) {
          const subset = data.SubsetHandle.find(
            (handle) => handle.Handle == cell
          )
          return subset ? subset.EmployerDescription : cell
        }
        return cell
      },
    },
  },
}

export const eligibleMembers: EligibleMembers = {
  IsCurrentlyActive: {
    defaultValue: false,
    displayValue: 'Currently Active?',
  },
  FirstName: {
    defaultValue: '',
    displayValue: 'First Name',
  },
  MiddleName: {
    defaultValue: '',
    displayValue: 'Middle Name',
    hidden: true,
  },
  LastName: {
    defaultValue: '',
    displayValue: 'Last Name',
  },
  DOB: {
    defaultValue: '',
    displayValue: 'DOB',
    isDate: true,
  },
  DisplayAddress: {
    defaultValue: '',
    displayValue: 'Address',
    minWidth: '175px',
  },
  ActivationDate: {
    defaultValue: '',
    displayValue: 'Activation Date',
    isDate: true,
    minWidth: '175px',
  },
  DeactivationDate: {
    defaultValue: '',
    displayValue: 'Deactivation Date',
    isDate: true,
    tooltip: 'First date of non-coverage',
    minWidth: '175px',
  },
  DivisionName: {
    defaultValue: '',
    displayValue: 'Division',
  },
  EmployerId: {
    defaultValue: '',
    displayValue: 'Employer ID',
    hidden: true,
  },
  EmployerMemberId: {
    defaultValue: '',
    displayValue: 'Employer Member ID',
    hidden: true,
  },
  EmployerName: {
    defaultValue: '',
    displayValue: 'Employer',
    hidden: true,
  },
  FirstNameAka: {
    defaultValue: '',
    displayValue: 'Nickname',
    hidden: true,
  },
  Gender: {
    defaultValue: '',
    displayValue: 'Gender',
  },
  InsuranceGroupId: {
    defaultValue: '',
    displayValue: 'Insurance Group ID',
    hidden: true,
  },
  InsuranceMemberId: {
    defaultValue: '',
    displayValue: 'Insurance Member ID',
    hidden: true,
  },
  IntegrationID: {
    defaultValue: '',
    displayValue: 'Member ID',
    minWidth: '115px',
  },
  IsSubscriber: {
    defaultValue: false,
    displayValue: 'Is Subscriber?',
  },
  LastNameAka: {
    defaultValue: '',
    displayValue: 'Nickname 2',
    hidden: true,
  },
  Latitude: {
    defaultValue: 0,
    displayValue: 'Latitude',
    hidden: true,
  },
  Longitude: {
    defaultValue: 0,
    displayValue: 'Longitude',
    hidden: true,
  },
  MemberID: {
    defaultValue: 0,
    displayValue: 'MemberID',
    hidden: true,
  },
  Problematic: {
    defaultValue: false,
    displayValue: 'Problematic',
    hidden: true,
  },
  SSNLastFour: {
    defaultValue: '',
    displayValue: 'SSN (last four)',
  },
  SubsetHandle: {
    defaultValue: '',
    displayValue: 'Employer Group',
  },
}
