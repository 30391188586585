import styled from 'styled-components'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { COLORS } from '../../utils/colors'

export const StyledCell = styled(TableCell)`
  color: ${COLORS.BW.INK_BLACK};
  font-family: 'Raleway';
`

export const ClickableTableRow = styled(TableRow)`
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`
