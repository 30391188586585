import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { COLORS } from '../../utils/colors'

const StyledCircularProgress = styled(CircularProgress)`
  margin-top: 50px;
  color: ${COLORS.EMPLOYERS.TUSCANY};
  width: 100px;
  height: 100px;
`

/**
 * Custom Style Wrapper on the Material UI circular component.
 *
 * @param props - accepts boolean 'loading'
 */
export const LoadingIndicator = (props: any) => {
  if (!props.loading) {
    return null
  }

  return (
    <StyledCircularProgress
      variant="indeterminate"
      thickness={4}
      size={props.size ? props.size : 50}
      {...props}
    />
  )
}
