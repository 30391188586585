import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios'

// @todo need to set a default here somehow
const BASE_URL =
  localStorage.getItem('employer_base_url') || 'https://local.zero.health'
const DEFAULT_SORT_PARAMS = []
const DEFAULT_RANGE_PARAMS = { page: 0, pageSize: 50 }

interface CustomAxiosClientInstance extends AxiosInstance {
  customNetworkDownHandler?: any
}

const axiosClient: CustomAxiosClientInstance = axios.create({
  baseURL: BASE_URL,
})

export interface APIResponse extends Omit<AxiosResponse, 'data'> {
  Data: any
  Meta: APIMetaResult
}

export interface APIMetaResult {
  Range: number[]
  Total: number
}

export interface APIErrorResponse {
  Data: any
  Meta?: any
  config: any
  response?: { data: any; status: number }
}

// TODO: this needs to be updated
axiosClient.interceptors.response.use(
  function (response: AxiosResponse<any, any>): APIResponse {
    return apiResponse(response)
  },
  function (error: APIErrorResponse): APIErrorResponse | Promise<never> {
    console.debug('Error response from API: ', error)
    if (error && error.response && error.response.status === 401) {
      return Promise.reject({
        Data: {
          Error: { DetailedMessage: 'Unauthorized' },
          Meta: { RequestId: 'Unknown' },
          error: true,
        },
      })
    }

    if (!error.response) {
      if (axiosClient.customNetworkDownHandler !== undefined)
        return axiosClient.customNetworkDownHandler(error.config)
      return Promise.reject({
        Data: {
          Error: { DetailedMessage: 'Network Down' },
          Meta: { RequestId: 'Unknown' },
          error: true,
        },
      })
    }

    console.error('Detailed error for debugging: ', error.response.data)

    return {
      Data: {
        ...error.response.data,
        error: true,
      },
      config: error.config,
    }
  }
)

const apiResponse = (res: AxiosResponse<any, any>): APIResponse => {
  const { data, ...resNoData } = res
  const Data = data.Data === undefined ? data.data : data.Data

  return {
    Data,
    Meta: data.Meta || null,
    ...resNoData,
  }
}

// NOTE: this diverges from the other apps in that it is 0 based
const computeRange = (page: number, pageSize: number): string => {
  let recordStart: number = 1
  if (page !== 0) recordStart = page * pageSize + 1

  return JSON.stringify([recordStart, (page + 1) * pageSize])
}

export const get = (url: string, params: any = '') => {
  let requestUrl: string = BASE_URL + url

  if (params) {
    requestUrl += '?' + queryParams(params)
  }

  return axiosClient.get<any, APIResponse | APIErrorResponse>(
    requestUrl,
    getBaseConfig()
  )
}

interface SearchParams {
  page?: number
  pageSize?: number
  sort?: any[]
  filter?
}

export const search = (
  url: string,
  {
    page = DEFAULT_RANGE_PARAMS.page,
    pageSize = DEFAULT_RANGE_PARAMS.pageSize,
    sort = DEFAULT_SORT_PARAMS,
    filter,
  }: SearchParams
) => {
  let requestUrl: string = BASE_URL + url

  let qsParams: any = {
    range: computeRange(page, pageSize),
    sort: JSON.stringify(sort),
  }

  if (filter) qsParams.filter = JSON.stringify(filter)

  requestUrl += '?' + queryParams(qsParams)

  return axiosClient.get<any, APIResponse | APIErrorResponse>(
    requestUrl,
    getBaseConfig()
  )
}

export const patch = (url: string, body: any) => {
  const requestUrl: string = BASE_URL + url

  return axiosClient.patch<any, APIResponse | APIErrorResponse>(
    requestUrl,
    body,
    getBaseConfig()
  )
}

export const post = (url: string, body: any) => {
  const requestUrl: string = BASE_URL + url

  return axiosClient.post<any, APIResponse | APIErrorResponse>(
    requestUrl,
    body,
    getBaseConfig()
  )
}

export const put = (url: string, body: any) => {
  const requestUrl: string = BASE_URL + url

  return axiosClient.put<any, APIResponse | APIErrorResponse>(
    requestUrl,
    body,
    getBaseConfig()
  )
}

interface CustomHeaders extends AxiosRequestHeaders {
  Accept: string
  Authorization: string
}

interface BaseConfig extends AxiosRequestConfig {
  headers: CustomHeaders
}
// body is optional
export const del = (url: string, body: any = undefined) => {
  const requestUrl: string = BASE_URL + url
  const config: BaseConfig = getBaseConfig()

  if (body !== undefined) {
    config.data = body
  }

  return axiosClient.delete<any, APIResponse | APIErrorResponse>(
    requestUrl,
    config
  )
}

const getBaseConfig = (): BaseConfig => {
  return {
    headers: getHeaders(),
  }
}

const getHeaders = (): CustomHeaders => {
  const token = localStorage.getItem('id_token')

  return {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
}

export const queryParams = (params: any): string => {
  return Object.keys(params)
    .map((k) => {
      let value: any = params[k]
      if (value && typeof value === 'string') {
        value = value.trim()
      }
      return encodeURIComponent(k) + '=' + encodeURIComponent(value)
    })
    .join('&')
}

export const url = BASE_URL
