import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Checkbox,
  Typography,
} from '@material-ui/core'
import {
  eligibilityActions,
  EditMemberParams,
} from '../../redux/eligibility/eligibilityActions'
import { CustomTextField } from '../../components/CustomTextField'
import { EligSubsetHandlesSelector } from '../Eligibility/EligibilitySubsetHandleSelector'
import { DivisionSelector } from '../Eligibility/DivisionSelector'
import { GenderSelector, SSNInput } from '../../components/Inputs'
import { SectionHeader, UnstyledList } from './styledComponents'
import { useStateValue } from '../../redux'
import { snackbarActions } from '../../redux/snackbar/snackbarActions'
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  margin-top: 14px;
`

const StyledText = styled(Typography)`
  margin-top: 14px;
  font-size: 14px;
`

interface Props {
  memberID: number
  isOpen: boolean
  onClose: Function
  onUpdated: Function
  employerID: number
}

const memberParamsInitialState = {
  MemberID: 0,
  FirstName: '',
  MiddleName: '',
  LastName: '',
  Suffix: '',
  DateOfBirth: '',
  Gender: '',
  SSN: '',
  Email: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Zip: '',
  Phone: '',
  DivisionID: 0,
  SubsetHandle: '',
  CarrierName: '',
  PlanName: '',
  ApplySubsetChangeToDependents: null,
}

const validatorsInitialState = {
  FirstName: {
    data: {},
    check: (v: string) => {
      return v && v.length > 1
    },
  },
  LastName: {
    data: {},
    check: (v: string) => {
      return v && v.length > 1
    },
  },
  DateOfBirth: {
    data: {},
    check: (v: string) => {
      return v && v.length > 1
    },
  },
  SSN: {
    data: {},
    check: (v: string) => {
      return v && v.length > 1
    },
  },
  Address1: {
    data: {},
    check: (v: string) => {
      return v && v.length > 0
    },
  },
  City: {
    data: {},
    check: (v: string) => {
      return v && v.length > 0
    },
  },
  State: {
    data: {},
    check: (v: string) => {
      return v && v.length > 0
    },
  },
  Zip: {
    data: {},
    check: (v: string) => {
      return v && v.length > 0
    },
  },
  Gender: {
    data: {},
    check: (v: string) => {
      return v && v.length > 0
    },
  },
}

export default function EditMemberDetailsDialog({
  memberID,
  isOpen,
  onClose,
  onUpdated,
  employerID,
}: Props) {
  const [open, setOpen] = useState(isOpen)
  const [originalSubset, setOriginalSubset] = useState<string>('')
  const [, dispatch]: any = useStateValue()

  const [payload, setPayload] = useState<EditMemberParams>(
    Object.assign({}, memberParamsInitialState)
  )

  const [validators, setValidators] = useState<any>(
    Object.assign({}, validatorsInitialState)
  )

  const getMemberData = async function (memberID: number): Promise<any> {
    const resp = await eligibilityActions.getMemberDetail(memberID, employerID)
    const errs = resp?.Data?.Error || []
    if (errs.length) {
      snackbarActions.setMessage(
        {
          type: 'error',
          message: (
            <UnstyledList>
              {errs.map((e: string) => (
                <li key={e}>{e}</li>
              ))}
            </UnstyledList>
          ),
        },
        dispatch
      )
      doCloseDialog()
      return
    }
    // @ts-ignore
    const memberData = resp?.Data || {}
    setPayload({
      MemberID: memberID,
      FirstName: memberData.FirstName,
      MiddleName: memberData.MiddleName,
      LastName: memberData.LastName,
      Suffix: memberData.Suffix,
      DateOfBirth: memberData.DateOfBirth,
      Gender: memberData.Gender,
      SSN: memberData.SSN,
      Email: memberData.Email,
      Address1: memberData.Address?.Address1,
      Address2: memberData.Address?.Address2,
      City: memberData.Address?.City,
      State: memberData.Address?.State,
      Zip: memberData.Address?.Zip,
      Phone: memberData.Phone,
      DivisionID: memberData.DivisionID,
      SubsetHandle: memberData.SubsetHandle,
      PlanName: memberData.PlanName,
      CarrierName: memberData.CarrierName,
      ApplySubsetChangeToDependents: null,
    })
    setOriginalSubset(memberData.SubsetHandle || '')
  }

  useEffect(() => {
    setPayload(Object.assign({}, memberParamsInitialState))
    setValidators(Object.assign({}, validatorsInitialState))
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!open) {
      return
    }
    getMemberData(memberID)
  }, [open])

  const doCloseDialog = () => {
    onClose()
    setOpen(false)
  }

  const onFieldChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target
    setPayload((prev: EditMemberParams) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = ev.target
    setPayload((prev: EditMemberParams) => ({
      ...prev,
      [name]: checked,
    }))
  }

  const handleSubsetChange = (val: string) => {
    setPayload((prev: EditMemberParams) => ({
      ...prev,
      SubsetHandle: val,
      ApplySubsetChangeToDependents: originalSubset !== val,
    }))
  }

  const handleDivisionChange = (val: number) => {
    setPayload((prev: EditMemberParams) => ({
      ...prev,
      DivisionID: val,
    }))
  }

  const validate = (): boolean => {
    let isValid = true
    Object.keys(validators).forEach((k) => {
      // @ts-ignore
      if (!validators[k].check(payload[k])) {
        isValid = false
        setValidators((prev: any) => {
          const next = Object.assign({}, prev)
          next[k].data = {
            error: true,
            helperText: validators[k].message || 'Required',
          }
          return next
        })
      } else {
        setValidators((prev: any) => {
          const next = Object.assign({}, prev)
          next[k].data = {}
          return next
        })
      }
    })
    return isValid
  }

  const doEditMember = async () => {
    if (!validate()) {
      return
    }
    const resp = await eligibilityActions.saveEligibilityMemberEdit(
      Object.assign({}, payload, {
        Dob: payload.DateOfBirth,
      }),
      employerID
    )
    // @ts-ignore
    const errs = resp?.Data?.Error || []
    if (errs.length) {
      snackbarActions.setMessage(
        {
          type: 'error',
          message: (
            <UnstyledList>
              {errs.map((e: string) => (
                <li key={e}>{e}</li>
              ))}
            </UnstyledList>
          ),
        },
        dispatch
      )
      return
    }
    snackbarActions.setMessage(
      { type: 'success', message: 'Member details saved' },
      dispatch
    )
    doCloseDialog()
    onUpdated()
  }

  return (
    <Dialog
      open={open}
      onClose={doCloseDialog}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Member Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <div>
              <SectionHeader className="reduced-top-margin">
                Member Personal Info
              </SectionHeader>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <CustomTextField
                    {...validators.FirstName.data}
                    fullWidth
                    value={payload.FirstName}
                    name="FirstName"
                    label="First"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <CustomTextField
                    fullWidth
                    value={payload.MiddleName}
                    name="MiddleName"
                    label="Middle"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <CustomTextField
                    {...validators.LastName.data}
                    fullWidth
                    value={payload.LastName}
                    name="LastName"
                    label="Last"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <CustomTextField
                    fullWidth
                    value={payload.Suffix}
                    name="Suffix"
                    label="Suffix"
                    onChange={onFieldChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <CustomTextField
                    {...validators.DateOfBirth.data}
                    fullWidth
                    value={payload.DateOfBirth}
                    InputLabelProps={{ shrink: true }}
                    name="DateOfBirth"
                    placeholder="01/01/1992"
                    label="Date Of Birth"
                    type="date"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <SSNInput
                    {...validators.SSN.data}
                    fullWidth
                    value={payload.SSN}
                    name="SSN"
                    label="SSN"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md>
                  <GenderSelector
                    {...validators.Gender.data}
                    onChange={onFieldChange}
                    value={payload.Gender}
                    helperText={'Required'}
                  />
                </Grid>
              </Grid>
            </div>

            <div>
              <SectionHeader>Member Address</SectionHeader>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12} md={7}>
                  <CustomTextField
                    {...validators.Address1.data}
                    fullWidth
                    value={payload.Address1}
                    name="Address1"
                    label="Address 1"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <CustomTextField
                    fullWidth
                    value={payload.Address2}
                    name="Address2"
                    label="Address 2"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    {...validators.City.data}
                    fullWidth
                    value={payload.City}
                    name="City"
                    label="City"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    {...validators.State.data}
                    fullWidth
                    value={payload.State}
                    name="State"
                    label="State"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CustomTextField
                    {...validators.Zip.data}
                    fullWidth
                    value={payload.Zip}
                    name="Zip"
                    label="Zip"
                    onChange={onFieldChange}
                  />
                </Grid>
              </Grid>
            </div>

            <div>
              <SectionHeader>Member Contact Info</SectionHeader>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12} md={8}>
                  <CustomTextField
                    fullWidth
                    value={payload.Email}
                    name="Email"
                    label="Email"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    value={payload.Phone}
                    name="Phone"
                    label="Home Phone"
                    onChange={onFieldChange}
                  />
                </Grid>
              </Grid>
            </div>

            <div>
              <SectionHeader>Employer Info</SectionHeader>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                  {payload.MemberID === 0 ? null : (
                    <EligSubsetHandlesSelector
                      employerID={employerID}
                      onChange={handleSubsetChange}
                      subset={payload.SubsetHandle}
                    />
                  )}
                </Grid>
                {originalSubset === payload.SubsetHandle ? null : (
                  <Grid container xs={12} md={6} alignItems="center">
                    <StyledCheckbox
                      checked={payload.ApplySubsetChangeToDependents}
                      onChange={onCheckboxChange}
                      name="ApplySubsetChangeToDependents"
                    />
                    <StyledText>
                      Apply Employer Group Change to Dependents?
                    </StyledText>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  {payload.MemberID === 0 ? null : (
                    <DivisionSelector
                      employerID={employerID}
                      onChange={handleDivisionChange}
                      divisionID={payload.DivisionID}
                    />
                  )}
                </Grid>
              </Grid>
            </div>

            <div>
              <SectionHeader>Insurance Info</SectionHeader>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    fullWidth
                    value={payload.CarrierName}
                    name="CarrierName"
                    label="Carrier Name"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    fullWidth
                    value={payload.PlanName}
                    name="PlanName"
                    label="Plan Name"
                    onChange={onFieldChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingTop: '1.5rem' }}>
        <Grid container xs={12} justify="space-between" alignItems="center">
          <Grid item>
            <Button onClick={doCloseDialog} color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={doEditMember} color="primary">
              Save Member Edits
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
