import React from 'react'
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core'
import { Sortable, Dir } from '../Types'
import { Columns } from '../Models'
import Tooltip from '../../Tooltip'

interface Props {
  columns?: Columns
  hasActions?: boolean
  canEdit?: boolean
  sortable: Sortable
  sortHandler: (sort: Sortable) => void
  isCheckable?: boolean
  toggleCheckAll?: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

const ColumnTooltip: React.FC<{ title: string }> = (props) => (
  <Tooltip
    title={props.title}
    style={{ marginLeft: '3px', color: '#bfbfbf' }}
  />
)

export const EnhancedTableHead = (props: Props) => {
  const onClickSort = (col: string, dir: Dir) => () => {
    props.sortHandler({ col, dir })
  }

  const {
    columns,
    isCheckable = false,
    hasActions,
    canEdit,
    toggleCheckAll = () => null,
    sortable,
  } = props

  // Remove hidden columns
  const filteredCols = Object.keys(columns!).filter((col) => {
    const currentCol = columns![col]
    if (currentCol && currentCol.hidden) return false
    return true
  })

  return (
    <TableHead>
      <TableRow>
        {isCheckable ? (
          <TableCell padding="checkbox">
            <Checkbox onChange={(ev) => toggleCheckAll(ev)} />
          </TableCell>
        ) : null}
        {hasActions ? <TableCell padding="checkbox">Actions</TableCell> : null}
        {canEdit ? <TableCell padding="checkbox">Edit</TableCell> : null}

        {filteredCols.map((col, i) => {
          const { displayValue, sortName, tooltip, minWidth } = columns![col]
          const sortEnabled = !!sortName

          // Sorting is NOT enabled
          if (!sortEnabled) {
            return (
              <TableCell
                key={i}
                align="left"
                padding="default"
                style={{ minWidth }}>
                {displayValue}
                {!!tooltip && <ColumnTooltip title={tooltip} />}
              </TableCell>
            )
          }

          // Sorting IS enabled
          return (
            <TableCell
              key={i}
              align="left"
              padding="default"
              style={{ minWidth }}
              sortDirection={sortable.dir ? sortable!.dir : false}>
              <TableSortLabel
                active={sortable.col === sortName}
                direction={sortable.dir ? sortable.dir! : 'asc'}
                onClick={onClickSort(
                  sortName ? sortName : '',
                  sortable.dir === 'asc' ? 'desc' : 'asc'
                )}>
                {displayValue}
                {!!tooltip && <ColumnTooltip title={tooltip} />}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
