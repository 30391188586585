/* eslint-disable indent */
import { types } from './memberActions'
import { Action } from '../types'
import moment from 'moment'

interface State {
  Address?: any
  AddressId?: number
  Dob?: string
  EmployerMemberships?: any[]
  Honorific?: string
  ID?: number
  FirstName?: string
  LastName?: string
  MiddleName?: string
  Phone?: string
  SSNLast4?: string
  Suffix?: string
  Email?: string
}

export const memberInitialState = {
  FirstName: '',
  LastName: '',
}

export default function reducer(state: State, action: Action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_MEMBER:
      return {
        ...state,
        ...payload,
      }
    case types.TERM_MEMBERSHIP:
      if (payload.result && payload.result.success) {
        const { employerMemberID, deactivationDate } = payload.result
        const { EmployerMemberships } = state
        EmployerMemberships &&
          EmployerMemberships.forEach((em) => {
            if (em.ID === employerMemberID) {
              em.DeactivationDate = deactivationDate
              em.IsCurrentlyActive =
                deactivationDate === null ||
                moment(deactivationDate).isAfter(moment())
            }
          })
      }
      return {
        ...state,
        ...payload,
      }
    default:
      return { ...state }
  }
}
