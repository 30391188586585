import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Grid, Typography } from '@material-ui/core'
import { COLORS } from '../../utils/colors'
import { CustomTextField } from '../../components/CustomTextField'
import { EligSubsetHandlesSelector } from './EligibilitySubsetHandleSelector'

export const RootComponent = styled.div``

export const DataTableContainer = styled.div`
  padding-top: 25px;
`
export const OtherTasksContainer = styled.div`
  padding-left: 5px;
  text-align: left;
  width: 100%;
`
export const OtherTasksButton = styled(Button)`
  margin-top: 6px;
  max-width: 225px;
  max-height: 36px;
  margin-right: 5px;
  font-size: 12px;
  padding: 5px;
`

export const OtherTasksButtonSolid = styled(OtherTasksButton)`
  background: ${COLORS.EMPLOYERS.TUSCANY};
`

export const OtherTasksButtonSecondarySolid = styled(OtherTasksButton)`
  background: ${COLORS.EMPLOYERS.ORANGE_SLICE};
`

export const OtherTasksButtonContainer = styled(Grid)`
  display: flex;
  algin-items: flex-start;
  justify-content: flex-start;
`

export const LeftHeaderContainer = styled(Grid)`
  width: 90%;
  max-width: 700px;
  margin-right: 80px;
`

export const ActionButtonContainer = styled(Grid)`
  width: 180px;
  height: 37px;
`

export const BottomRow = styled(Grid)`
  margin-top: 10px;
`

export const BottomRowLeft = styled(Grid)`
  display: flex;
  align-items: center;
`

export const ErrorText = styled(Typography)`
  color: red;
  font-size: 22px;
  font-family: 'Raleway';
  text-align: left;
  margin-top: 20px;
`

export const SearchButton = styled(Button)`
  background: ${COLORS.EMPLOYERS.TUSCANY};
`

export const ClearButton = styled(Button)`
  background: ${COLORS.EMPLOYERS.ORANGE_SLICE};
`

export const FilterText = styled(Typography)`
  font-family: 'Raleway';
  margin-left: 20px;
`

export const DeactivatedAftedField = styled(CustomTextField)`
  width: 167px;
`

export const SubsetHandleFilter = styled(EligSubsetHandlesSelector)`
  width: 167px;
  margin-left: 20px;
  text-align: left;
`

// Remove the step from the right side of the input
export const SsnFieldNoStep = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`
