import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useContext,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as api from '../../services/thezerocard/api-helper'
import { debounce } from 'lodash'
import DataTableV2 from '../../components/DataTable/DataTableV2/DataTableV2'
import { eligibleMemberCols } from '../../components/DataTable/Models/eligibleMembers'
import { Grid, Checkbox } from '@material-ui/core'
import { CustomTextField } from '../../components/CustomTextField'
import {
  SearchButton,
  ClearButton,
  SsnFieldNoStep,
  LeftHeaderContainer,
  ActionButtonContainer,
  ErrorText,
  FilterText,
  BottomRow,
  BottomRowLeft,
  DeactivatedAftedField,
  SubsetHandleFilter,
} from './styledComponents'
import { PageTitle } from '../styledComponents'
import useQueryParamsGen2 from '../../hooks/index'
import moment from 'moment'
import { eligibilityActions } from '../../redux/eligibility/eligibilityActions'

const { getEligibilitySubsetHandles } = eligibilityActions

interface FilterProps {
  EmployerIDs: Array<number>
  FirstName: string
  LastName: string
  DOB: string
  SSNLast4: string
  IsCurrentlyActive: boolean
  DeactivatedAfter: string
  SubsetHandle: string
}

interface contextData {
  employerID: number
  filter: any
  setFilter(v: any): void
  pagination: PageProps
  setPagination(v: any): void
  setLastRefresh(n: number): void
  filterInvalid(filter: FilterProps): string
  setError(err: string): void
}

interface PageProps {
  page: number
  pageSize: number
}

interface SortProps {
  col: string
  dir: string
}

interface Props {
  employerID: number
  passFilters?: any
  customColumns?: any
  // If you want to disable the default row click action, simply pass a no-op as this prop
  onRowClick?(row: any, cmdClicked: boolean): void
  onCheckHandler?(selected: any[]): void
  DataTableProps?: any
  RightHeaderItems?: React.ReactNode
  LeftHeaderItems?: React.ReactNode
  // Allows overriding the endpoint: case in point being with Fee Schedules, where the route
  // decorates the response with additional data
  apiEndpoint?(payload: any): Promise<any>
}

export const defaultColumns = eligibleMemberCols

const sharedContext = React.createContext({} as contextData)

const apiEndpointDefault = (params: any): Promise<any> => {
  return api.search('/teamzero/eligibility', params)
}

const defaultFilters: FilterProps = {
  EmployerIDs: [],
  FirstName: '',
  LastName: '',
  DOB: '',
  SSNLast4: '',
  IsCurrentlyActive: true,
  DeactivatedAfter: '',
  SubsetHandle: '',
}

const EligibilityTable = forwardRef(function EligibibiityTableList(
  {
    employerID,
    onRowClick,
    customColumns = defaultColumns,
    onCheckHandler,
    DataTableProps = {},
    RightHeaderItems,
    LeftHeaderItems,
    apiEndpoint = apiEndpointDefault,
  }: Props,
  ref: any
): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2()
  const history = useHistory()
  const location = useLocation()
  const [lastRefresh, setLastRefresh] = useState<any>(null)
  const [results, setResults] = useState<Array<any>>([])
  const [resultCount, setResultCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<FilterProps>(
    !queryData.filter ? defaultFilters : { ...queryData.filter }
  )
  const [pagination, setPagination] = useState<PageProps>(
    !queryData.pagination
      ? { page: 0, pageSize: 50 }
      : { ...queryData.pagination }
  )
  const [sortable, setSortable] = useState<SortProps>({
    col: 'last_name',
    dir: 'asc',
    ...queryData.sortable,
  })
  const [subsetHandles, setSubsetHandles] = useState<Array<any>>([])
  const [error, setError] = useState<string>('')
  const dataTableProps = {
    ...DataTableProps,
    ...(onCheckHandler ? { checkHandler: onCheckHandler } : {}),
  }

  useImperativeHandle(
    ref,
    () => ({
      refresh() {
        setLastRefresh(Math.round(Math.random() * 10000000000000))
      },
    }),
    [setLastRefresh]
  )

  useEffect(() => {
    getEligibilitySubsetHandles(employerID, {}).then((subsetHandles) => {
      setSubsetHandles(subsetHandles)
    })
  }, [employerID])

  const loadData = useCallback(
    debounce((payload: any) => {
      if (!employerID) return
      setLoading(true)
      apiEndpoint(payload)
        .then((res: any) => {
          if (res.error) {
            throw res
          }
          setResults(res.Data)
          setResultCount(res.Meta?.Total || 0)
          setError('')
        })
        .catch((err: any) => {
          if (err.Error?.Message) return setError(err.Error.Message)
          return setError(
            'Error fetching Eligibility Data, please contact engineering'
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }, 400),
    [setLoading, setResults, setResultCount, employerID]
  )

  useEffect(() => {
    if (filterInvalid(filter)) return
    setError('')

    const payload = {
      filter: { ...filter, EmployerIDs: [employerID] },
      sort: [sortable.col, sortable.dir],
      page: pagination.page,
      pageSize: pagination.pageSize,
    }
    loadData(payload)
    setQueryData({ filter, pagination, sortable })
  }, [sortable, loadData, lastRefresh, setQueryData])

  // Default click handler: if an 'onRowClick' prop is passed, it'll proxy to that and NOT
  // do the default action - which is to navigate to the page (or open in new window, if CMD
  // is held while clicking)

  const filterInvalid = (filter: FilterProps) => {
    if (filter.SSNLast4 && filter.SSNLast4.length !== 4)
      return 'SSN Invalid (Must be 4 numbers long)'
    if (filter.FirstName && filter.FirstName.length < 3)
      return 'First Name Invalid (Must be 3 letters long)'
    if (filter.LastName && filter.LastName.length < 3)
      return 'Last Name Invalid (Must be 3 letters long)'
    if (
      filter.DeactivatedAfter &&
      !moment(filter.DeactivatedAfter, 'YYYY-MM-DD', true).isValid()
    )
      return 'Deactivated After Invalid (Not Valid Date)'
    if (filter.DOB && !moment(filter.DOB, 'YYYY-MM-DD', true).isValid())
      return 'Date of Birth Invalid (Not Valid Date)'
    return ''
  }

  const onRowClickHandler = (_: any, row: any, cmdClicked: boolean): void => {
    if (onRowClick) {
      return onRowClick(row, cmdClicked)
    }
    const pathname = `/employer/${row.EmployerID}/member/${row.MemberID}`
    if (cmdClicked) {
      window.open(pathname, '_blank')
      return
    }
    history.push({
      pathname,
      state: {
        prevSearch: location ? location.search : null,
      },
    })
  }

  const handlePaginationChange = (pagination: PageProps) => {
    setPagination(pagination)
    // Forces table data to refresh after pagination change
    setLastRefresh(Math.round(Math.random() * 10000000000000))
  }

  return (
    <sharedContext.Provider
      value={{
        employerID,
        filter,
        setFilter,
        pagination,
        setPagination,
        setLastRefresh,
        filterInvalid,
        setError,
      }}>
      <Grid container direction="column">
        {(!!LeftHeaderItems || !!RightHeaderItems) && (
          <Grid container direction="row" alignItems="center">
            {!!LeftHeaderItems && (
              <LeftHeaderContainer container>
                {LeftHeaderItems}
              </LeftHeaderContainer>
            )}
            {!!RightHeaderItems && <Grid item>{RightHeaderItems}</Grid>}
          </Grid>
        )}
        {error && <ErrorText>{error}</ErrorText>}

        <DataTableV2
          data={results}
          keyProp="ID"
          columns={customColumns}
          page={pagination.page}
          pageSize={pagination.pageSize}
          count={resultCount * 1}
          onRowClick={onRowClickHandler}
          onChangePage={handlePaginationChange}
          onChangeRowsPerPage={handlePaginationChange}
          sortHandler={setSortable}
          sortable={sortable}
          loading={loading}
          displayData={{
            SubsetHandle: subsetHandles,
          }}
          {...dataTableProps}
        />
      </Grid>
    </sharedContext.Provider>
  )
})

export default EligibilityTable
export { EligibilityTable }

export const EligibilitySearchInputs = () => {
  const {
    employerID,
    filter,
    setFilter,
    pagination,
    setPagination,
    setLastRefresh,
    filterInvalid,
    setError,
  } = useContext(sharedContext)

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const newFilter = { ...filter, [fieldName]: event.target.value }
    setPagination({ ...pagination, page: 0 })
    setFilter(newFilter)
  }

  const handleEligSubsetChange = (value: string) => {
    const newFilter = { ...filter, SubsetHandle: value }
    setPagination({ ...pagination, page: 0 })
    setFilter(newFilter)
    handleFormUpdate()
  }

  const handleChangeActiveFilter = (event: any) => {
    setPagination({ ...pagination, page: 0 })
    setFilter({ ...filter, IsCurrentlyActive: event.target.checked })
  }

  const handleClearClick = () => {
    setPagination({ ...pagination, page: 0 })
    setFilter(defaultFilters)
    updateForm()
  }

  const updateForm = () =>
    setLastRefresh(Math.round(Math.random() * 10000000000000))

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') handleFormUpdate()
  }

  const handleFormUpdate = () => {
    if (filterInvalid(filter)) return setError(filterInvalid(filter))
    updateForm()
  }

  return (
    <Grid container direction="column">
      <PageTitle>Manage Members</PageTitle>
      <Grid container justify="space-between">
        <CustomTextField
          value={filter.FirstName}
          id="first-name"
          label="First Name"
          onChange={(event: any) => handleChange(event, 'FirstName')}
          onKeyPress={(event: any) => onKeyPress(event)}
        />
        <CustomTextField
          value={filter.LastName}
          id="last-name"
          label="Last Name"
          onChange={(event: any) => handleChange(event, 'LastName')}
          onKeyPress={(event: any) => onKeyPress(event)}
        />
        <CustomTextField
          value={filter.DOB}
          InputLabelProps={{ shrink: true }}
          id="dob"
          placeholder="01/01/1992"
          label="Date of Birth"
          type="date"
          onChange={(event: any) => handleChange(event, 'DOB')}
          onKeyPress={(event: any) => onKeyPress(event)}
        />
        <SsnFieldNoStep>
          <CustomTextField
            value={filter.SSNLast4}
            id="ssn"
            label="SSN (Last 4)"
            type="number"
            inputProps={{ maxLength: 4 }}
            onChange={(event: any) => handleChange(event, 'SSNLast4')}
            onKeyPress={(event: any) => onKeyPress(event)}
          />
        </SsnFieldNoStep>
      </Grid>
      <BottomRow container justify="space-between" alignItems="center">
        <BottomRowLeft xs={3}>
          <DeactivatedAftedField
            value={filter.DeactivatedAfter}
            InputLabelProps={{ shrink: true }}
            id="deactivate-date"
            placeholder="01/01/1992"
            label="Deactivated After"
            type="date"
            onChange={(event: any) => handleChange(event, 'DeactivatedAfter')}
            onKeyPress={(event: any) => onKeyPress(event)}
          />
        </BottomRowLeft>
        <Grid item xs={4}>
          <SubsetHandleFilter
            allowEmpty
            employerID={employerID}
            onChange={(value: string) => handleEligSubsetChange(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <FilterText>
            Active:{' '}
            <Checkbox
              checked={filter.IsCurrentlyActive}
              onChange={(event: any) => handleChangeActiveFilter(event)}
            />
          </FilterText>
        </Grid>
        <Grid item xs={3}>
          <ActionButtonContainer
            container
            direction="row"
            justify="space-between">
            <SearchButton
              type="submit"
              variant="contained"
              onClick={handleFormUpdate}>
              Search
            </SearchButton>
            <ClearButton
              variant="contained"
              color="primary"
              onClick={handleClearClick}>
              Clear
            </ClearButton>
          </ActionButtonContainer>
        </Grid>
      </BottomRow>
    </Grid>
  )
}
