import * as api from '../../services/thezerocard/api-helper'
import {
  APIErrorResponse,
  APIResponse,
} from '../../services/thezerocard/api-helper'

export interface InvoicesFilter {
  StatusID?: number[]
  EmployerID?: number[]
}

type Sort = [string, string] //[col, dir]

export interface GetInvoicesParams {
  filter: InvoicesFilter
  page: number
  pageSize: number
  sort?: Sort
}

interface InvoiceResult {
  ID: string
  CreatedAt: string
  Number: number
  DueDate: string
  Status: string
  [key: string]: any
}

interface GetInvoicesAPIResponse extends APIResponse {
  Data: InvoiceResult[]
}

interface GetInvoiceAPIResponse extends APIResponse {
  Data: InvoiceResult
}

export const services = {
  async getInvoice(
    ID: number
  ): Promise<GetInvoiceAPIResponse | APIErrorResponse> {
    const response = await api.get(`/teamzero/invoice/${ID}`)
    return response
  },
  async getInvoiceByUUID(
    UUID: string
  ): Promise<GetInvoiceAPIResponse | APIErrorResponse> {
    const response = await api.get(`/teamzero/invoice/uuid/${UUID}`)
    return response
  },
  async getInvoices(
    params: GetInvoicesParams
  ): Promise<GetInvoicesAPIResponse | APIErrorResponse> {
    return api.search(`/teamzero/invoice`, params)
  },
}
