import React from 'react'
import styled from 'styled-components'
import { FormControlLabel, Checkbox } from '@material-ui/core'

const CheckboxContainer = styled.div`
  margin-top: 10px;
  span.MuiCheckbox-root {
    align-self: start;
  }
  span.MuiTypography-root {
    align-self: start;
    margin-top: 8px;
  }
  .checkbox-label {
    margin: 0px;
    p {
      margin: 0px;
    }
  }
`

interface CheckboxProps {
  value: any
  onChange: any
  label: string
  description: string
  disabled?: boolean
}

const CheckboxWithDescription: React.FC<CheckboxProps> = (props) => (
  <CheckboxContainer>
    <FormControlLabel
      label={
        <div className="checkbox-label">
          <p>{props.label}</p>
          <span style={{ color: 'gray' }}>{props.description}</span>
        </div>
      }
      control={
        <Checkbox
          checked={props.value}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      }
    />
  </CheckboxContainer>
)

export default CheckboxWithDescription
