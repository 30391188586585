import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import {
  RootComponent,
  DataTableContainer,
  OtherTasksContainer,
  OtherTasksButton,
  OtherTasksButtonSolid,
  OtherTasksButtonSecondarySolid,
  OtherTasksButtonContainer,
} from './styledComponents'
import { PageTitle } from '../styledComponents'
import { useStateValue } from '../../redux'
import { UploadEligibilityDialog } from './UploadEligibility'
import { UploadAccumulatorDialog } from './UploadAccumulator'
import { EligibilityTable, EligibilitySearchInputs } from './table'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { eligibilityActions } from '../../redux/eligibility/eligibilityActions'
import { useAppPerms } from '../../AppPermissions'
import { TITLE_TEMPLATE } from '../../utils'
import { useEmployerIDFromRoute } from '../../hooks'

const { getValidationFile, getEligibilitySubsetHandles } = eligibilityActions

interface Props {
  history: any
}

interface FilterProps {
  filter: {
    EmployerIDs: Array<number>
    FirstName: string
    LastName: string
    DOB: string
    SSNLast4: string
  }
}

export const Eligibility = (props: Props) => {
  // Example of how to import redux state into component
  // Subscribe to data and get access to dispatch
  const [{ loading }, dispatch]: any = useStateValue()
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [uploadAccumDialogOpen, setUploadAccumDialogOpen] = useState(false)
  const [showUploadEligButton, setShowUploadEligButton] = useState(false)
  const [showUploadAccumButton, setShowUploadAccumButton] = useState(false)
  const { selectedEmployerID, userPermissions } = useAppPerms()
  const employerID = useEmployerIDFromRoute()

  useEffect(() => {
    if (selectedEmployerID) {
      const fetchSubsetHandles = async () => {
        const subsetHandles =
          await getEligibilitySubsetHandles(selectedEmployerID)
        if (subsetHandles && subsetHandles.length > 0) {
          setShowUploadEligButton(true)
          subsetHandles.forEach((sh) => {
            if (sh.SeparateAccumulatorFile) {
              setShowUploadAccumButton(true)
            }
          })
        } else {
          setShowUploadEligButton(false)
          setShowUploadAccumButton(false)
        }
      }
      fetchSubsetHandles()
    }
  }, [selectedEmployerID, getEligibilitySubsetHandles])

  const generateValidationFile = async () => {
    if (!selectedEmployerID) return false
    const downloadUrl = await getValidationFile(dispatch, selectedEmployerID)
    if (downloadUrl !== null) window.open(downloadUrl)
  }

  const handleRowClick = ({ MemberID }: any) => {
    props.history.push(`/employer/${employerID}/member/${MemberID}`)
  }

  const onAddMember = () => {
    props.history.push(`/employer/${employerID}/eligibility/add-subscriber`)
  }

  const renderRightHeaderItems = () => (
    <Grid container>
      <OtherTasksContainer>
        <PageTitle>Other tasks</PageTitle>
        <OtherTasksButtonContainer direction="column">
          {showUploadEligButton ? (
            <OtherTasksButtonSolid
              variant="contained"
              disabled={!userPermissions.ViewAndSubmitEligibility}
              onClick={() => setUploadDialogOpen(true)}>
              Upload Eligibility File
            </OtherTasksButtonSolid>
          ) : null}
          {showUploadAccumButton ? (
            <OtherTasksButtonSecondarySolid
              variant="contained"
              disabled={!userPermissions.ViewAndSubmitEligibility}
              onClick={() => setUploadAccumDialogOpen(true)}>
              Upload Accumulator File
            </OtherTasksButtonSecondarySolid>
          ) : null}
          <OtherTasksButton
            variant="outlined"
            disabled={!userPermissions.UpdateEligibility}
            onClick={onAddMember}>
            Add New Subscriber/Employee
          </OtherTasksButton>
          <OtherTasksButton
            variant="outlined"
            disabled={!userPermissions.ViewAndSubmitEligibility}
            onClick={generateValidationFile}>
            Generate validation file
          </OtherTasksButton>
          <LoadingIndicator
            size={30}
            loading={loading.apiCalls.getValidationFile}
            style={{ marginTop: 10, marginLeft: 10 }}
          />
        </OtherTasksButtonContainer>
      </OtherTasksContainer>
    </Grid>
  )

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Eligibility</title>
      </Helmet>
      <Grid container></Grid>
      <DataTableContainer>
        <EligibilityTable
          employerID={selectedEmployerID}
          onRowClick={handleRowClick}
          LeftHeaderItems={<EligibilitySearchInputs />}
          RightHeaderItems={renderRightHeaderItems()}
        />
      </DataTableContainer>
      {uploadDialogOpen ? (
        <UploadEligibilityDialog
          open={uploadDialogOpen}
          employerID={selectedEmployerID}
          onClose={() => setUploadDialogOpen(false)}
          onSubmit={() => setUploadDialogOpen(false)}
        />
      ) : null}
      {uploadAccumDialogOpen ? (
        <UploadAccumulatorDialog
          open={uploadAccumDialogOpen}
          employerID={selectedEmployerID}
          onClose={() => setUploadAccumDialogOpen(false)}
          onSubmit={() => setUploadAccumDialogOpen(false)}
        />
      ) : null}
    </RootComponent>
  )
}
