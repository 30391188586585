import React, { useState } from 'react'
import moment from 'moment'
import { Container, Grid, CircularProgress } from '@material-ui/core'
import DateField from '../../components/DateField'
import { Forbidden } from '../Forbidden'
import CheckboxWithDescription from '../../components/CheckboxWithDescription'
import { PageTitle, SolidButton, StandardButton } from '../styledComponents'
import * as api from '../../services/thezerocard/api-helper'
import { useAppPerms } from '../../AppPermissions'
import { notifySnackbar, useStateValue } from '../../redux'

interface PaidClaimsReportProps {
  employerID: number
}

interface checkedDate {
  value: string
  valid: boolean
}

export const PaidClaimsReport = ({ employerID }: PaidClaimsReportProps) => {
  const [startDate, setStartDate] = useState<checkedDate>({
    value: moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY'),
    valid: true,
  })
  const [endDate, setEndDate] = useState<checkedDate>({
    value: moment().startOf('month').format('MM/DD/YYYY'),
    valid: true,
  })
  const [generatingFile, setGeneratingFile] = useState(false)
  const [url, setUrl] = useState<string | null>(null)
  const [includePHI, setIncludePHI] = useState<boolean>(false)
  const { userPermissions } = useAppPerms()
  const [_, dispatch]: any = useStateValue() // eslint-disable-line

  const generateReport = async () => {
    setUrl(null)
    setGeneratingFile(true)

    const start = moment(startDate.value).format('YYYY-MM-DD')
    const end = moment(endDate.value).format('YYYY-MM-DD')

    const res = await api.post(`/teamzero/report/paid_claims_report_request`, {
      EmployerID: employerID,
      PaidStart: start,
      PaidEnd: end,
      IncludePHI: includePHI,
    })
    setGeneratingFile(false)

    // @ts-ignore
    if (res.Data?.error) {
      // @ts-ignore
      notifySnackbar(res.Data, dispatch)
      return
    }

    // @ts-ignore
    const { URL } = res.Data
    if (URL) {
      setUrl(URL)
    }
  }

  const openWithUrl = () => {
    const newTab = window.open()
    if (!newTab || !url) {
      return window.alert('Unable to download file.')
    }
    newTab.location.href = url
    return
  }

  const formatRequestDate = (v: string): checkedDate => {
    if (!v) {
      return { value: v, valid: false }
    }
    const m = moment(v, 'MM/DD/YYYY', true).utc()
    return { value: v, valid: m.isValid() }
  }

  const onStartDateChange = (v: any) => {
    setStartDate(formatRequestDate(v))
  }

  const onEndDateChange = (v: any) => {
    setEndDate(formatRequestDate(v))
  }

  const isReady = (): boolean => {
    return startDate.valid && endDate.valid
  }

  const buttonText = generatingFile ? (
    <>
      <CircularProgress size={20} style={{ marginRight: 3 }} /> Generating...
    </>
  ) : (
    'Generate CSV'
  )

  if (!userPermissions.ViewReportPaidClaims)
    return <Forbidden perm="View Paid Claims" />

  return (
    <div>
      <Container maxWidth="xl">
        <PageTitle>Paid Claims</PageTitle>
        <p>
          <em>
            Input the start and end date that you want to pull claims for and we
            will generate a CSV for you.
          </em>
        </p>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <DateField
              value={startDate.value}
              label="Start"
              onChange={onStartDateChange}
              error={!startDate.valid}
              helperText="Required"
            />
          </Grid>
          <Grid item xs={2}>
            <DateField
              value={endDate.value}
              label="End"
              onChange={onEndDateChange}
              error={!endDate.valid}
              helperText="Required"
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxWithDescription
              label="Include Patient Details (PHI)"
              description="This is only available if you have permission for Paid Claims PHI"
              value={includePHI}
              onChange={(e: any) => {
                setIncludePHI(e.target.checked)
              }}
              disabled={!userPermissions.ViewReportPaidClaimsPHI}
            />
          </Grid>
          <Grid item xs={12}>
            <SolidButton onClick={generateReport} disabled={!isReady()}>
              {buttonText}
            </SolidButton>
          </Grid>
          {url && (
            <Grid item xs={12}>
              <p>
                <em>Your report is ready to download!</em>
              </p>
              <StandardButton variant="outlined" onClick={openWithUrl}>
                Click here to download
              </StandardButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}
