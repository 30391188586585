import React, { ChangeEvent } from 'react'
import { Helmet } from 'react-helmet'
import {
  Container,
  FormControlLabel,
  Switch,
  Radio,
  Typography,
} from '@material-ui/core'
import {
  RootComponent,
  Subtitle,
  RadioGroupInlineFlex,
} from './styledComponents'
import { PageTitle } from '../styledComponents'
import { TITLE_TEMPLATE } from '../../utils'
import { useAppPerms } from '../../AppPermissions'

import { useStateValue } from '../../redux'
import { notificationActions } from '../../redux/notifications/notificationActions'
import { NotificationResponse } from '../../response-types'

const {
  getUserNotificationSettings,
  putUserNotificationType,
  putEmployerNotificationSetting,
} = notificationActions

interface NotificationToggleProps {
  notification: NotificationResponse['Notifications'][number]
  userId: number
}

const NotificationToggle: React.FC<NotificationToggleProps> = ({
  notification,
  userId,
}) => {
  const [, dispatch]: any = useStateValue()
  const {
    NotificationType,
    NotificationTypeDescription,
    NotificationFrequencies,
  } = notification

  const selectedFrequency =
    NotificationFrequencies.find((nf) => nf.Selected)?.NotificationFrequency ||
    0

  const updateNotificationFreq = (freq: number): void => {
    putUserNotificationType(dispatch, userId, NotificationType, freq)
  }

  return (
    <div>
      <Typography>{NotificationTypeDescription}</Typography>
      <div style={{ marginLeft: '18px', marginBottom: '10px' }}>
        <RadioGroupInlineFlex
          value={selectedFrequency}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            updateNotificationFreq(Number(evt.target.value))
          }}>
          {NotificationFrequencies.map((f) => (
            <FormControlLabel
              key={f.NotificationFrequency}
              label={f.NotificationFrequencyDescription}
              value={f.NotificationFrequency}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroupInlineFlex>
      </div>
    </div>
  )
}

interface EmployerToggleProps {
  employer: NotificationResponse['Employers'][number]
  userId: number
}

const EmployerToggle: React.FC<EmployerToggleProps> = ({
  employer,
  userId,
}) => {
  const [, dispatch]: any = useStateValue()
  return (
    <div>
      <FormControlLabel
        label={employer.EmployerName}
        control={
          <Switch
            checked={employer.ReceiveNotifications}
            color="primary"
            onChange={() => {
              putEmployerNotificationSetting(
                dispatch,
                userId,
                employer.EmployerID,
                !employer.ReceiveNotifications
              )
            }}
          />
        }
      />
    </div>
  )
}

const NotificationsView: React.FC = () => {
  const { user } = useAppPerms()
  const [{ notifications }, dispatch] = useStateValue()
  const { Notifications, Employers } = notifications

  React.useEffect(() => {
    getUserNotificationSettings(dispatch, user.ID)
  }, []) // eslint-disable-line

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Notifications</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageTitle>Notifications</PageTitle>
        <Subtitle extraMargin>
          How would you like to receive your notifications?
        </Subtitle>
        {Notifications.map((notif) => (
          <NotificationToggle
            key={notif.NotificationType}
            notification={notif}
            userId={user.ID}
          />
        ))}
        <Subtitle extraMargin>
          Which employers do you want notifications for?
        </Subtitle>
        {Employers.map((employer) => (
          <EmployerToggle
            key={employer.EmployerID}
            employer={employer}
            userId={user.ID}
          />
        ))}
      </Container>
    </RootComponent>
  )
}

export default NotificationsView
