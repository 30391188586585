import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const RootComponent = styled.div`
  text-align: center;
`
export const DataTableContainer = styled.div`
  padding-top: 25px;
`
// todo: move to generic location and enforce
export const Label = styled.h4`
  display: block;
`

export const FieldGridColumn = styled(Grid)`
  overflow-wrap: break-word;
`
