import React, { useState, useEffect } from 'react'
import { DataTable } from '../../components/DataTable'
import { Sortable, DataTableParams } from '../../components/DataTable/Types'
import { InvoiceDialog } from './InvoiceDialog'
import { useStateValue } from '../../redux'
import { invoiceActions } from '../../redux/invoices/invoiceActions'
import { toBoolean } from 'underscore.string'

interface ViewingParams {
  viewingID?: number
  viewing?: boolean
}

type InvoiceTableInitParams = ViewingParams | DataTableParams
interface InvoiceTableProps {
  employerID: number
  initParams: InvoiceTableInitParams
  persistSearch: (params: FindInvoiceParams) => void
  persistViewInvoice: (params: ViewingParams) => void
}

interface InvoiceTableFilter {
  EmployerID?: number[]
  StatusID?: number[]
}

interface FindInvoiceParams {
  filter: InvoiceTableFilter
  page: number
  pageSize: number
  sortable: Sortable
}

export const InvoiceTable = ({ employerID, initParams, ...props }: any) => {
  const [{ invoices, loading }, dispatch]: any = useStateValue()
  const [filter, setFilter] = useState<InvoiceTableFilter>({
    EmployerID: [employerID],
    ...initParams.filter,
  })
  const [dialogOpen, setDialogOpen] = useState(
    initParams.viewing ? toBoolean(initParams.viewing) : false
  )
  const [viewingID, setViewingID] = useState(
    initParams.viewingID ? initParams.viewingID * 1 : 0
  )

  const [viewingUUID, setViewingUUID] = useState(
    initParams.viewingUUID ? initParams.viewingUUID : ''
  )

  const [sortable, setSortable] = useState<Sortable>({
    col: 'DateOfIssue',
    dir: 'desc',
  })
  const [page, setPage] = useState(initParams.page ? initParams.page * 1 : 0)
  const [pageSize, setPageSize] = useState(
    initParams.pageSize ? initParams.pageSize * 1 : 50
  )
  const { getInvoices } = invoiceActions

  const doFindInvoices = ({
    filter,
    page,
    pageSize,
    sortable,
  }: FindInvoiceParams) => {
    setFilter(filter)
    props.persistSearch({ filter, page, pageSize, sortable })

    const payload: any = { filter, page, pageSize }
    if (sortable.col && sortable.dir) {
      payload.sort = [sortable.col, sortable.dir]
    }
    getInvoices(payload, dispatch)
  }

  useEffect(() => {
    doFindInvoices({
      filter: { EmployerID: [employerID] },
      sortable,
      page,
      pageSize,
    })
  }, [employerID, page, pageSize]) // eslint-disable-line

  const handleRowClick = ({ ID, InvoiceUUID }: any) => {
    setDialogOpen(true)
    if (!!InvoiceUUID) {
      setViewingUUID(InvoiceUUID)
      setViewingID(0)
      props.persistViewInvoice({ viewingID: 0, viewingUUID: InvoiceUUID })
      return
    }

    setViewingID(ID)
    setViewingUUID('')
    props.persistViewInvoice({ viewingID: ID, viewingUUID: '' })
  }

  const handleChangePage = ({ page, rowsPerPage }: any) => {
    setPage(page)
    setPageSize(rowsPerPage)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setViewingID(0)
    setViewingUUID('')
    props.persistViewInvoice({ viewingID: 0, viewingUUID: '' })
  }

  const sortHandler = (sortable: Sortable) => {
    setSortable(sortable)

    doFindInvoices({ filter, page, pageSize, sortable })
  }

  const dataTable = (
    <DataTable
      onChangePage={handleChangePage}
      model="invoices"
      data={invoices.invoices}
      count={invoices.count}
      error={invoices.error}
      sortable={sortable}
      initPageSize={pageSize}
      initPage={page}
      handleRowClick={handleRowClick}
      sortHandler={sortHandler}
      loading={loading.apiCalls.getInvoices}
    />
  )

  return (
    <div>
      {dataTable}
      {dialogOpen && (
        <InvoiceDialog
          open={dialogOpen}
          invoiceID={viewingID}
          invoiceUUID={viewingUUID}
          onClose={handleDialogClose}
        />
      )}
    </div>
  )
}

InvoiceTable.defaultProps = {
  persistSearch: () => {},
}
