import React from 'react'
import { Tooltip as CoreTooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

type Props = Omit<React.ComponentProps<typeof CoreTooltip>, 'children'>

const Tooltip: React.FC<Props> = (props) => (
  <CoreTooltip {...props}>
    <InfoOutlined />
  </CoreTooltip>
)

export default Tooltip
