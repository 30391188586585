import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStateValue } from '../../../redux'
import { userActions } from '../../../redux/user/userActions'
import { useAppPerms } from '../../../AppPermissions'
import { Column } from '../index'

interface Props {
  open: boolean // Display the modal if true, hide if false
  onClose: () => void // Function that updates when the modal is closed
  UserID: string | null
  EmployerID: string
  reportConfig: Column[]
  permissionConfig: Column[]
}

export const PermissionsTableModal = ({
  open,
  UserID,
  onClose,
  EmployerID,
  reportConfig,
  permissionConfig,
}: Props) => {
  const [{ user, loading }, dispatch]: any = useStateValue()
  const { SelectedUserInfo, CurrentUser } = user
  const {
    getPermissionsForUser,
    putUsersPermissionHandle,
    getUsersAndPermissions,
    clearSelectedUser,
    getChartPermissionsForCurrentUser,
  } = userActions
  const [permissionCheckboxes, updatePermissionCheckboxes] = useState<
    JSX.Element[]
  >([])
  const [reportCheckboxes, updateReportCheckboxes] = useState<JSX.Element[]>([])
  const [permissionsForSaving, updatePermissionsForSaving] = useState<{
    [index: string]: { value: boolean } | null
  }>({})
  const { reinitialize, selectedEmployer } = useAppPerms()

  // Fetch fresh user information if userID changes
  useEffect(() => {
    if (UserID && EmployerID) {
      getPermissionsForUser(dispatch, UserID, EmployerID)
    }
  }, [UserID, EmployerID, dispatch, getPermissionsForUser])

  // Prepopulate the permissionsForSavingObject
  useEffect(() => {
    if (SelectedUserInfo && reportConfig && permissionConfig) {
      const objectToSave: { [index: string]: { value: boolean } } = {}
      _.forEach(reportConfig, (obj: any) => {
        objectToSave[obj.key] = SelectedUserInfo[obj.key]
      })
      _.forEach(permissionConfig, (obj: any) => {
        objectToSave[obj.key] = SelectedUserInfo[obj.key]
      })

      updatePermissionsForSaving(objectToSave)
    }
  }, [reportConfig, permissionConfig, SelectedUserInfo])

  // Create an array of permission cells
  useEffect(() => {
    if (_.isEmpty(permissionsForSaving)) return
    const genCheckbox = (item: any): JSX.Element => {
      return (
        <Grid item xs={6} key={item.key}>
          <FormControlLabel
            label={item.name}
            control={
              <Checkbox
                // @ts-ignore
                checked={permissionsForSaving[item.key]}
                onChange={() => {
                  updatePermissionsForSaving((currValue: any) => {
                    return {
                      ...currValue,
                      [item.key]: !currValue[item.key],
                    }
                  })
                }}
                name="checkedB"
                color="primary"
              />
            }
          />
        </Grid>
      )
    }

    const reportCheckboxes = _.map(reportConfig, genCheckbox)
    const permissionCheckboxes = _.map(permissionConfig, genCheckbox)
    updateReportCheckboxes(reportCheckboxes)
    updatePermissionCheckboxes(permissionCheckboxes)
  }, [reportConfig, permissionConfig, permissionsForSaving])

  // Modal isn't open so hide it
  if (!open) {
    return null
  }

  const cleanup = () => {
    clearSelectedUser(dispatch)
    updatePermissionsForSaving({})
    updatePermissionCheckboxes([])
    updateReportCheckboxes([])
    onClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        PaperProps={{
          style: {
            minWidth: 800,
            minHeight: 600,
          },
        }}
        onBackdropClick={() => {
          // Clear all data so the modal doesn't pre-render it next time
          clearSelectedUser(dispatch)
          updatePermissionCheckboxes([])
          updateReportCheckboxes([])
          updatePermissionsForSaving({})
        }}>
        {loading?.global ? (
          <CenteredLoadingIndicator />
        ) : (
          <>
            <DialogTitle>
              Edit Permissions For User
              <RemoveUserButton
                employerID={SelectedUserInfo?.EmployerID}
                userID={SelectedUserInfo?.UserID}
                username={SelectedUserInfo?.UserInfo?.Email}
                employerName={selectedEmployer?.Name}
                onRemove={() => {
                  reinitialize()
                  setTimeout(
                    () => getUsersAndPermissions(dispatch, selectedEmployer.ID),
                    100
                  )
                  cleanup()
                }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText style={{ color: 'black' }}>
                <h4>User Information</h4>
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    zeroMinWidth
                    style={{ overflowWrap: 'break-word' }}>
                    <em>Email</em>
                    <p>{_.get(SelectedUserInfo, 'UserInfo.Email', null)}</p>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid
                    item
                    xs={3}
                    zeroMinWidth
                    style={{ overflowWrap: 'break-word' }}>
                    <em>First Name</em>
                    <p>{_.get(SelectedUserInfo, 'UserInfo.FirstName', null)}</p>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid
                    item
                    xs={3}
                    zeroMinWidth
                    style={{ overflowWrap: 'break-word' }}>
                    <em>Last Name</em>
                    <p>{_.get(SelectedUserInfo, 'UserInfo.LastName', null)}</p>
                  </Grid>
                </Grid>
                <h4>Permissions</h4>
                <Grid container>{permissionCheckboxes}</Grid>
                <h4>Reports</h4>
                <Grid container>{reportCheckboxes}</Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  cleanup()
                }}
                color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // Save the permissions object
                  putUsersPermissionHandle(
                    dispatch,
                    permissionsForSaving,
                    SelectedUserInfo.UserID,
                    SelectedUserInfo.EmployerID
                  ).then((res: any) => {
                    reinitialize()
                    // Call get all users to make sure it hydrates fresh data in the table
                    getUsersAndPermissions(
                      dispatch,
                      SelectedUserInfo.EmployerID
                    )
                    // If this user is the one who is currently logged in then re-pull their permissions
                    if (
                      CurrentUser &&
                      SelectedUserInfo.UserID === CurrentUser.ID
                    ) {
                      // Wait a second and then update the current user in case they changed their permissions
                      getChartPermissionsForCurrentUser(
                        dispatch,
                        CurrentUser.ID,
                        EmployerID
                      )
                    }
                    cleanup()
                  })
                }}
                color="primary">
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  )
}

const CenteredLoadingIndicator = () => {
  const CenterDiv = styled.div`
    height: 600px; // matches the min-height
    display: flex;
    justify-content: center;
    align-items: center;
  `
  return (
    <CenterDiv>
      <CircularProgress size={50} />
    </CenterDiv>
  )
}

interface RemoveUserButtonProps {
  onRemove: () => void
  employerID: number
  userID: number
  username: string
  employerName: string
}

const RemoveUserButton = ({
  onRemove,
  employerID,
  userID,
  username,
  employerName,
}: RemoveUserButtonProps) => {
  const [{ user }, dispatch]: any = useStateValue()
  const { deleteUser } = userActions
  const [showConfirm, updateShowConfirm] = useState<boolean>(false)

  const FloatRightButton = styled(Button)`
    float: right;
  `

  return (
    <>
      <FloatRightButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          updateShowConfirm(true)
        }}>
        Remove User
      </FloatRightButton>
      <ConfirmRemoveDialog
        open={showConfirm}
        onConfirm={() => {
          deleteUser(dispatch, employerID, userID)
          onRemove()
          updateShowConfirm(false)
        }}
        onCancel={() => {
          updateShowConfirm(false)
        }}
        username={username}
        employerName={employerName}
      />
    </>
  )
}

interface ConfirmRemoveProps {
  username: string
  employerName: string
  onConfirm: () => void
  onCancel: () => void
  open: boolean
}

const ConfirmRemoveDialog = ({
  username,
  employerName,
  onConfirm,
  onCancel,
  open,
}: ConfirmRemoveProps) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirm Remove User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove {username}? They will no longer have
          access to {employerName} in teamZERO.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
