import React from 'react'
import { FileLinkBtn } from '../../components/FileLinkBtn/FileLinkBtn'

interface InvoiceFileBtnProps {
  fileID: number
  invoiceID: number
  fileType: any
  filename: string
}

export const InvoiceFileBtn = ({
  invoiceID,
  fileID,
  filename,
  ...props
}: InvoiceFileBtnProps) => {
  const preSignUrl = `/teamzero/invoice/${invoiceID}/presign_download/${fileID}`

  return (
    <FileLinkBtn
      preSignUrl={preSignUrl}
      filename={filename}
      style={{ marginRight: 5 }}
    />
  )
}
