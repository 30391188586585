import { types } from './employerActions'
import { Action } from '../types'

export const employerInitialState = {
  selectedEmployer: {},
  employers: [],
}

export default function reducer(state: any, action: Action) {
  const { payload } = action

  switch (action.type) {
    case types.UPDATE_SELECTED_EMPLOYER:
    case types.GET_EMPLOYERS:
      return {
        ...state,
        ...payload,
      }
    default:
      return { ...state }
  }
}
