import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useAppPerms, Employer } from '../../AppPermissions'

// Styled Components
const StyledSelect = styled(Select)`
  font-family: 'Raleway';
`

/**
 * Material UI select component to select an employer and update in redux state
 */
export const EmployerSelect = () => {
  const { employers, updateSelectedEmployerID, selectedEmployerID } =
    useAppPerms()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateSelectedEmployerID(event.target.value)
  }

  const menuItemComponents = _.map(employers, (emp: Employer, i: number) => {
    return (
      <MenuItem value={emp.ID} key={i}>
        {emp.Name}
      </MenuItem>
    )
  })

  return (
    <FormControl>
      <StyledSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedEmployerID}
        onChange={handleChange}>
        {menuItemComponents}
      </StyledSelect>
    </FormControl>
  )
}
