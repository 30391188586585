import React from 'react'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
import {
  Input,
  InputLabel,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import { CustomSelectField } from '../CustomSelectField'

const SSNMask = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="XXX-XX-XXXX"
    />
  )
}

const DateMask = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[0-1]/, /\d/, '/', /\d/, /\d/, '/', /[0-2]/, /\d/, /\d/, /\d/]}
      placeholder="MM/DD/YYYY"
    />
  )
}

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Raleway';
`

const MaskInput = (props: any) => {
  const {
    name,
    label,
    value,
    onChange,
    maskInput,
    error,
    helperText,
    showHelper,
    className,
    ...other
  } = props
  const { disabled } = other
  return (
    <FormControl className={className} error={error}>
      <StyledInputLabel disabled={disabled}>{label}</StyledInputLabel>
      <Input
        {...other}
        name={name}
        value={value}
        onChange={onChange}
        inputComponent={maskInput}
      />
      {(error || showHelper) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const SSNInput = (props: any) => {
  return <MaskInput maskInput={SSNMask} {...props} />
}

export const DateInput = (props: any) => {
  return <MaskInput maskInput={DateMask} {...props} />
}

const genderOptions = [
  { v: '', label: '---' },
  { v: 'F', label: 'Female' },
  { v: 'M', label: 'Male' },
  { v: 'O', label: 'Other/Prefer not to identify' },
]

export const GenderSelector = ({
  value,
  onChange,
  helperText = '',
  ...props
}: any) => {
  console.debug('props: ', props)
  return (
    <>
      <InputLabel shrink htmlFor="gender-labelled" style={{ marginTop: '5px' }}>
        Gender
      </InputLabel>
      <CustomSelectField
        onChange={onChange}
        value={value}
        {...props}
        fullWidth
        InputProps={{
          name: 'Gender',
          id: 'gender-labelled',
          style: { paddingTop: '1px' },
          helperText: helperText,
        }}>
        {genderOptions.map((item) => {
          return <option value={item.v}>{item.label}</option>
        })}
      </CustomSelectField>
      {props.error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  )
}
