import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useStateValue } from '../../../redux'
import { Grid } from '@material-ui/core'
import { RootComponent } from './styledComponents'
import { useEmployerIDFromRoute } from '../../../hooks'
import { TITLE_TEMPLATE } from '../../../utils'

export const Home = () => {
  const [{ user }]: any = useStateValue()
  const { CurrentUserPermissions } = user
  const employerID = useEmployerIDFromRoute()

  if (!user && !CurrentUserPermissions) return <div />

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Home</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} sm={8}>
          {CurrentUserPermissions?.ViewAndSubmitEligibility ? (
            <Redirect to={`/employer/${employerID}/eligibility`} />
          ) : (
            <Redirect to={`/employer/${employerID}/reporting`} />
          )}
        </Grid>
      </Grid>
    </RootComponent>
  )
}
