import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'
import { eligibilityActions } from '../../../redux/eligibility/eligibilityActions'
import { use } from 'msw/lib/types/utils/internal/requestHandlerUtils'

const { getDivisions } = eligibilityActions

interface DivisionSelectorProps {
  employerID: number
  onChange: (val: number) => void
  divisionID?: number
}

interface Division {
  ID: number
  Name: string
}
const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`

export const DivisionSelector = ({
  employerID,
  onChange,
  divisionID,
}: DivisionSelectorProps) => {
  const [divisionItems, setDivisionItems] = useState([])
  const [selectedDivisionID, setSelectedDivisionID] =
    useState<number>(divisionID)
  const [selectedDivision, setSelectedDivision] = useState<Division>({
    ID: 0,
    Name: '',
  })

  useEffect(() => {
    const fetchDivisions = async () => {
      let divisions = await getDivisions(employerID)

      const newDivisions = divisions.map((division: Division) => {
        return (
          <MenuItem value={division.ID} key={division.ID}>
            {division.Name}
          </MenuItem>
        )
      })
      setDivisionItems(newDivisions)

      if (divisions && divisions.length === 1) {
        // auto select if there is only 1 and member does not already have a division selected
        if (!selectedDivisionID) {
          setSelectedDivisionID(divisions[0].ID)
          onChange(divisions[0].ID)
        }
      }
    }

    fetchDivisions()
  }, [employerID])

  useEffect(() => {
    // set selectedDivision
    if (selectedDivisionID) {
      const selected = divisionItems.find(
        (division: Division) => division.ID === selectedDivisionID
      )
      setSelectedDivision(selected)
    }

    if (!selectedDivisionID) {
      setSelectedDivision({ ID: 0, Name: '' })
    }
  }, [selectedDivisionID])

  const divisionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as number
    onChange(selected)
    setSelectedDivisionID(selected)
  }

  return (
    <FullWidthFormControl>
      <InputLabel htmlFor={'division-selector'}>Division</InputLabel>
      <Select
        label="Employer Group"
        name="division-selector"
        onChange={divisionChange}
        value={selectedDivisionID}>
        {divisionItems}
      </Select>
    </FullWidthFormControl>
  )
}
