import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import {
  eligibilityActions,
  NewMemberParams,
} from '../../../redux/eligibility/eligibilityActions'
import { RootComponent, Masthead, ErrDisplay } from './styledComponents'
import { PageTitle, SectionHeader } from '../../styledComponents'
import { CustomTextField } from '../../../components/CustomTextField'
import { CustomSelectField } from '../../../components/CustomSelectField'
import { GenderSelector, SSNInput } from '../../../components/Inputs'
import { useAppPerms } from '../../../AppPermissions'
import { default as _get } from 'lodash/get'
import moment from 'moment'
import { TITLE_TEMPLATE } from '../../../utils'
import { useEmployerIDFromRoute } from '../../../hooks'
import { EligSubsetHandlesSelector } from '../EligibilitySubsetHandleSelector'
import { DivisionSelector } from '../DivisionSelector'

interface Props {
  history: any
  location: any
  requireSubscriber: boolean
}

const dependentOptions = [
  { v: '', label: '- Required -' },
  { v: 'C', label: 'Child' },
  { v: 'S', label: 'Spouse' },
  { v: 'D', label: 'Dependent' },
  { v: 'U', label: 'Unknown' },
]

export const EligibilityAddMember = ({
  history,
  location,
  requireSubscriber,
}: Props) => {
  const { selectedEmployer } = useAppPerms()
  const employerID = useEmployerIDFromRoute()
  const subscriberInfo = location.state?.subscriber
  const canUseSubscriberAddress =
    requireSubscriber &&
    subscriberInfo &&
    subscriberInfo.AddressFromMemberID > 0
  const [resErrors, setResErrors] = useState<Array<string>>([])
  const [payload, setPayload] = useState<NewMemberParams>({
    IsSubscriber: true,
    SubscriberID: null,
    EmployerID: selectedEmployer?.ID,
    FirstName: null,
    MiddleName: null,
    LastName: null,
    Suffix: null,
    DateOfBirth: null,
    Gender: null,
    SSN: null,
    Email: null,
    UseAddressID: null,
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
    HomePhone: null,
    WorkPhone: null,
    RelationToSubscriber: !subscriberInfo ? 'I' : null,
    DivisionID: null,
    SubsetHandle: null,
    CarrierName: subscriberInfo ? subscriberInfo.CarrierName : null,
    PlanName: subscriberInfo ? subscriberInfo.PlanName : null,
    BenefitStart: null,
    BenefitEnd: null,
    Notes: null,
  })

  const [validators, setValidators] = useState<any>({
    FirstName: {
      data: {},
      check: (v: string) => {
        return v && v.length > 1
      },
    },
    LastName: {
      data: {},
      check: (v: string) => {
        return v && v.length > 1
      },
    },
    DateOfBirth: {
      data: {},
      check: (v: string) => {
        return v && v.length > 1
      },
    },
    SSN: {
      data: {},
      check: (v: string) => {
        return !requireSubscriber ? v && v.length > 1 : true
      },
    },
    BenefitStart: {
      data: {},
      check: (v: string) => {
        return v && v.length > 1
      },
    },
    BenefitEnd: {
      data: {},
      check: (v: string) => {
        if (!v) return true
        if (v.length < 10) return false

        let now = new Date()
        let oneYearOut = new Date()
        oneYearOut.setFullYear(oneYearOut.getFullYear() + 1)
        let benefitEndDate = new Date(v)
        return now <= benefitEndDate && benefitEndDate <= oneYearOut
      },
      message: 'Must be blank, and 1 year or less in the future',
    },
    RelationToSubscriber: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
    Address1: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
    City: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
    State: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
    SubsetHandle: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
    Zip: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
    Gender: {
      data: {},
      check: (v: string) => {
        return v && v.length > 0
      },
    },
  })

  const onFieldChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target
    setPayload((prev: NewMemberParams) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validate = (): boolean => {
    let isValid = true
    Object.keys(validators).forEach((k) => {
      // @ts-ignore
      if (!validators[k].check(payload[k])) {
        isValid = false
        setValidators((prev: any) => {
          const next = Object.assign({}, prev)
          next[k].data = {
            error: true,
            helperText: validators[k].message || 'Required',
          }
          return next
        })
      } else {
        setValidators((prev: any) => {
          const next = Object.assign({}, prev)
          next[k].data = {}
          return next
        })
      }
    })
    return isValid
  }

  const doCreateMember = async () => {
    if (!validate()) {
      return
    }
    const data = Object.assign({}, payload, {
      IsSubscriber: true,
      EmployerID: selectedEmployer?.ID,
      DateOfBirth: moment.utc(payload.DateOfBirth),
      BenefitStart: moment.utc(payload.BenefitStart),
      BenefitEnd: moment.utc(payload.BenefitEnd),
    })
    if (subscriberInfo) {
      Object.assign(data, {
        IsSubscriber: false,
        SubscriberEmployerMemberID: subscriberInfo.EmployerMemberID,
      })
    }

    const resp = await eligibilityActions.addEligibilityMember(data)
    const errs = _get(resp, 'Data.Error', [])
    if (errs.length) {
      setResErrors(errs)
      return
    }

    const memberID = _get(resp, 'Data.MemberID', null)
    history.push(`/employer/${employerID}/member/${memberID}`)
  }

  const handleSubsetChange = (val: string) => {
    setPayload((prev: NewMemberParams) => ({
      ...prev,
      SubsetHandle: val,
    }))
  }

  const handleDivisionChange = (val: number) => {
    setPayload((prev: NewMemberParams) => ({
      ...prev,
      DivisionID: val,
    }))
  }

  const handleUseSubscriberAddress = async (
    ev: React.MouseEvent<HTMLInputElement>
  ) => {
    const target: any = ev.target
    if (target.checked) {
      const resp = await eligibilityActions.getAddressForMemberID(
        subscriberInfo.AddressFromMemberID
      )
      const errs = _get(resp, 'Data.Error', [])
      if (errs.length) {
        setResErrors(errs)
        return
      }
      setPayload((prev: NewMemberParams) => ({
        ...prev,
        UseAddressID: _get(resp, 'Data.ID', null),
        Address1: _get(resp, 'Data.Address1', ''),
        Address2: _get(resp, 'Data.Address2', ''),
        City: _get(resp, 'Data.City', ''),
        State: _get(resp, 'Data.State', ''),
        Zip: _get(resp, 'Data.Zip', ''),
      }))
      return
    }
    setPayload((prev: NewMemberParams) => ({
      ...prev,
      UseAddressID: null,
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zip: '',
    }))
  }

  if (requireSubscriber && !subscriberInfo) {
    return (
      <RootComponent>
        <p>
          <strong>Error</strong>
          <br />
          You're likely seeing this after refreshing your browser. When adding a
          dependent...
        </p>
      </RootComponent>
    )
  }

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Add Member</title>
      </Helmet>
      {requireSubscriber ? (
        <Masthead>
          <PageTitle>Add a new Dependent</PageTitle>
          <p>
            Add a new dependent of existing member{' '}
            <strong>
              {subscriberInfo.FirstName} {subscriberInfo.LastName}
            </strong>{' '}
            (employed by <strong>{selectedEmployer?.Name}</strong>).
          </p>
        </Masthead>
      ) : (
        <Masthead>
          <PageTitle>Add a new Subscriber</PageTitle>
          <p>
            Add a new member as a <i>subscriber/employee</i>, for employer{' '}
            <strong>{selectedEmployer?.Name}</strong>. Subscribers are employees
            directly enrolled for ZERO benefits. If you need to add a{' '}
            <i>dependent</i> of an existing member, please navigate back to the
            Eligibility page, locate the subscriber, and use the "Add Dependent"
            button.
          </p>
        </Masthead>
      )}
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={8}>
          <div>
            <SectionHeader>Member Personal Info</SectionHeader>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <CustomTextField
                  {...validators.FirstName.data}
                  fullWidth
                  value={payload.FirstName}
                  name="FirstName"
                  label="First"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CustomTextField
                  fullWidth
                  value={payload.MiddleName}
                  name="MiddleName"
                  label="Middle"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CustomTextField
                  {...validators.LastName.data}
                  fullWidth
                  value={payload.LastName}
                  name="LastName"
                  label="Last"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CustomTextField
                  fullWidth
                  value={payload.Suffix}
                  name="Suffix"
                  label="Suffix"
                  onChange={onFieldChange}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <CustomTextField
                  {...validators.DateOfBirth.data}
                  fullWidth
                  value={payload.DateOfBirth}
                  InputLabelProps={{ shrink: true }}
                  name="DateOfBirth"
                  placeholder="01/01/1992"
                  label="Date Of Birth"
                  type="date"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <SSNInput
                  {...validators.SSN.data}
                  fullWidth
                  value={payload.SSN}
                  name="SSN"
                  label="SSN"
                  placeholder="123-45-6789"
                  onChange={onFieldChange}
                  helperText={
                    !requireSubscriber
                      ? 'Required when adding subscriber/employee'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md>
                <GenderSelector
                  {...validators.Gender.data}
                  onChange={onFieldChange}
                  value={payload.Gender}
                  helperText={'Required'}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <SectionHeader>Member Address</SectionHeader>
            {canUseSubscriberAddress && (
              <label>
                <input type="checkbox" onClick={handleUseSubscriberAddress} />{' '}
                Use same address as subscriber/employee
              </label>
            )}
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={7}>
                <CustomTextField
                  {...validators.Address1.data}
                  fullWidth
                  value={payload.Address1}
                  name="Address1"
                  label="Address 1"
                  onChange={onFieldChange}
                  disabled={!!payload.UseAddressID}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomTextField
                  fullWidth
                  value={payload.Address2}
                  name="Address2"
                  label="Address 2"
                  onChange={onFieldChange}
                  disabled={!!payload.UseAddressID}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  {...validators.City.data}
                  fullWidth
                  value={payload.City}
                  name="City"
                  label="City"
                  onChange={onFieldChange}
                  disabled={!!payload.UseAddressID}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  {...validators.State.data}
                  fullWidth
                  value={payload.State}
                  name="State"
                  label="State"
                  onChange={onFieldChange}
                  disabled={!!payload.UseAddressID}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  {...validators.Zip.data}
                  fullWidth
                  value={payload.Zip}
                  name="Zip"
                  label="Zip"
                  onChange={onFieldChange}
                  disabled={!!payload.UseAddressID}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <SectionHeader>Member Contact Info</SectionHeader>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  value={payload.Email}
                  name="Email"
                  label="Email"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  value={payload.HomePhone}
                  name="HomePhone"
                  label="Home Phone"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  value={payload.WorkPhone}
                  name="WorkPhone"
                  label="Work Phone"
                  onChange={onFieldChange}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <SectionHeader>Member Insurance Info</SectionHeader>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  value={payload.CarrierName}
                  name="CarrierName"
                  label="Carrier Name"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  value={payload.PlanName}
                  name="PlanName"
                  label="Plan Name"
                  onChange={onFieldChange}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <SectionHeader>Miscellaneous Info</SectionHeader>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  value={payload.Notes}
                  name="Notes"
                  label="Notes"
                  multiline
                  rows={4}
                  rowsMax={4}
                  onChange={onFieldChange}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <div>
            <SectionHeader>Benefit Dates</SectionHeader>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  {...validators.BenefitStart.data}
                  fullWidth
                  value={payload.BenefitStart}
                  InputLabelProps={{ shrink: true }}
                  name="BenefitStart"
                  placeholder="01/01/1992"
                  label="Benefit Start"
                  type="date"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  {...validators.BenefitEnd.data}
                  fullWidth
                  value={payload.BenefitEnd}
                  InputLabelProps={{ shrink: true }}
                  name="BenefitEnd"
                  placeholder="01/01/1992"
                  label="Benefit End"
                  type="date"
                  onChange={onFieldChange}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <SectionHeader>Employee Group</SectionHeader>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <EligSubsetHandlesSelector
                  error={validators.SubsetHandle.data?.helperText}
                  employerID={selectedEmployer?.ID}
                  onChange={handleSubsetChange}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <SectionHeader>Division</SectionHeader>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <DivisionSelector
                  employerID={selectedEmployer?.ID}
                  onChange={handleDivisionChange}
                />
              </Grid>
            </Grid>
          </div>

          {subscriberInfo && (
            <div>
              <SectionHeader>
                Relationship With Subscriber/Employee
              </SectionHeader>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <CustomSelectField
                    {...validators.RelationToSubscriber.data}
                    onChange={onFieldChange}
                    value={payload.RelationToSubscriber}
                    fullWidth
                    InputProps={{
                      name: 'RelationToSubscriber',
                      id: 'relation-to-subscriber',
                    }}>
                    {dependentOptions.map((item) => {
                      return <option value={item.v}>{item.label}</option>
                    })}
                  </CustomSelectField>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>

        <Grid container item xs={12} spacing={2}>
          {resErrors.length > 0 && (
            <Grid item xs={12}>
              <ErrDisplay>
                {resErrors.map((msg: string) => {
                  return <span>{msg}</span>
                })}
              </ErrDisplay>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button onClick={doCreateMember} variant="contained" fullWidth>
              Add a new Member to {selectedEmployer?.Name}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </RootComponent>
  )
}
