import { eligibleMembers, EligibleMembers } from '../Models/eligibleMembers'
import { teamZeroUsers, TeamZeroUsers } from '../Models/teamZeroUsers'
import { invoices, InvoiceColumns } from '../Models/invoices'

/**
 * Types and base values for all models that can be read into the table
 */

interface ColumnFormatterProps {
  cell: any
  row: any
}

// Data to display in an individual table cell
export interface Column {
  defaultValue: string | boolean | number // This value is required as it can be used to identify the "type" of the datafield
  displayValue?: string // Readable value for the user to see
  isDate?: boolean // Designates the type as a date for display
  hidden?: boolean // Hide this column from display
  sortName?: string // The name used to sort the column
  formatter?: ({ cell, row }: ColumnFormatterProps) => any
  tooltip?: string
  minWidth?: string
}

export interface Columns {
  [index: string]: Column
}

interface Models {
  [index: string]: EligibleMembers | TeamZeroUsers | InvoiceColumns // Add more types here as we add more models.
}

export const models: Models = {
  eligibleMembers,
  teamZeroUsers,
  invoices,
}
