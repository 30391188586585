import { services, NewUser } from './userServices'
import { Dispatch } from '../types'
import { loadingActions } from '../loading/loadingActions'
import { snackbarActions } from '../snackbar/snackbarActions'

export const types = {
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USERS_AND_PERMISSIONS: 'GET_USERS_AND_PERMISSIONS',
  PUT_USERS_PERMISSION_HANDLE: 'PUT_USERS_PERMISSION_HANDLE',
  GET_PERMISSIONS_FOR_USER: 'GET_PERMISSIONS_FOR_USER',
  CLEAR_SELECTED_USER: 'CLEAR_SELECTED_USER',
  GET_CHART_PERMISSIONS_FOR_CURRENT_USER:
    'GET_CHART_PERMISSIONS_FOR_CURRENT_USER',
  CREATE_USER: 'CREATE_USER',
  DELETE_USER: 'DELETE_USER',
}

// Actions for indicating loading states for API calls
const {
  updateGetUserInfoLoading,
  updateGetUsersAndPermissionsLoading,
  updatePutUsersPermissionHandleLoading,
  updateGetPermissionsForUserLoading,
  updateGetChartPermissionsForCurrentUserLoading,
  updateCreateUserLoading,
  updateDeleteUserLoading,
} = loadingActions

export const userActions = {
  async getUserInfo(dispatch: Dispatch) {
    updateGetUserInfoLoading(dispatch, true)
    const response = await services.getUserWithToken()
    updateGetUserInfoLoading(dispatch, false)

    const userData = response.Data ? response.Data.User : null
    dispatch({
      type: types.GET_USER_INFO,
      payload: {
        ...userData,
      },
    })
  },
  async getUsersAndPermissions(dispatch: Dispatch, employerID: number) {
    updateGetUsersAndPermissionsLoading(dispatch, true)
    const response = await services.getPermissionsAndUsers(employerID)
    updateGetUsersAndPermissionsLoading(dispatch, false)

    const userPermissions = response.Data

    // Check for error that would cause us not to save the permissions
    if (response.Data.Error && response.Data.Error.StatusCode === 403) {
      throw new Error('403')
    }

    dispatch({
      type: types.GET_USERS_AND_PERMISSIONS,
      payload: {
        userPermissions,
      },
    })
  },
  async putUsersPermissionHandle(
    dispatch: Dispatch,
    permissionsObject: any,
    userID: string,
    employerID: string
  ) {
    updatePutUsersPermissionHandleLoading(dispatch, true)
    const response = await services.putUsersPermissionHandle(
      permissionsObject,
      userID,
      employerID
    )
    updatePutUsersPermissionHandleLoading(dispatch, false)
    // Display error if API call fails
    if (response?.Data?.Error) {
      snackbarActions.setMessage(
        {
          message: 'An error occured updating user. Please try again.',
          type: 'error',
        },
        dispatch
      )
    } else {
      snackbarActions.setMessage(
        {
          message: 'Successfully updated permissions for user',
          type: 'success',
        },
        dispatch
      )
    }

    dispatch({
      type: types.PUT_USERS_PERMISSION_HANDLE,
    })
  },
  async getPermissionsForUser(
    dispatch: Dispatch,
    userID: string,
    employerID: string
  ) {
    updateGetPermissionsForUserLoading(dispatch, true)
    const response = await services.getPermissionsForUser(
      Number(userID),
      Number(employerID)
    )
    updateGetPermissionsForUserLoading(dispatch, false)

    const permissionData = response.Data ? response.Data : null

    dispatch({
      type: types.GET_PERMISSIONS_FOR_USER,
      payload: {
        ...permissionData,
      },
    })
  },
  // Get the chart permissions for the user who is currently logged in
  async getChartPermissionsForCurrentUser(
    dispatch: Dispatch,
    userID: string,
    employerID: string
  ) {
    updateGetChartPermissionsForCurrentUserLoading(dispatch, true)
    const response = await services.getPermissionsForUser(
      Number(userID),
      Number(employerID)
    )
    updateGetChartPermissionsForCurrentUserLoading(dispatch, false)

    const permissionData = response.Data ? response.Data : null

    dispatch({
      type: types.GET_CHART_PERMISSIONS_FOR_CURRENT_USER,
      payload: {
        ...permissionData,
      },
    })
  },
  async clearSelectedUser(dispatch: Dispatch) {
    dispatch({
      type: types.CLEAR_SELECTED_USER,
    })
  },
  async createUser(dispatch: Dispatch, employerID: number, newUser: NewUser) {
    updateCreateUserLoading(dispatch, true)
    const response = await services.createUser(employerID, newUser)
    updateDeleteUserLoading(dispatch, false)
    // Display error if API call fails
    if (response?.Data?.Error) {
      snackbarActions.setMessage(
        {
          message: 'An error occured creating a new user. Please try again.',
          type: 'error',
        },
        dispatch
      )
    } else {
      snackbarActions.setMessage(
        { message: 'Successfully created a new user', type: 'success' },
        dispatch
      )
    }

    dispatch({
      type: types.CREATE_USER,
    })
    return response.Data
  },
  async deleteUser(dispatch: Dispatch, employerID: number, userID: number) {
    updateDeleteUserLoading(dispatch, true)
    const response = await services.deleteUser(employerID, userID)
    updateDeleteUserLoading(dispatch, false)
    // Display error if API call fails
    if (response?.Data?.Error) {
      snackbarActions.setMessage(
        {
          message: 'An error occured removing user. Please try again.',
          type: 'error',
        },
        dispatch
      )
    } else {
      snackbarActions.setMessage(
        { message: 'Successfully removed user', type: 'success' },
        dispatch
      )
    }

    dispatch({
      type: types.DELETE_USER,
    })
    return response
  },
}
