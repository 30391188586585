import React from 'react'
import { Container } from '@material-ui/core'
import { PageTitle } from '../styledComponents'

interface ForbiddenProps {
  perm: string
  route?: string | string[] | undefined
}

export const Forbidden = ({ perm, route }: ForbiddenProps) => {
  let routeName = ''
  switch (typeof route) {
    case 'string':
      routeName = route
      break
    case 'object': // really array, but you know, js
      const asArray = route as string[]
      routeName = asArray.join(',')
      break
    case 'undefined':
      routeName = 'this page'
  }

  return (
    <div>
      <Container maxWidth="xl">
        <PageTitle>Sorry, this page requires additional permission.</PageTitle>
        <p>
          <em>
            Ask your teamZERO Administrator for access to{' '}
            {perm.split(/(?=[A-Z])/).join(' ')} if you need to view this page.
          </em>
        </p>
      </Container>
    </div>
  )
}
