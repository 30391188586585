import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Edit as IconEdit } from '@material-ui/icons'

const StyledIconEdit = styled(IconEdit)`
  margin-right: 3px;
`
export const EditButton = (props: any) => {
  return (
    <Button {...props}>
      <StyledIconEdit /> {props.children}
    </Button>
  )
}
