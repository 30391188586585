import { services } from './invoiceServices'
import { Dispatch, Sort } from '../types'
import { notifySnackbar } from '../index'
import { loadingActions } from '../loading/loadingActions'

const { updateGetInvoiceLoading, updateGetInvoicesLoading } = loadingActions

export const types = {
  GET_INVOICE: 'GET_INVOICE',
  GET_INVOICES: 'GET_INVOICES',
}

interface GetInvoiceFilter {
  EmployerID?: number[]
  StatusID?: number[]
}

interface GetInvoicesProps {
  filter: GetInvoiceFilter
  sort: Sort
  page: number
  pageSize: number
}

export const invoiceActions = {
  async getInvoice(invoiceID: number, dispatch: Dispatch) {
    updateGetInvoiceLoading(dispatch, true)
    const response = await services.getInvoice(invoiceID)
    updateGetInvoiceLoading(dispatch, false)

    const { Data } = response
    const error = Data.Error || null
    const invoice = error ? [] : Data

    dispatch({
      type: types.GET_INVOICES,
      payload: {
        invoice,
        error,
      },
    })

    if (error) {
      notifySnackbar(response, dispatch)
    }
  },
  async getInvoiceByUUID(invoiceUUID: string, dispatch: Dispatch) {
    updateGetInvoiceLoading(dispatch, true)
    const response = await services.getInvoiceByUUID(invoiceUUID)
    updateGetInvoiceLoading(dispatch, false)

    const { Data } = response
    const error = Data.Error || null
    const invoice = error ? [] : Data

    dispatch({
      type: types.GET_INVOICES,
      payload: {
        invoice,
        error,
      },
    })

    if (error) {
      notifySnackbar(response, dispatch)
    }
  },
  async getInvoices(
    { filter, sort, page, pageSize }: GetInvoicesProps,
    dispatch: Dispatch
  ) {
    updateGetInvoicesLoading(dispatch, true)
    const response = await services.getInvoices({
      filter,
      sort,
      page,
      pageSize,
    })
    updateGetInvoicesLoading(dispatch, false)

    const { Data } = response
    const error = Data?.Error || null
    const invoices = error ? [] : Data

    const { Meta } = response
    const count = Meta?.Total

    dispatch({
      type: types.GET_INVOICES,
      payload: {
        count,
        invoices,
        error,
      },
    })

    if (error) {
      notifySnackbar(response, dispatch)
    }
  },
}
