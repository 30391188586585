import React, { useState, useEffect, useContext } from 'react'
import { LinearProgress } from '@material-ui/core'
import { services as EmployerService } from './redux/employer/employerServices'
import { services as UserService } from './redux/user/userServices'
import Logo from './assets/tzc-logo.png'
import { getEmployerIdFromRoute } from './utils/url'

interface AppPermissionGetter {
  (): AppPermissionState | any
}

interface AppPermissionState {
  employers: Employer[]
  selectedEmployerID: number | null
  selectedEmployer: Employer
  updateSelectedEmployerID: (employerID: number) => void
  user: User
  userPermissions: any
  reinitialize: (userId: number, employerId: number) => void
}

export interface Employer {
  ID: number
  Name: string
}

export interface User {
  ID: number
  FirstName: string
  LastName: string
  Email: string //maybe
}

type AppPermissionValues = Omit<
  AppPermissionState,
  'reinitialize' | 'updateSelectedEmployerID'
>
type FetchPermissions = (
  userId: number,
  employerId: number
) => Promise<AppPermissionState['userPermissions']>

export const AppPermissionContext = React.createContext({
  selectedEmployer: {},
  selectedEmployerID: 0,
  employers: [],
  updateSelectedEmployerID: (_employerID: number) => {},
  user: {},
  userPermissions: {},
  reinitialize: () => {},
} as unknown as AppPermissionState)

export const useAppPerms: AppPermissionGetter = () =>
  useContext(AppPermissionContext)

const RequestEmployerAccess: React.FC = () => (
  <div style={{ textAlign: 'center' }}>
    <img
      alt="Zero Logo"
      style={{ width: 155, height: 44, marginTop: '60px' }}
      src={Logo}
    />
    <p style={{ fontSize: '20px', marginTop: '20px' }}>
      Please contact your Account Manager at ZERO to request access to teamZERO.
    </p>
  </div>
)

export const AppPermissionProvider = ({ children }: any) => {
  const [appPermissions, setAppPermissions] = useState<AppPermissionValues>()

  const fetchUserPermissions: FetchPermissions = async (userId, employerId) => {
    if (!userId || !employerId) return null
    const res = await UserService.getPermissionsForUser(userId, employerId)
    return res?.Data
  }

  const getInitialEmployer = (employers: Employer[]): Employer => {
    const defaultEmployer = { ID: 0, Name: '' }
    const employerIdRouteParam = getEmployerIdFromRoute()

    if (!employers.length) return defaultEmployer

    if (employerIdRouteParam) {
      const employer = employers.find(
        (emp) => emp.ID === Number(employerIdRouteParam)
      )
      // if no access to url requested employer, return the default, which results in "no access" page
      return employer || defaultEmployer
    }

    return employers[0]
  }

  const initializePermissions = async () => {
    const userRes = await UserService.getUserWithToken()
    const employersRes = await EmployerService.getEmployers()
    const user: User = userRes?.Data?.User
    const employers: Employer[] = employersRes?.Data
    const selectedEmployer = getInitialEmployer(employers)
    const userPermissions =
      user?.ID && selectedEmployer.ID !== 0
        ? await fetchUserPermissions(user.ID, selectedEmployer.ID)
        : null

    setAppPermissions({
      selectedEmployer,
      selectedEmployerID: selectedEmployer ? selectedEmployer.ID : null,
      employers,
      user,
      userPermissions,
    })
  }

  const updateSelectedEmployerID = async (employerID: number) => {
    const newEmployer = appPermissions?.employers?.find(
      (emp) => emp.ID === employerID
    )
    if (newEmployer && appPermissions?.user) {
      const userPermissions = await fetchUserPermissions(
        appPermissions.user.ID,
        employerID
      )
      setAppPermissions({
        ...(appPermissions as AppPermissionValues),
        userPermissions,
        selectedEmployer: newEmployer,
        selectedEmployerID: employerID,
      })
    }
  }

  const reinitialize: AppPermissionState['reinitialize'] = async () => {
    if (appPermissions) {
      const userPermissions = await fetchUserPermissions(
        appPermissions.user.ID,
        appPermissions.selectedEmployerID
      )
      setAppPermissions({
        ...(appPermissions as AppPermissionValues),
        userPermissions,
      })
    }
  }

  useEffect(() => {
    initializePermissions()
  }, []) // eslint-disable-line

  if (!appPermissions) return <LinearProgress />

  return (
    <AppPermissionContext.Provider
      value={{
        ...appPermissions,
        reinitialize,
        updateSelectedEmployerID,
      }}>
      {!!appPermissions?.selectedEmployerID ? (
        children
      ) : (
        <RequestEmployerAccess />
      )}
    </AppPermissionContext.Provider>
  )
}
