import React from 'react'
import { Typography, Button } from '@material-ui/core'
import styled from 'styled-components'
import Logo from '../../assets/tzc-logo.png'

const Content = styled.div`
  height: 100%;
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Error = styled.div`
  width: 50%;
`

const LogoImg = styled.img`
  height: 75px;
  margin-bottom: 15px;
`

interface AuthErrorProps {
  error: string
}

export const PASSWORD_EXPIRED = 'password_expired' // used in auth0 rule for verifying password age
export const UNKNOWN_ERROR = 'unknown_error'

const PasswordExpired = () => {
  return (
    <div>
      <Typography variant="h5">
        Your password has expired and needs to be reset.
      </Typography>
      <Typography>We have sent you an email with instructions. </Typography>
      <Typography>
        If you do not receive an email, you can{' '}
        <a href="mailto:support@zero.health">Contact Us</a> for help
      </Typography>
    </div>
  )
}

const AuthError = () => {
  return (
    <div>
      There was an unknown authentication error, please try again shortly
    </div>
  )
}

export default (props: AuthErrorProps) => {
  let errorComponent = <div />
  switch (props.error) {
    case PASSWORD_EXPIRED:
      errorComponent = <PasswordExpired />
      break
    case UNKNOWN_ERROR:
      errorComponent = <AuthError />
      break
  }
  return (
    <Content>
      <Error>
        <LogoImg alt="Zero" src={Logo} />
        {errorComponent}
      </Error>
    </Content>
  )
}
