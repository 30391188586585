import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAppPerms } from './AppPermissions'
import { usePrevious } from './hooks'
import * as url from './utils/url'

const RedirectSelectedEmployer: React.FC = () => {
  const { selectedEmployerID } = useAppPerms()
  const prevSelectedEmployerId = usePrevious(selectedEmployerID)

  const userChangedEmployer =
    !!prevSelectedEmployerId && prevSelectedEmployerId !== selectedEmployerID

  const getEmployerIdRedirect = () => {
    const loc = window.location
    return url.hasEmployerRouteParam()
      ? `/employer/${selectedEmployerID}/${loc.pathname
          .split('/')
          .slice(3)
          .join('/')}`
      : loc.pathname.slice(1)
  }

  if (userChangedEmployer) {
    return <Redirect to={getEmployerIdRedirect()} />
  }

  return null
}

export default RedirectSelectedEmployer
