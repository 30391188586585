import React from 'react'
import { NativeSelect } from '@material-ui/core'
import styled from 'styled-components'

export const StyledSelectField = styled(NativeSelect)`
  font-family: 'Raleway';
`

export const CustomSelectField = (props: any) => {
  const { InputProps, ...other } = props
  // Styles to pass down to the input label on the text field
  const inputProps = {
    style: {
      fontFamily: 'Raleway',
    },
    ...InputProps,
  }

  return <StyledSelectField inputProps={inputProps} {...other} />
}
