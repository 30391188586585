import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'
import { eligibilityActions } from '../../../redux/eligibility/eligibilityActions'

const { getEligibilitySubsetHandles } = eligibilityActions

interface EligSubsetHandleSelectorProps {
  employerID: number
  onChange: (val: string) => void
  error?: string
  subset?: string
  filter?: any
  allowEmpty?: boolean
}

interface SubsetHandle {
  Handle: string
  EmployerDescription: string
  InternalDescription: string
}
const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`

export const EligSubsetHandlesSelector = ({
  employerID,
  onChange,
  error,
  subset = '',
  filter,
  allowEmpty = false,
}: EligSubsetHandleSelectorProps) => {
  const [subsetHandleItems, setSubsetHandleItems] = useState([])
  const [selectedSubset, setSelectedSubset] = useState<string>(subset)

  useEffect(() => {
    const fetchSubsetHandles = async () => {
      let subsetHandles = await getEligibilitySubsetHandles(employerID, filter)
      if (allowEmpty) {
        subsetHandles = [
          {
            Handle: '',
            EmployerDescription: 'All',
            InternalDescription: 'All',
          },
          ...subsetHandles,
        ]
      }
      const newSubsetHandles = subsetHandles.map((handle: SubsetHandle) => {
        return (
          <MenuItem value={handle.Handle} key={handle.Handle}>
            {handle.EmployerDescription}
          </MenuItem>
        )
      })
      setSubsetHandleItems(newSubsetHandles)

      if (subsetHandles && subsetHandles.length > 0) {
        // auto select if there is only 1 and member does not already have a subset selected
        if (subsetHandles.length === 1 && !selectedSubset) {
          setSelectedSubset(subsetHandles[0].Handle)
          onChange(subsetHandles[0].Handle)
        }
      }
    }

    fetchSubsetHandles()
  }, [employerID])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as string
    onChange(selected)
    setSelectedSubset(selected)
  }

  return (
    <FullWidthFormControl>
      <InputLabel htmlFor={'subset-selector'}>Employer Group</InputLabel>
      <Select
        label="Employer Group"
        name="subset-selector"
        error={!!error}
        onChange={handleChange}
        value={selectedSubset}>
        {subsetHandleItems}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FullWidthFormControl>
  )
}
