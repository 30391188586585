import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import S3Upload from 'react-s3-uploader/s3upload'
import { Typography } from '@material-ui/core'
import { CloudUpload as IconUpload } from '@material-ui/icons'
import styled from 'styled-components'
import { FileList } from './FileList'
import { services } from '../../redux/files/fileServices'

export interface FileUploaderProps {
  disabled?: boolean
  maxFiles?: number
  onDrop?: (files: File[]) => void
  onComplete: (filename: string) => void
  onError: (status: number) => void
  employerID: number
}

export const FileUploader = ({
  disabled,
  maxFiles = 1,
  onComplete,
  onError,
  employerID,
}: FileUploaderProps) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [errors, setErrors] = useState({})
  // note: we cant do this using state since the whole file upload is a captive user interaction,
  // and the react state hooks will not fire until after the final event resolution.
  let signedS3Key = ''

  const onUploadProgress = useCallback((pctUploaded: number) => {
    console.debug('uploadProgress handler: ', pctUploaded)
    setUploadProgress(pctUploaded)
  }, [])

  const onUploadComplete = (res: any, file: File) => {
    console.debug('uploadComplete handler: ', res, file)
    onComplete(signedS3Key)
  }

  const onUploadError = (status: number, file: any) => {
    setErrors({
      [file.name]: status,
    })
    onError(status)
  }

  const onFileSelected = useCallback(
    (file: File, next: (file: File) => void) => {
      console.debug('onFileSelected :', file)
      setTimeout(() => next(file), 200)
    },
    []
  )

  const s3UploaderOpts = {
    onProgress: onUploadProgress,
    onFinishS3Put: onUploadComplete,
    onError: onUploadError,
    preprocess: onFileSelected,
    uploadRequestHeaders: {},
    getSignedUrl: services.getPresignedUrlForUpload(
      employerID,
      (s3Key: string) => {
        console.debug('signedUrl:', s3Key)
        signedS3Key = s3Key
      }
    ),
  }

  const onDrop = (acceptedFiles: File[]) => {
    console.debug('onDrop handler: ', acceptedFiles)
    new S3Upload({
      files: acceptedFiles,
      ...s3UploaderOpts,
    })
  }

  const empty = [] as string[]
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    accept: {
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/plain': [],
      'text/csv': [],
      'application/rtf': [],
      'application/zip': [],
      'application/x-rar-compressed': [],
      'application/x-tar': [],
      'application/gzip': [],
    },
    onDrop,
  })

  return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <DragDropBox dragging={isDragActive} />
      </div>
      <FileList
        files={acceptedFiles}
        rejectedFiles={fileRejections}
        uploadStatus={uploadProgress}
        errors={errors}
      />
    </section>
  )
}

interface DragDropProps {
  dragging: boolean
}

const DragDropContainer = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DragDropBox = ({ dragging }: DragDropProps) => {
  return (
    <DragDropContainer>
      <IconUpload fontSize="large" />
      <Typography variant="h6">
        {dragging ? 'Drop your file here' : 'Drop file here or click to select'}
      </Typography>
    </DragDropContainer>
  )
}
