import React from 'react'
import moment from 'moment'
import { Grid, Checkbox } from '@material-ui/core'
import CheckboxWithDescription from '../../CheckboxWithDescription'

interface SelectDependentsProps {
  includedDependents: number[]
  setIncludedDependents: (val: number[]) => void
  dependents: any[]
  deactivationDate: string
  hasDeactivationDate: boolean
}

const DATE_NOT_SET = '01/01/0001'
const DATE_WAS_RESET = '0001-01-01'
const DEFAULT_COLOR = 'black'

const formatDisplayDate = (date?: string) => {
  if (date === DATE_NOT_SET || date === DATE_WAS_RESET || date === null) {
    return 'Not Set'
  }
  return date ? moment.utc(date).format('MM/DD/YYYY') : ''
}

const SelectDependentsStep: React.FC<SelectDependentsProps> = (props) => {
  const [showDependentCheckboxes, setShowDependentCheckboxes] =
    React.useState(false)
  const {
    includedDependents,
    setIncludedDependents,
    dependents,
    hasDeactivationDate,
    deactivationDate,
  } = props

  const allValid = dependents.map((d) =>
    moment.utc(d.ActivationDate).isBefore(deactivationDate)
  )

  // always enable if user is removing deactivationDate
  const disabled = hasDeactivationDate ? allValid.includes(false) : false

  const onApplyAllCheckboxChange = (evt: any) => {
    const checked = evt.target.checked
    setShowDependentCheckboxes(checked)
    if (checked) {
      setIncludedDependents(dependents.map((dep) => dep.EmployerMemberID))
    } else {
      setIncludedDependents([])
    }
  }

  const onDepCheckboxChange = (dependentId: number) => () => {
    if (includedDependents.includes(dependentId)) {
      const newIncludedDeps = includedDependents.filter(
        (id) => id !== dependentId
      )
      setIncludedDependents(newIncludedDeps)
    } else {
      setIncludedDependents([...includedDependents, dependentId])
    }
  }

  return (
    <div style={{ width: '500px' }}>
      <CheckboxWithDescription
        value={showDependentCheckboxes}
        onChange={onApplyAllCheckboxChange}
        label="Apply to all dependents"
        description="Deactivation date will propagate to all dependents of this subscriber."
        disabled={disabled}
      />
      {!!dependents.length && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <p style={{ fontWeight: 'bold' }}>Name</p>
            {dependents.map(({ ID, EmployerMemberID, FirstName, LastName }) => (
              <div
                style={{
                  display: 'flex',
                  height: 34,
                  alignItems: 'flex-start',
                }}>
                <Checkbox
                  checked={includedDependents.includes(EmployerMemberID)}
                  onChange={onDepCheckboxChange(EmployerMemberID)}
                  disabled={showDependentCheckboxes}
                  style={{ marginLeft: '-10px', marginTop: '-12px' }}
                  disableRipple
                />
                <p key={ID} style={{ margin: 0 }}>
                  {FirstName} {LastName}
                </p>
              </div>
            ))}
          </Grid>

          <Grid item xs={3}>
            <p style={{ fontWeight: 'bold' }}>Activation</p>
            {dependents.map(({ ID, ActivationDate }) => (
              <p
                key={ID}
                style={{
                  color: moment.utc(ActivationDate).isAfter(deactivationDate)
                    ? 'firebrick'
                    : DEFAULT_COLOR,
                }}>
                {formatDisplayDate(ActivationDate)}
              </p>
            ))}
          </Grid>

          <Grid item xs={3}>
            <p style={{ fontWeight: 'bold' }}>Deactivation</p>
            {dependents.map(
              ({
                ID,
                EmployerMemberID,
                DeactivationDate: DepDeactivationDate,
              }) => (
                <p
                  key={ID}
                  style={{
                    color: includedDependents.includes(EmployerMemberID)
                      ? 'green'
                      : DEFAULT_COLOR,
                  }}>
                  {includedDependents.includes(EmployerMemberID)
                    ? formatDisplayDate(deactivationDate)
                    : formatDisplayDate(DepDeactivationDate)}
                </p>
              )
            )}
          </Grid>
        </Grid>
      )}
      {disabled && (
        <p style={{ color: 'firebrick' }}>
          Cannot apply to all dependents because the new deactivation date comes
          before the dependent's current activation date.
        </p>
      )}
    </div>
  )
}

export default SelectDependentsStep
