import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { RootComponent } from './styledComponents'
import { PageTitle } from '../styledComponents'
import { InvoiceTable } from './InvoiceTable'
import { useAppPerms } from '../../AppPermissions'
import queryString from 'query-string'
import { TITLE_TEMPLATE } from '../../utils'

interface InvoiceProps {
  history: any
}

export const Invoices = (props: InvoiceProps) => {
  const [initParams, setInitParams] = useState(null)
  const { selectedEmployerID } = useAppPerms()

  useEffect(() => {
    const params: any = queryString.parse(window.location.search)

    if (params.sortable) {
      params.sortable = queryString.parse(params.sortable)
    }
    if (params.filter) {
      params.filter = queryString.parse(params.filter)
    }
    setInitParams(params)
  }, [])

  const persistSearch = (params: any) => {
    const curParams = queryString.parse(window.location.search)
    const stringified = queryString.stringify({
      ...curParams,
      ...params,
      sortable: queryString.stringify(params.sortable),
      filter: queryString.stringify(params.filter),
    })
    props.history.push(`?${stringified}`)
  }

  const persistViewInvoice = (params: any) => {
    const curParams = queryString.parse(window.location.search)
    const stringified = queryString.stringify({
      ...curParams,
      ...params,
      viewing: params.viewingID !== 0 || params.viewingUUID !== '',
    })
    props.history.push(`?${stringified}`)
  }

  return initParams ? (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Invoices</title>
      </Helmet>
      <Grid container>
        <Grid item xs={6}>
          <PageTitle>View Invoices</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <InvoiceTable
            employerID={selectedEmployerID}
            initParams={initParams}
            persistSearch={persistSearch}
            persistViewInvoice={persistViewInvoice}
          />
        </Grid>
      </Grid>
    </RootComponent>
  ) : null
}
