import * as api from '../../services/thezerocard/api-helper'
import {
  APIErrorResponse,
  APIResponse,
} from '../../services/thezerocard/api-helper'
/**
 * Services for the user state to access async data
 * Only to be used in the useUserState hook
 */

export interface NewUser {
  FirstName: string
  LastName: string
  Email: string
}

export const services = {
  async getUserWithToken() {
    const response = await api.get('/user')
    return response
  },
  async getPermissionsAndUsers(employerID: number) {
    if (!employerID) {
      throw new Error('403')
    }
    const response = await api.get(`/teamzero/employer/${employerID}/users`)
    return response
  },
  async putUsersPermissionHandle(
    permissionsObject: any,
    userID: string,
    employerID: string
  ) {
    const response = await api.put(
      `/teamzero/employer/${employerID}/user/${userID}`,
      { ...permissionsObject }
    )
    return response
  },
  async getPermissionsForUser(userID: number, employerID: number) {
    if (!userID || !employerID) {
      throw new Error('403')
    }
    const response = await api.get(
      `/teamzero/employer/${employerID}/user/${userID}`
    )
    return response
  },
  async createUser(employerID: number, newUser: NewUser) {
    return api.post(`/teamzero/employer/${employerID}/user`, newUser)
  },
  async deleteUser(employerID: number, userID: number) {
    return api.del(
      `/teamzero/employer/${employerID}/user/${userID}/permissions`
    )
  },
}
