import React from 'react'
import ReactToPrint from 'react-to-print'
import { Button } from '@material-ui/core'
import MemberIdCard from './MemberIdCard'

interface Props {
  FirstName: string
  LastName: string
  MiddleName: string
  IntegrationId: string
  IsCurrentlyActive: boolean
  NotYetActive: boolean
  Dob: string
  QuestID: string
  HasQuest: boolean
}

// NOTE: MemberIdCard supports both rendering and printing

const PrintMemberIdCard: React.FC<Props> = (props) => {
  const componentRef = React.useRef(null)

  return (
    <div style={{ textAlign: 'left' }}>
      <MemberIdCard {...props} />
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined" style={{ marginLeft: '10px' }}>
            Print your ID Card
          </Button>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: 'none' }}>
        <MemberIdCard {...props} componentRef={componentRef} print />
      </div>
    </div>
  )
}

export default PrintMemberIdCard
