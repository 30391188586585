import * as api from '../../services/thezerocard/api-helper'
import {
  APIResponse,
  APIErrorResponse,
} from '../../services/thezerocard/api-helper'
import {
  EligibilityParams,
  NewMemberParams,
  EditMemberParams,
} from './eligibilityActions'
import queryString from 'query-string'

interface PostEligibilityFileParams {
  EmployerID: number
  SubsetHandle: string
  Notes: string
  Md5: string
  Size: number
}

interface PostAccumulatorFileParams {
  EmployerID: number
  Notes: string
  Md5: string
  Size: number
}

interface EligibilityResult {
  ActivationDate: string
  AddressID: number | null
  BenefitEndRo: string | null
  BenefitStartRo: string | null
  CreatedAt: string | null
  DOB: string | null
  DeactivationDate: string | null
  DisplayAddress: string | null
  DivisionName: string | null
  Email: string | null
  EmployerID: number
  EmployerMemberID: number
  EmployerName: string | null
  FirstName: string | null
  FirstNameAka: string | null
  Gender: string | null
  Honorific: string | null
  InsuranceGroupID: string | null
  InsuranceMemberID: string | null
  IntegrationID: string | null
  IsCurrentlyActive: boolean
  IsSubscriber: boolean
  LastName: string | null
  LastNameAka: string | null
  Latitude: number | null
  Longitude: number | null
  MemberID: number | null
  MiddleName: string | null
  ModifiedBy: any
  Phone: string | null
  Problematic: boolean | null
  Retired: boolean | null
  SSNLastFour: string | null
  Suffix: string | null
  UpdatedAt: string | null
}

interface EligibilitySearchAPIResponse extends APIResponse {
  Data: EligibilityResult[]
}

/**
 * Services for the user state to access async data
 * Only to be used in the useUserState hook
 */
export const services = {
  async eligibilitySearch(
    params: EligibilityParams
  ): Promise<EligibilitySearchAPIResponse | APIErrorResponse> {
    const response = api.search('/teamzero/eligibility', params)
    return response
  },
  async getValidationFile(employerID: number) {
    const qs = queryString.stringify({ employerID })
    return api.get(`/teamzero/eligibility/validation_file?${qs}`)
  },
  async addEligibilityMember(params: NewMemberParams) {
    const response = api.post('/teamzero/eligibility', params)
    return response
  },
  async getMemberDetail(ID: number, EmployerID: number) {
    const response = await api.get(
      `/teamzero/eligibility/employer/${EmployerID}/member_detail/${ID}`
    )
    return response
  },
  async getAddressForMemberID(ID: number) {
    const response = await api.get(
      `/teamzero/eligibility/member_detail/${ID}/address`
    )
    return response
  },
  async saveEligibilityMemberEdit(
    params: EditMemberParams,
    EmployerID: number
  ) {
    const response = api.put(
      `/teamzero/eligibility/employer/${EmployerID}`,
      params
    )
    return response
  },
  async getEligibilitySubsetHandles(employerID: number, payload: any) {
    const response = api.get(
      `/teamzero/eligibility_subset_handles/${employerID}`,
      payload
    )
    return response
  },
  async getDivisions(employerID: number) {
    const response = api.get(`/teamzero/divisions/${employerID}`)
    return response
  },
  async postEligibilityFile(params: PostEligibilityFileParams) {
    const response = api.post(`/teamzero/eligibility_file`, params)
    return response
  },
  async postAccumulatorFile(params: PostAccumulatorFileParams) {
    const response = api.post(`/teamzero/accumulator_file`, params)
    return response
  },
}
