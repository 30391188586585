import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Grid } from '@material-ui/core'

export const RootComponent = styled.div`
  text-align: center;
  padding-top: 60px;
`
export const DataTableContainer = styled.div`
  padding-top: 25px;
`
export const OtherTasksContainer = styled.div`
  padding-left: 5px;
  text-align: left;
  width: 100%;
`
export const OtherTasksButton = styled(Button)`
  margin-top: 6px;
  max-width: 225px;
  max-height: 36px;
`
export const OtherTasksButtonContainer = styled(Grid)`
  display: flex;
  algin-items: flex-start;
  justify-content: flex-start;
`
