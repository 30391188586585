import React from 'react'
import { Grid } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import {
  MainComponent,
  Root,
  AppFrameStyled,
  RootComponent,
} from './styledComponents'
import { MenuContainer } from '../Menu/MenuContainer'

interface Error {
  message?: string
  stack?: string
}

interface Props {
  error?: Error
  resetErrorBoundary?: any
}

/**
 * Fall back component for the main error boundary.
 * This can be used for routing errors to reporting services in the future.
 */
export const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: Props) => {
  let message =
    'Something went wrong. Please contact support if the issue persists'

  // Display a custom message based on the error that a permissions error is caught
  if (error && error.message === '403') {
    message =
      'You do not have access to this page. Please talk to your administrator to get the correct permissions.'
  }

  return (
    <Root>
      <AppFrameStyled>
        <div onClick={() => resetErrorBoundary()}>
          <MenuContainer />
        </div>
        <MainComponent>
          <RootComponent>
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <h3>{message}</h3>
                <ErrorOutlineIcon style={{ fontSize: 72 }} />
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </RootComponent>
        </MainComponent>
      </AppFrameStyled>
    </Root>
  )
}
