import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@material-ui/core'

import { useForm } from '../../../hooks'
import SetDeactivationDateStep from './SetDeactivationDateStep'
import SelectDependentsStep from './SelectDependentsStep'
import ManageFileUploadsStep from './ManageFileUploadsStep'

const ZERO_EPOCH = moment.utc('2015-01-01')

const isValidDate = (date?: string) => {
  if (date === '01/01/0001') return false
  return date ? moment(date).isValid() : false
}

const formatDisplayDate = (date?: string) => {
  return date ? moment.utc(date).format('MM/DD/YYYY') : ''
}

const formatRequestDate = (date?: string) => {
  return date ? moment.utc(date).format('YYYY-MM-DD') : null
}

const isBefore2015 = (date?: string) => {
  if (date) {
    const parsed = moment.utc(date)
    return parsed.isBefore(ZERO_EPOCH)
  } else {
    return false
  }
}

const isThreeMonthsOut = (date?: string) => {
  const threeMonthsFromNow = moment().add(3, 'months')
  return date ? moment.utc(date).isAfter(threeMonthsFromNow) : false
}

const isValidDeactivationDate = (date?: string) => {
  return isValidDate(date) && !isThreeMonthsOut(date) && !isBefore2015(date)
}

const Error = styled.p`
  color: firebrick;
  font-size: 13px;
  padding: 10px 30px;
  max-width: 437px;
`

interface DeactivationDateStepperProps {
  form: any
  setValid: (valid: boolean) => void
  dependents: any[]
  initialDate: string
  showSkipUpdateStep: boolean
}

interface Step {
  id: string
  initialLabel: string
  completedLabel: string
  component: React.ReactNode
  isValid: () => boolean
}

const DeactivationDateStepper: React.FC<DeactivationDateStepperProps> = (
  props
) => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [dirtySteps, setDirtySteps] = React.useState<number[]>([])
  const { form, setValid, initialDate } = props

  const {
    deactivationDate,
    hasDeactivationDate,
    includedDependents,
    overrideFiles,
    skipUpdateUntil,
  } = form?.data

  const steps = [
    {
      initialLabel: 'Set Deactivation Date (required)',
      completedLabel: `Deactivation Date: ${formatDisplayDate(
        deactivationDate
      )}`,
      component: (
        <SetDeactivationDateStep
          deactivationDate={deactivationDate}
          setDeactivationDate={form.setters.deactivationDate}
          hasDeactivationDate={hasDeactivationDate}
          setHasDeactivationDate={form.setters.hasDeactivationDate}
          initialDate={initialDate}
        />
      ),
      isValid: () => {
        return (
          isValidDeactivationDate(deactivationDate) ||
          hasDeactivationDate === false
        )
      },
    },
    props.showSkipUpdateStep && {
      initialLabel: 'Manage File Uploads (optional)',
      completedLabel: `Override file uploads until: ${formatDisplayDate(
        skipUpdateUntil
      )}`,
      component: (
        <ManageFileUploadsStep
          overrideFiles={overrideFiles}
          setOverrideFiles={form.setters.overrideFiles}
          skipUpdateUntil={skipUpdateUntil}
          setSkipUntilDate={form.setters.skipUpdateUntil}
        />
      ),
      isValid: () =>
        overrideFiles === true ? moment().isBefore(skipUpdateUntil) : true,
    },
    props.dependents?.length && {
      initialLabel: 'Select Dependents (optional)',
      completedLabel: `Apply to dependents: ${
        includedDependents.length ? 'Yes' : 'No'
      }`,
      component: (
        <SelectDependentsStep
          includedDependents={includedDependents}
          setIncludedDependents={form.setters.includedDependents}
          dependents={props.dependents}
          deactivationDate={deactivationDate}
          hasDeactivationDate={hasDeactivationDate}
        />
      ),
      isValid: () => true,
    },
  ].filter(Boolean) as unknown as Step[]

  const back = () => {
    setDirtySteps([...dirtySteps, activeStep])
    setActiveStep(activeStep - 1)
  }

  const next = () => {
    setDirtySteps([...dirtySteps, activeStep])
    setActiveStep(activeStep + 1)
  }

  const isLastStep = activeStep === steps.length - 1

  const showCompletedLabel = (stepIndex: number, isValid: Step['isValid']) => {
    if (stepIndex === activeStep) return false
    return dirtySteps.includes(stepIndex) && isValid()
  }

  const allValid =
    steps.map((s) => s.isValid()).filter((v) => v === false).length === 0

  React.useEffect(() => {
    setValid(allValid)
  }, [setValid, allValid])

  return (
    <Stepper orientation="vertical" activeStep={activeStep}>
      {steps.map((s, i) => (
        <Step key={s.initialLabel}>
          <StepLabel>
            {showCompletedLabel(i, s.isValid)
              ? s.completedLabel
              : s.initialLabel}
          </StepLabel>
          <StepContent>
            <div style={{ width: '300px', paddingBottom: '30px' }}>
              {s.component}
            </div>
            <Button disabled={activeStep === 0} onClick={back}>
              Back
            </Button>
            {!isLastStep && (
              <Button
                variant="contained"
                color="primary"
                disabled={!s.isValid()}
                onClick={next}>
                Next
              </Button>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}

interface Props {
  handleSave: (obj: any) => void
  handleClose: () => void
  deactivationDate?: string
  skipUpdateUntil?: string
  open: boolean
  dependents: any[]
  showSkipUpdateStep: boolean
}

const DialogSetDeactivationDate: React.FC<Props> = (props) => {
  const initialDeactivationDate = formatDisplayDate(props.deactivationDate)
  const initialSkipUpdateUntil = formatDisplayDate(props.skipUpdateUntil)

  const [valid, setValid] = React.useState<boolean>()

  const form = useForm({
    deactivationDate: isValidDate(initialDeactivationDate)
      ? initialDeactivationDate
      : '',
    hasDeactivationDate: true,
    includedDependents: [],
    overrideFiles: isValidDate(props.skipUpdateUntil),
    skipUpdateUntil: isValidDate(initialSkipUpdateUntil)
      ? initialSkipUpdateUntil
      : '',
  })

  const hasDeactivationDateTooEarly =
    form.data.hasDeactivationDate &&
    isValidDate(form.data.deactivationDate) &&
    isBefore2015(form.data.deactivationDate)

  const hasDeactivationDateTooLate =
    form.data.hasDeactivationDate &&
    isThreeMonthsOut(form.data.deactivationDate)

  const save = () => {
    const {
      deactivationDate,
      hasDeactivationDate,
      includedDependents,
      skipUpdateUntil,
    } = form.data

    props.handleSave({
      DeactivationDate: hasDeactivationDate
        ? formatRequestDate(deactivationDate)
        : null,
      NoDeactivationDate: !hasDeactivationDate,
      IncludedDependentEmployerMemberIDs: includedDependents,
      SkipUpdateUntil: formatRequestDate(skipUpdateUntil),
    })

    // reset dependents
    form.setData({ ...form.data, includedDependents: [] })
  }

  const close = () => {
    props.handleClose()
    form.reset()
  }

  if (!props.open) return null

  return (
    <div>
      <Dialog
        onClose={close}
        open={props.open}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Change deactivation date
        </DialogTitle>
        <DialogContent>
          <DeactivationDateStepper
            form={form}
            setValid={setValid}
            dependents={props.dependents}
            initialDate={initialDeactivationDate}
            showSkipUpdateStep={props.showSkipUpdateStep}
          />
        </DialogContent>
        <div>
          {hasDeactivationDateTooEarly && (
            <Error>Deactivation date cannot be before the year 2015.</Error>
          )}
          {hasDeactivationDateTooLate && (
            <Error>
              Deactivation date cannot be more than 3 months in the future.
            </Error>
          )}
          {moment().isAfter(form.data.skipUpdateUntil) && (
            <Error>
              When overriding file uploads, 'Skip Until Date' must be in the
              future.
            </Error>
          )}
        </div>
        <DialogActions>
          <Button onClick={save} disabled={!valid} color="primary">
            Save
          </Button>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogSetDeactivationDate
