import React from 'react'
import DateField from '../../DateField'
import CheckboxWithDescription from '../../CheckboxWithDescription'

interface ManageFileUploadsProps {
  overrideFiles: boolean
  setOverrideFiles: (val: boolean) => void
  skipUpdateUntil: string
  setSkipUntilDate: (val: string | null) => void
}

const ManageFileUploadsStep: React.FC<ManageFileUploadsProps> = (props) => {
  const { overrideFiles, setOverrideFiles, skipUpdateUntil, setSkipUntilDate } =
    props

  React.useEffect(() => {
    if (overrideFiles === false && !!skipUpdateUntil) {
      setSkipUntilDate(null)
    }
  }, [overrideFiles, setSkipUntilDate]) // eslint-disable-line

  return (
    <div style={{ width: '450px' }}>
      <CheckboxWithDescription
        value={overrideFiles}
        onChange={(e: any) => setOverrideFiles(e.target.checked)}
        label="Override future file uploads"
        description="The deactivation date set above will take precedence over deactivation dates that come in on future eligibility files, until the date specified below."
      />
      {overrideFiles && (
        <DateField
          value={skipUpdateUntil}
          onChange={setSkipUntilDate}
          label="Skip Until Date"
        />
      )}
    </div>
  )
}

export default ManageFileUploadsStep
