import { services } from './notificationServices'
import { Dispatch } from '../types'
import { loadingActions } from '../loading/loadingActions'
import { snackbarActions } from '../snackbar/snackbarActions'

export const types = {
  GET_USER_NOTIFICATION_SETTINGS: 'GET_USER_NOTIFICATION_SETTINGS',
  PUT_USER_NOTIFICATION_TYPE: 'PUT_USER_NOTIFICATION_TYPE',
  PUT_EMPLOYER_NOTIFICATION_SETTING: 'PUT_EMPLOYER_NOTIFICATION_SETTING',
  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS', // set all notification settings in reducer
}

const {
  updatePutUserNotificationTypeLoading,
  updatePutEmployerNotificationSettingLoading,
} = loadingActions

export const notificationActions = {
  async getUserNotificationSettings(dispatch: Dispatch, userId: number) {
    const response = await services.getUserNotificationSettings(userId)

    dispatch({
      type: types.SET_NOTIFICATION_SETTINGS,
      payload: response?.Data,
    })
  },

  async putUserNotificationType(
    dispatch: Dispatch,
    userId: number,
    notifType: string,
    frequency: number
  ) {
    updatePutUserNotificationTypeLoading(dispatch, true)

    const putResponse = await services.putUserNotificationType(
      userId,
      notifType,
      frequency
    )

    updatePutUserNotificationTypeLoading(dispatch, false)

    if (putResponse && !putResponse?.Data?.Error) {
      snackbarActions.setMessage(
        {
          message: 'Preference updated.',
          type: 'success',
        },
        dispatch
      )
    } else {
      snackbarActions.setMessage(
        {
          message: 'Error updating preference.',
          type: 'error',
        },
        dispatch
      )
    }

    const getResponse = await services.getUserNotificationSettings(userId)

    dispatch({
      type: types.SET_NOTIFICATION_SETTINGS,
      payload: getResponse?.Data,
    })
  },

  async putEmployerNotificationSetting(
    dispatch: Dispatch,
    userId: number,
    employerId: number,
    active: boolean
  ) {
    updatePutEmployerNotificationSettingLoading(dispatch, true)

    const putResponse = await services.putEmployerNotificationSetting(
      userId,
      employerId,
      active
    )

    updatePutEmployerNotificationSettingLoading(dispatch, false)

    if (putResponse && !putResponse?.Data?.Error) {
      snackbarActions.setMessage(
        {
          message: 'Employer preference updated.',
          type: 'success',
        },
        dispatch
      )
    } else {
      snackbarActions.setMessage(
        {
          message: 'Error updating preference.',
          type: 'error',
        },
        dispatch
      )
    }

    const getResponse = await services.getUserNotificationSettings(userId)

    dispatch({
      type: types.SET_NOTIFICATION_SETTINGS,
      payload: getResponse?.Data,
    })
  },
}
