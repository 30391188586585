import { RadioGroup } from '@material-ui/core'
import styled from 'styled-components'

export const RootComponent = styled.div`
  padding-top: 15px;
`

export const Subtitle = styled.h4<{ extraMargin?: boolean }>`
  margin-top: 40px;
  margin-bottom: 20px;
`
export const RadioGroupInlineFlex = styled(RadioGroup)`
  display: inline-flex;
`
