import React, { useState, useCallback } from 'react'
import { hasEmployerRouteParam, getEmployerIdFromRoute } from '../utils/url'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

/*
useQueryParamsGen2 is a crack at managing structured data when stored/reflected in URL query params.
This is applicable to GET queries only, where things are sent via URL query params, instead of in payload
bodies (as you'd see for PUT/POSTs).

Picture the following payload:

  {
    id: 123,
    name: null,
    withIds: [1, 2],
    sort: {
      col: "test",
      dir: "ing"
    }
  }

  If this were sent via GET, the encoded URL would look something like this:
  https://www.test.com?id=123&name=null&withIds=%5B1,2%5Dsort=%7B%22col%22:%22test%22,%22dir%22:%22ing%22%7D

Looks aside, thats not going to work well at all when you try to "unparse" it: ie. null would be a string "null",
and all bets are off on the [] or nested {} types. In other words, URL query params kind of suck if used "as is".

--------------------------------------------------------------------------------------------

This solution fixes that ^ problem by standardizing how we use URL params. Instead of 1:1 (queryParamName:<value>),
we use a single, standardized query param (z), and store **base64 encoded, serialized JSON** on it. That sounds
like a lot, but here's all it is (using the same payload as above):

  stepA = JSON.stringify({
    id: 123,
    name: null,
    withIds: [1, 2],
    sort: {
      col: "test",
      dir: "ing"
    }
  })
  // stepA = '{"id":123,"name":null,"withIds":[1,2],"sort":{"col":"test","dir":"ing"}}'

  // then we take that serialized JSON string and base64 encode it...
  stepB = btoa(step1)
  // stepB = 'eyJpZCI6MTIzLCJuYW1lIjpudWxsLCJ3aXRoSWRzIjpbMSwyXSwic29ydCI6eyJjb2wiOiJ0ZXN0IiwiZGlyIjoiaW5nIn19'

  // then we set that back on the URL as the 'z' param:
  history.location.search = `?z={stepB}`

Now, if we reload the page, we simply reverse those steps and get a properly unmarshalled "POJO" object (in pseudocode below):

  zParam = history.location.search('z') // get this value into a variable: 'eyJpZCI6MTIzLCJuYW1lIjpudWxsLCJ3aXRoSWRzIjpbMSwyXSwic29ydCI6eyJjb2wiOiJ0ZXN0IiwiZGlyIjoiaW5nIn19'
  unbase64d = atob(zParam)
  jsonObj = JSON.parse(unbase64d)
  // jsonObj = <the payload above>; where name is ACTUALLY null, and withIds is ACTUALLY an array (of ints), etc etc

--------------------------------------------------------------------------------------------

Example use with a component:

  **Its presumed the 'data' value returned by this hook is **immediately stored into state in the component (eg. useState)**
  
  export default function MyComponent(props : any) {
    const {queryData, setQueryData} = useQueryParamsGen2()
    const [myData, setMyData] = useState({...queryData})

    // now, when 'myData' changes, we'd want that data reflected back into the URL, so wire up an effect...
    useEffect(() => {
      setQueryData(myData)
    }, [myData])
  }

And you're done. Now, if myData changes, its immediately reflected back onto the URL as the 'z' param, and
if you refresh the page, its reflected as 'myData'.

--------------------------------------------------------------------------------------------

Optional override function:

  While base64'ing saves a lot of headache, we still run into issues where we want to allow external applications
  to link back to specific pages, passing their own parameters. For example: if a Sigma dashboard needs to display
  a link to the Savings Review page, passing an Employer ID to use on page filters. NOTE: if your component doesn't
  need to honor external linking strategies, YOU SHOULD NOT USE THIS.

  In this case, other apps aren't going to base64 -> serializedJSON... so an optional 'overRides' function is 
  available for callers of this hook. If provided, the callback will receive **any other URL parameters** sent
  besides the 'z' param. This function would be in charge of determining whether the value passed in is valid,
  and if so, would return it in an object that WILL BE MERGED WITH THE OBJECT REPRESENTED BY THE z PARAM. In
  other words, the callback function would return an object that will have its properties merged with whatever
  was unmarshalled from 'z'.

  Taking a smaller example:
  https://whatever.com?z=eyJhIjoxLCJiIjoyfQ== // here, z represents: {a:1, b:2}

    // implementing component has:
    const {queryData, setQueryData} = useQueryParamsGen2()
    // queryData = {a:1, b:2}
    

  However, if the URL is:
  https://whatever.com?z=eyJhIjoxLCJiIjoyfQ==&b=999 // here, z represents: {a:1, b:2} again, but there's an additional query param

    // implementing component has:
    const {queryData, setQueryData} = useQueryParamsGen2((otherURLParams : any) : any => {
      const merger = {}
      if (otherURLParams.b && _.parseInt(otherURLParams.b) >= 0) {
        merger.b = _.parseInt(otherURLParams.b)
      }
      return merger
    })
    // queryData = {a:1, b:999}

  Its important to recognize that the 'merger' object in the callback above can return any fields it wants and it'll
  be merged into the 'queryData' value... which is not necessarily a good thing. For example, setting

      merger.employerId = 123
      // when you meant to set
      merger.EmployerID = 123
      // could result in: {EmployerID:null, employerId: 123}, and the rest of the app is using EmployerID

  will be a bad time. The override callback is intentionally flexible to allow arbitrary use cases by callers,
  but that makes it less safe too. Buyer beware.

*/
export default function (overRides?: { (v: any): any }) {
  const hist = useHistory()
  const loc = useLocation()
  const [data, setData] = useState(read())

  function read(): any {
    if (!loc.search.length) {
      return {}
    }
    try {
      let payload = {}

      const z = new URLSearchParams(loc.search).get('z') || ''
      if (z) {
        payload = { ...JSON.parse(atob(z)) }
      }

      if (overRides) {
        const otherURLParams = queryString.parse(loc.search)
        delete otherURLParams.z
        const overResult = overRides(otherURLParams)
        payload = { ...payload, ...overResult }
      }

      return payload
    } catch (e) {
      console.warn('useQueryParamsGen2: failed parsing initial query param!')
      return {}
    }
  }

  const setter = useCallback(
    (v: any): void => {
      setData(v)
      hist.push({ search: `z=${btoa(JSON.stringify(v))}` })
    },
    [setData, hist]
  )

  return {
    queryData: data as any,
    setQueryData: setter,
  }
}

export const useEmployerIDFromRoute = () => {
  if (hasEmployerRouteParam()) return getEmployerIdFromRoute()
  return null
}

export type Validators<T> = {
  [key in keyof Partial<T>]: {
    isValid: (val: T[key]) => boolean
    msg: string
  }
}

type AnyObject = { [key: string]: any }

type Setters<T> = {
  [key in keyof T]: (val: NonNullable<T[key]>) => void
}

type Errors<T> = {
  [key in keyof T]: string | null
}

export function useForm<T extends AnyObject>(
  initialData: T,
  validator?: Validators<T>
) {
  const [data, setData] = React.useState<T>(initialData)

  const setField = (field: string) => (val: any) => {
    setData({ ...data, [field]: val })
  }

  const fields: Array<keyof T> = Object.keys(initialData)
  let setters: Setters<T> = {} as T
  let errors: Errors<T> = {} as T

  fields.forEach((field) => {
    const val = initialData[field]
    const valType = typeof val

    //does not support nested objects
    if (
      typeof initialData[field] === 'object' &&
      !Array.isArray(initialData[field])
    ) {
      return
    }
    setters[field] = (val: any) => {
      setData({ ...data, [field]: val })
    }
  })

  fields.forEach((field) => {
    if (!validator || !validator[field]) return
    const val = data[field]
    const validation = validator[field]
    const err = validation.isValid(val) ? null : validation.msg
    errors[field] = err
  })

  const reset = () => setData(initialData)

  const isValid = () => {
    if (!validator) return true
    const validity = Object.keys(validator).map((key) =>
      validator[key].isValid(data[key])
    )
    return validity.includes(false) ? false : true
  }

  return {
    data,
    setData,
    setField,
    setters,
    errors,
    reset,
    isValid,
  }
}

// use a previous state or prop value between renders
// for checking diffs
export function usePrevious(value: any) {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
