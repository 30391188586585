import styled from 'styled-components'

export const RootComponent = styled.div`
  max-width: 1024px;
`

export const Masthead = styled.div`
  border-bottom: 1px solid #ccc;
`

export const ErrDisplay = styled.div`
  background: #e82b2b;
  color: #fff;
  border-radius: 8px;
  padding: 1.5rem;

  span {
    display: block;
  }
`
