import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { RootComponent } from './styledComponents'
import { PageTitle } from '../styledComponents'
import { useStateValue } from '../../redux'
import { userActions } from '../../redux/user/userActions'
import { Container } from '@material-ui/core'
import { PermissionsTable } from '../../components/PermissionsTable'
import { useAppPerms } from '../../AppPermissions'
import { TITLE_TEMPLATE } from '../../utils'
import { LoadingIndicator } from '../../components/LoadingIndicator'

interface Props {
  history: any
}

export const Admin = (props: Props) => {
  const [{ user, loading }, dispatch]: any = useStateValue()
  const { selectedEmployerID } = useAppPerms()
  const { UserPermissions } = user
  const { getUsersAndPermissions } = userActions
  const [isError, updateIsError] = useState<string | null>(null)

  // Throw an error to be caught be the error boundary
  if (isError) {
    throw new Error(isError)
  }

  useEffect(() => {
    if (selectedEmployerID) {
      // Get users for all the different permissions
      getUsersAndPermissions(dispatch, selectedEmployerID).catch(
        (error: any) => {
          updateIsError('403')
        }
      )
    }
  }, [dispatch, getUsersAndPermissions, selectedEmployerID])

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>User Management</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageTitle>User Management</PageTitle>
        <p>
          <em>Customize who on your team has access to what.</em>
        </p>
        {loading &&
        loading.apiCalls &&
        loading.apiCalls.getUsersAndPermissions ? (
          <LoadingIndicator
            size={30}
            loading={loading.apiCalls.getUsersAndPermissions}
          />
        ) : (
          <PermissionsTable
            data={UserPermissions}
            EmployerID={selectedEmployerID}
          />
        )}
      </Container>
    </RootComponent>
  )
}
