import { types } from './eligibilityActions'

export const eligibilityInitialState = {
  eligibleMembers: [],
  count: 0,
  eligibilityError: null,
  params: null,
}

export default function reducer(state: any, action: any) {
  const { payload } = action

  switch (action.type) {
    case types.ELIGIBILITY_SEARCH:
      return {
        ...state,
        ...payload,
      }
    default:
      return { ...state }
  }
}
