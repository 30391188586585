import React from 'react'
import { useStateValue } from '../../redux'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { green, amber } from '@material-ui/core/colors'
import classNames from 'classnames'

import { snackbarActions } from '../../redux/snackbar/snackbarActions'
const { clearSnackbar } = snackbarActions

const TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
}

const styles = (theme: any) => {
  return {
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconType: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}

interface SnackbarMessageProps {
  classes: any
}

const SnackbarMessage = ({ classes }: SnackbarMessageProps) => {
  const [{ snackbar }, dispatch]: any = useStateValue()
  const { message, type, open } = snackbar

  const handleClose = (e: any, reason: string) => {
    if (reason === 'clickaway') {
      return
    }

    clearSnackbar(dispatch)
  }

  const duration = type === TYPES.SUCCESS ? 4000 : 15000

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}>
      <SnackbarContentWrapper
        classes={classes}
        onClose={handleClose}
        type={type}
        message={message}
      />
    </Snackbar>
  )
}

const typeIcons: any = {
  success: CheckCircleIcon,
  error: ErrorIcon,
}

interface SnackbarContentWrapperProps {
  classes: any
  type: string
  message: string
  onClose: any
}

const SnackbarContentWrapper = ({
  classes,
  type,
  message,
  onClose,
}: SnackbarContentWrapperProps) => {
  const Icon = typeIcons[type]

  if (!type || !message) return <div />
  return (
    <SnackbarContent
      className={classes[type]}
      message={
        <span className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconType)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          color="inherit"
          className={classes.close}
          onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  )
}

export default withStyles(styles)(SnackbarMessage)
