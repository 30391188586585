import { services } from './memberServices'
import { notifySnackbar } from '../index'
import { loadingActions } from '../loading/loadingActions'
import { Dispatch } from '../types'

export const types = {
  GET_MEMBER: 'GET_MEMBER',
  GET_MEMBER_DETAIL: 'GET_MEMBER_DETAIL',
  TERM_MEMBERSHIP: 'TERM_MEMBERSHIP',
}

export interface TermParams {
  DeactivationDate?: string
  NoDeactivationDate?: boolean
  IncludedDependentEmployerMemberIDs?: number[]
  SkipUpdateUntil?: string
}

const { updateGetMemberLoading, updateTermMembershipLoading } = loadingActions

export const memberActions = {
  async getMember(memberID: number, employerID: number, dispatch: Dispatch) {
    updateGetMemberLoading(dispatch, true)
    const response = await services.getMember(memberID, employerID)
    updateGetMemberLoading(dispatch, false)
    const result = response.Data ? response.Data : null

    dispatch({
      type: types.GET_MEMBER,
      payload: {
        ...result,
      },
    })
    // for GETs we probably only want to notify on errors.
    if (result.error) {
      notifySnackbar(result, dispatch)
    }
  },
  async termMembership(
    employerMemberID: number,
    params: TermParams,
    dispatch: Dispatch
  ) {
    updateTermMembershipLoading(dispatch, true)
    const response: any = await services.termMembership(
      employerMemberID,
      params
    )
    updateTermMembershipLoading(dispatch, false)

    const result = response.status ? response.status === 204 : null

    dispatch({
      type: types.TERM_MEMBERSHIP,
      payload: {
        result: {
          success: result,
          employerMemberID,
          deactivationDate: params.NoDeactivationDate
            ? null
            : params.DeactivationDate,
        },
      },
    })

    if (response?.Data?.error) {
      notifySnackbar(response.Data, dispatch)
    }
  },
}
