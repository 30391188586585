import React, { useState } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import { useStateValue } from '../../../redux'
import { eligibilityActions } from '../../../redux/eligibility/eligibilityActions'
import { snackbarActions } from '../../../redux/snackbar/snackbarActions'
import { FileUploader } from '../../../components/FileUploader/FileUploader'
import { FieldGridColumn } from './styledComponents'
import { EligSubsetHandlesSelector } from '../EligibilitySubsetHandleSelector'

const { postEligibilityFile } = eligibilityActions

interface Props {
  onClose: () => void
  onSubmit: () => void
  open: boolean
  employerID: number
}

interface FieldErrors {
  subset?: string
  notes?: string
}

const StyledTextField = styled(TextField)`
  width: 100%;
`

export const UploadEligibilityDialog = ({ employerID, ...props }: Props) => {
  const [filename, setFilename] = useState<string>()
  const [selectedSubset, setSelectedSubset] = useState<string>()
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})
  const [notes, setNotes] = useState<string>()
  const [_state, dispatch]: any = useStateValue() // eslint-disable-line

  const handleSubmit = () => {
    console.debug('Submit file')

    postEligibilityFile({
      EmployerID: employerID,
      SubsetHandle: selectedSubset,
      S3Key: filename,
      Size: 0,
      Md5: '',
      Notes: notes,
    })
      .then((res) => {
        snackbarActions.setMessage(
          { type: 'success', message: 'Submitted file' },
          dispatch
        )
      })
      .catch((err) => {
        snackbarActions.setMessage(
          {
            type: 'error',
            message: 'Problem submitting file. Please contact try again.',
          },
          dispatch
        )
      })
    props.onSubmit()
  }

  const handleClose = () => {
    props.onClose()
  }

  const handleUploadComplete = (filename: string) => {
    setFilename(filename)
  }

  const handleUploadError = (status: number) => {
    setFilename(undefined)
  }

  const handleSubsetChanged = (handle: string) => {
    setSelectedSubset(handle)

    const { subset, ...errorsWithoutSubset } = fieldErrors
    setFieldErrors({
      ...errorsWithoutSubset,
    })
  }

  const canSubmit = () => {
    return filename && selectedSubset && Object.keys(fieldErrors).length === 0
  }

  const renderNotesField = () => {
    return (
      <StyledTextField
        rows={4}
        rowsMax={8}
        multiline
        label={'Notes'}
        value={notes}
        onChange={(e: any) => setNotes(e.target.value)}
        name={'notes'}
        key={'notes'}
        margin="normal"
      />
    )
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{
        style: {
          minWidth: 400,
        },
      }}>
      <DialogContent>
        <Grid container>
          <FieldGridColumn item xs={12}>
            <FileUploader
              onComplete={handleUploadComplete}
              onError={handleUploadError}
              employerID={employerID}
            />
          </FieldGridColumn>
          <FieldGridColumn item xs={6}>
            {filename ? (
              <EligSubsetHandlesSelector
                employerID={employerID}
                onChange={handleSubsetChanged}
                error={fieldErrors.subset}
              />
            ) : null}
          </FieldGridColumn>
          <FieldGridColumn item xs={12}>
            {filename ? renderNotesField() : null}
          </FieldGridColumn>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={!canSubmit()} color="primary">
          Submit
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
