import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { teal, pink } from '@material-ui/core/colors'
import { Check, Close } from '@material-ui/icons'

const NULL_DATE = moment('0001-01-01T00:00:00Z').utc()

export const TITLE_TEMPLATE = 'teamZERO - %s'

function convertMoneyFromTZC(val: number): number {
  return val / 100
}

function convertMoneyToTZC(val: number) {
  return val * 100
}

function convertToRate(val: number | string): number {
  if (typeof val === 'string') {
    return parseFloat(val) / 100
  }
  return val / 100
}

function convertFromRate(val: number | string): number {
  if (typeof val === 'string') {
    return parseFloat(val) * 100
  }
  return val * 100
}

function stripNonNumeric(val) {
  return val.replace(/\D/g, '')
}

function getDisplayAddress(cell, row) {
  return row.Address.DisplayAddress
}

function distanceFormatter(cell, row) {
  const value = _.toNumber(cell).toFixed(1)
  return `${value} mi`
}

function scrubAndParseFloat(value) {
  const scrubbedVal = value.toString().replace(/[^0-9.]/g, '')

  return parseFloat(scrubbedVal)
}

function isInteger(value: number | string): boolean {
  const parsed = _.toNumber(value)
  return parsed === _.toInteger(value)
}

function isFloat(value: number | string): boolean {
  if (typeof value === 'string') {
    let parsed = parseFloat(value)
    return parsed === _.toNumber(value)
  }
  return value === _.toNumber(value)
}

export const isDate = (value: any): boolean => {
  const date = moment(value)

  return date.isValid()
}

export const isNullDate = (val: any): boolean => {
  const date = moment(val)

  return date.diff(NULL_DATE) === 0
}

function getCurDate() {
  const dateStr = moment().format('YYYY-MM-DD')

  return moment(dateStr).utc()
}

function checkIfActive(start, end) {
  const now = moment().utc()

  start = moment(start)
  end = moment(end)

  if (start <= now) {
    if (end >= start && end <= now) return false
    if (start.diff(NULL_DATE) === 0) return false

    return true
  }

  return false
}

function nullFormatter(cell, row) {
  return cell !== null ? cell : ''
}

function defaultDateFormatter(cell, row) {
  const val = moment(cell).utc()

  if (!val.isValid() || val.diff(utils.NULL_DATE) === 0) return 'N/A'

  return <span>{val.format('MM/DD/YYYY')}</span>
}

function boolCheckFormatter(cell) {
  if (cell === true || cell === '1') return <Check />
  return <></>
}

function defaultLocalDateFormatter(cell, row) {
  const val = moment(cell)

  if (!val.isValid() || val.diff(utils.NULL_DATE) === 0) return 'N/A'

  return <span>{val.format('MM/DD/YYYY')}</span>
}

function localDateFormatter(cell, row) {
  const val = moment(cell)

  if (!val.isValid() || val.diff(utils.NULL_DATE) === 0) return 'N/A'

  return (
    <span title={val.format('MMMM Do YYYY, h:mm:ss a')}>{val.calendar()}</span>
  )
}

function dateFormatter(cell, row) {
  const val = moment(cell).utc()

  if (!val.isValid() || val.diff(utils.NULL_DATE) === 0) return 'N/A'

  return (
    <span title={val.format('MMMM Do YYYY, h:mm:ss a')}>{val.calendar()}</span>
  )
}

function boolYesNoFormatter(cell, row) {
  const val = cell

  return val === true ? 'Yes' : 'No'
}

function arrayToSelectOptions(items) {
  return items.map((item) => {
    return {
      key: item,
      value: item,
      label: item,
    }
  })
}

function isValidTIN(tin) {
  return tin && tin.length === 9 && isInteger(tin)
}

function getIds(collection) {
  if (!collection || collection.length === 0) return []
  return collection.map((item) => {
    return item.ID
  })
}

const IconCheck = withStyles({
  colorPrimary: {
    color: teal[500],
  },
})(Check)

const IconX = withStyles({
  colorPrimary: {
    color: pink[500],
  },
})(Close)

function activeFormatter(field) {
  return (_, row) => {
    if (row && row[field] === true) {
      return <IconCheck color="primary" />
    }
    return <IconX color="primary" />
  }
}

function zeroOneCheckFormatter(field) {
  return (_, row) => {
    if (row && row[field] === 1) {
      return <IconCheck color="primary" />
    }
    return <IconX color="primary" />
  }
}

const utils = {
  isInteger,
  isFloat,
  distanceFormatter,
  defaultLocalDateFormatter,
  localDateFormatter,
  defaultDateFormatter,
  boolCheckFormatter,
  dateFormatter,
  boolYesNoFormatter,
  NULL_DATE,
  checkIfActive,
  activeFormatter,
  getDisplayAddress,
  arrayToSelectOptions,
  isValidTIN,
  getCurDate,
  convertMoneyFromTZC,
  convertMoneyToTZC,
  scrubAndParseFloat,
  convertToRate,
  convertFromRate,
  nullFormatter,
  stripNonNumeric,
  getIds,
  zeroOneCheckFormatter,
}

export default utils
