import styled, { css } from 'styled-components'
import {
  Paper,
  Grid,
  Avatar,
  ListItem,
  CardHeader,
  CardContent,
} from '@material-ui/core'

const rndcolor = (seed?: number) => {
  const rnd = seed ? seed : Math.random() * 100000
  const colors = [
    '#2979ff',
    '#ff9100',
    '#1de9b6',
    '#00e5ff',
    '#9CCC65',
    '#0277BD',
    '#1DE9B6',
    '#EF5350',
    '#607D8B',
  ]

  const index = Math.floor(rnd % colors.length)
  return colors[index]
}

export const RootComponent = styled.div`
  text-align: center;
`
export const MemberContent = styled(Paper)`
  padding: 20px;
  paddingtop: 0;
`
export const MemberHeader = styled.div`
  padding: 10px;
  text-align: left;
`
export const MembershipColumn = styled(Grid)`
  min-width: 300;
  max-width: 300;
  padding: 0;
`
export const IDCardColumn = styled(Grid)`
  min-width: 300;
  max-width: 300;
`
export const CurrentMembershipAvatar = styled(Avatar)`
  background: green;
`
export const ExpiredMembershipAvatar = styled(Avatar)`
  background: white;
`
export const MemberListItem = styled(ListItem)`
  border-left: 4px;
  border-left-style: solid;
  borderradius: 6;
  margin-right: 10px;

  ${(props) => {
    const color = rndcolor()
    console.debug('color: ', color)
    return css`
      border-left-color: ${color};
    `
  }};
`

export const MembershipHeader = styled(CardHeader)`
  text-align: left;
`
export const MembershipCard = styled(CardContent)`
  padding: 0px !important;
  text-align: left;
`

export const SectionHeader = styled.h5`
  margin: 2.5rem 0 0.75rem;
  font-size: 110%;

  &.reduced-top-margin {
    margin-top: 1rem;
  }
`

export const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
