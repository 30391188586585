import React from 'react'
import { parse, stringify } from 'query-string'
import { Redirect } from 'react-router-dom'
import { useAppPerms } from './AppPermissions'

const RedirectWithEmployerId: React.FC = () => {
  const { selectedEmployerID } = useAppPerms()

  const loc = window.location
  const { employerID, ...queryParams } = parse(loc.search)
  const pathWithParams = `${loc.pathname.slice(1)}?${stringify(queryParams)}`

  return <Redirect to={`/employer/${selectedEmployerID}/${pathWithParams}`} />
}

export default RedirectWithEmployerId
