import { Column } from './index'

export interface TeamZeroUsers {
  [index: string]: Column
  Email: Column
  ExtUsername: Column
  FirstName: Column
  GoZeroNotifications: Column
  ID: Column
  IsActive: Column
  IsGoZero: Column
  LastName: Column
  MemberID: Column
}

export const teamZeroUsers: TeamZeroUsers = {
  Email: {
    defaultValue: '',
    displayValue: 'Email',
  },
  FirstName: {
    defaultValue: '',
    displayValue: 'First Name',
  },
  LastName: {
    defaultValue: '',
    displayValue: 'Last Name',
  },
  ExtUsername: {
    defaultValue: '',
    displayValue: 'Username',
    hidden: true,
  },
  GoZeroNotifications: {
    defaultValue: '',
    displayValue: 'Go Zero Notifications?',
    hidden: true,
  },
  ID: {
    defaultValue: '',
    displayValue: 'ID',
    hidden: true,
  },
  IsActive: {
    defaultValue: '',
    displayValue: 'Is active user?',
    hidden: true,
  },
  IsGoZero: {
    defaultValue: '',
    displayValue: 'Is goZero?',
    hidden: true,
  },
  MemberID: {
    defaultValue: '',
    displayValue: 'Member ID',
    hidden: true,
  },
}
