import React from 'react'
import { Switch, Route } from 'react-router-dom'
import 'typeface-roboto'
import { Eligibility } from './views/Eligibility'
import { EligibilityAddMember } from './views/Eligibility/AddMember'
import { Invoices } from './views/Invoices'
import { Home } from './views/Main/Home'
import { MemberDetail } from './views/Member'
import { Reporting } from './views/Reporting'
import { Admin } from './views/Admin'
import Notifications from './views/Notifications'
import { NoMatch } from './components/NoMatch/NoMatch'
import AppFrame from './components/AppFrame'
import './utils/logger'
import 'postcss'
import { ProtectedRoute } from './ProtectedRoute'
import RedirectWithEmployerId from './RedirectWithEmployerId'
import RedirectSelectedEmployer from './RedirectSelectedEmployer'

const EligibilityAddDependent = (props: any) => (
  <EligibilityAddMember {...props} requireSubscriber={true} />
)

const App = () => (
  <AppFrame>
    <Switch>
      <Route
        path="/employer/:employerID"
        render={({ match: { path } }) => (
          <Switch>
            <Route
              exact
              path={[`${path}/`, `${path}/home`, `${path}/home/`]}
              component={Home}
            />
            <ProtectedRoute
              path={`${path}/eligibility/add-subscriber`}
              perm={'UpdateEligibility'}
              component={EligibilityAddMember}
            />
            <ProtectedRoute
              path={`${path}/eligibility/add-dependent`}
              perm={'UpdateEligibility'}
              component={EligibilityAddDependent}
            />
            <ProtectedRoute
              path={`${path}/eligibility`}
              perm={'ViewAndSubmitEligibility'}
              component={Eligibility}
            />
            <ProtectedRoute
              path={`${path}/invoices`}
              perm={'ViewInvoice'}
              component={Invoices}
            />
            <ProtectedRoute
              path={`${path}/member/:id`}
              perm={'ViewAndSubmitEligibility'}
              component={MemberDetail}
            />
            <Route path={`${path}/reporting`} component={Reporting} />
            <ProtectedRoute
              path={`${path}/admin`}
              perm={'CanManageOtherUsers'}
              component={Admin}
            />
            <Route component={NoMatch} />
          </Switch>
        )}
      />

      <Route path="/notifications" component={Notifications} />

      {/* Route did not match /employer/:employerID, perform redirect to prepend it */}
      <Route component={RedirectWithEmployerId} />
    </Switch>

    {/* Listens for change to selectedEmployerID and fires redirect */}
    <RedirectSelectedEmployer />
  </AppFrame>
)

export default App
