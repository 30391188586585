import React, { useState, useEffect, useContext } from 'react'
import { LinearProgress } from '@material-ui/core'
import { v1 as uuidv1 } from 'uuid'

interface ConfigGetter {
  (): Config | any
}

interface AwsAppConfigResponse {
  data: {
    Content: string
  }
}

interface Config {
  baseUrl: string
  mapsUrl: string
  mapsKey: string
  auth0Domain: string
  auth0Key: string
  serverlessBaseUrl: string
  s3BaseUrl: string
  referralAppLink: string
  env: string
  clientID: string
  initialized: boolean
}

export const ConfigContext = React.createContext({})
export const useConfig: ConfigGetter = () => useContext(ConfigContext)
export const ConfigProvider = ({ children, ...initOptions }: any) => {
  const [baseUrl, setBaseUrl]: [string | undefined, any] = useState()
  const [auth0ClientID, setAuth0ClientID]: [string | undefined, any] =
    useState()
  const [auth0Domain, setAuth0Domain]: [string | undefined, any] = useState()
  const [env, setEnv]: [string | undefined, any] = useState()
  const [gitHash, setGitHash] = useState<string | undefined>()
  const [clientID, setClientID]: [string | undefined, any] = useState()
  const [initialized, setInitialized]: [boolean | undefined, any] = useState()
  const notificationsEnabled = true

  const initializeConfig = async () => {
    const buildEnv = process.env.REACT_APP_ENV
    const hash = process.env.REACT_APP_CURRENT_HASH

    setEnv(buildEnv)
    setGitHash(hash)

    if (buildEnv === 'prd') {
      console.debug(`Commit hash: ${hash}`)
    }

    return fetch(
      `https://config.zero.health/config?application_id=tzc-employer&environment=${buildEnv}&configuration=${buildEnv}&client_id=${clientID}`
    )
      .then((res) => res.json())
      .then((res: any) => {
        const decoded = atob(res.Content)
        const config = JSON.parse(decoded)
        setBaseUrl(config.BASE_URL)
        localStorage.setItem('employer_base_url', config.BASE_URL) // hacky, but would otherwise require a massive refactor of the way we interact with the API
        setAuth0ClientID(config.AUTH0_CLIENT_ID)
        setAuth0Domain(config.AUTH0_DOMAIN)
        setInitialized(true)
      })
      .catch((err) => {
        setInitialized(false)
      })
  }

  const overrideWithLocalEnv = () => {
    const {
      REACT_APP_AUTH0_DOMAIN,
      REACT_APP_AUTH0_CLIENT_ID,
      REACT_APP_BASE_URL,
    } = process.env
    if (REACT_APP_AUTH0_DOMAIN !== undefined) {
      setAuth0Domain(REACT_APP_AUTH0_DOMAIN)
    }
    if (REACT_APP_AUTH0_CLIENT_ID !== undefined) {
      setAuth0ClientID(REACT_APP_AUTH0_CLIENT_ID)
    }
    if (REACT_APP_BASE_URL !== undefined) {
      setBaseUrl(REACT_APP_BASE_URL)
      localStorage.setItem('admin_base_url', REACT_APP_BASE_URL) // hacky, but would otherwise require a massive refactor of the way we interact with the API
    }
  }

  useEffect(() => {
    if (!initialized) {
      setClientID(uuidv1())
      initializeConfig().then((config) => {
        overrideWithLocalEnv()
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ConfigContext.Provider
      value={{
        initialized,
        baseUrl,
        auth0Domain,
        auth0ClientID,
        env,
        notificationsEnabled,
        gitHash,
      }}>
      {initialized ? children : <LinearProgress />}
    </ConfigContext.Provider>
  )
}
